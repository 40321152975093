import React from "react";
import MONTHS from "../../constants/monthsNames";

import MeetingSingleTable from "../meetings/MeetingSingleTable";

class TablesShowMeetings extends React.Component {
    meetingsOrderByDate = Object.keys(this.props.troopsMeetings[this.props.troopID].meetings).slice().sort((a, b) => new Date(this.props.troopsMeetings[this.props.troopID].meetings[b].date) - new Date(this.props.troopsMeetings[this.props.troopID].meetings[a].date));

    state = {
        selectedYear: String(new Date(this.props.troopsMeetings[this.props.troopID].meetings[this.meetingsOrderByDate[0]].date).getFullYear()),
        selectedMonth: String(new Date(this.props.troopsMeetings[this.props.troopID].meetings[this.meetingsOrderByDate[0]].date).getMonth())
    }

    selectYear = (event) => {
        const filterYears = this.meetingsOrderByDate.filter(f => String(new Date(this.props.troopsMeetings[this.props.troopID].meetings[f].date).getFullYear()) === event.target.value);
        this.setState({
            selectedYear: event.target.value,
            selectedMonth: String(new Date(this.props.troopsMeetings[this.props.troopID].meetings[filterYears[0]].date).getMonth())
        })
    }

    selectMonth = (event) => {
        this.setState({
            selectedMonth: event.target.value
        })
    }

    getYears = () => {
        let years = [];
        this.meetingsOrderByDate.map(key => years.push(new Date(this.props.troopsMeetings[this.props.troopID].meetings[key].date).getFullYear()));
        const distinctYears = [...new Set(years)];
        return distinctYears.map(key => <option key={key} value={key}>{key}</option>);
    }

    getMonths = () => {
        let months = [];
        const filterYears = this.meetingsOrderByDate.filter(f => String(new Date(this.props.troopsMeetings[this.props.troopID].meetings[f].date).getFullYear()) === this.state.selectedYear);
        filterYears.map(key => months.push(new Date(this.props.troopsMeetings[this.props.troopID].meetings[key].date).getMonth()));
        const distinctMonths = [...new Set(months)];
        return distinctMonths.map(key => <option key={key} value={key}>{MONTHS[key]}</option>);
    }

    getMeetings = () => {
        const filterData = this.meetingsOrderByDate.filter(f => String(new Date(this.props.troopsMeetings[this.props.troopID].meetings[f].date).getFullYear()) === this.state.selectedYear &&
                                                                String(new Date(this.props.troopsMeetings[this.props.troopID].meetings[f].date).getMonth()) === this.state.selectedMonth);
        return filterData.map((key2, index) =>(<MeetingSingleTable key={key2} order={index+1} slug={key2} details={this.props.troopsMeetings[this.props.troopID].meetings[key2]} />));
    }

    render() {
        return(
            <div className={`single-multi-troop ${this.props.classes.wrapperTitle}`}>
                {(Object.keys(this.props.troopsMeetings).length > 1 && !this.props.adminAccess) ? <h4 className="yellow-font">{this.props.troopsData[this.props.troopID].troopName}</h4> : null}
                <div className="wrapper-meetings-filter-by-date">
                    <select name="meetings-filter-year" className="negative-color box-shadow select-box" value={this.state.selectedYear} onChange={this.selectYear}>
                        <this.getYears />
                    </select>
                    <select name="meetings-filter-month" className="negative-color box-shadow select-box" value={this.state.selectedMonth} onChange={this.selectMonth}>
                        <this.getMonths />
                    </select>
                </div>
                <table className={`general-table ${this.props.classes.tableTile}`}>
                    <thead>
                    <tr>
                        {this.props.tableHeader.map((key, index) => <th key={index}>{key}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                        <this.getMeetings />
                    </tbody>
                </table>
            </div>
        )
    }
}

export default TablesShowMeetings;
