import React from 'react';
import {reCapitalizeData} from "../../../helpers";
import {Link} from "react-router-dom";
import {TROOP_CARD} from "../../../constants/routes";
import FAMILIES from "../../../constants/familesClubs";

class TablesShowTroops extends React.Component {
    render() {
        const orderTroopsByName = Object.keys(this.props.troopsData).sort((a, b) => this.props.troopsData[a].troopName.localeCompare(this.props.troopsData[b].troopName, "sl"));
        return (
            <div className={this.props.classes.wrapperTitle}>
                <table className={`general-table ${this.props.classes.tableTile}`}>
                    <thead>
                        <tr>
                            {this.props.tableHeader.map((key, index) => <th key={index}>{key}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {orderTroopsByName.map((key, index) =>
                            <tr key={key}>
                                <td data-label="Št." className="order">{index+1}</td>
                                <td data-label="Ime" className="name">{this.props.troopsData[key].troopName}</td>
                                <td data-label="Družina" className={
                                    (this.props.troopsData[key].troopRole === FAMILIES.MC_FAMILY.VALUE) ? "family red-font" :
                                        (this.props.troopsData[key].troopRole === FAMILIES.GG_FAMILY.VALUE) ? "family green-font" :
                                            (this.props.troopsData[key].troopRole === FAMILIES.PP_CLUB.VALUE) ? "family blue-font" :
                                                (this.props.troopsData[key].troopRole === FAMILIES.RR_CLUB.VALUE) ? "family orange-font" :
                                                    "family purple-font"}>
                                    {reCapitalizeData(this.props.troopsData[key].troopRole, FAMILIES)}
                                </td>
                                {(this.props.troopsData[key].troopLeader) ?
                                    <td data-label="Vodnik" className="troop-leader">{this.props.users[this.props.troopsData[key].troopLeader].firstName} {this.props.users[this.props.troopsData[key].troopLeader].lastName}</td> :
                                    <td data-label="Vodnik" className="troop-leader">/</td>
                                }
                                <td data-label="Več" className="more-info"><Link to={`${TROOP_CARD}/${key}`}>Info</Link></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default TablesShowTroops;