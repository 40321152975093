import React from "react";
import firebase from "firebase/app";
import 'firebase/auth';
import base, {firebaseApp} from "../base";
import * as ROUTES from "../constants/routes";
import ALL_ROLES, { NO_ROLE } from "../constants/roles";
import NOTICES from "../constants/notices";
import loaderImage from "../images/fidget-spinner-loader.svg";

import LoginUser from "./LoginUser";
import CanvasAdmin from "./admin/CanvasAdmin";
import CanvasUser from "./user/CanvasUser";

class App extends React.Component {
    // States
    state = {
        isLoading: true,
        loginDataErrors: {
            wrongData: false,
            notice: null
        },
        userIsLogged: false,
        currentUser: {},
        currentUserRole: null,
        schoolYears: {},
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        subMenuActive: false
    };

    // When component mount check database if user is logged in
    componentDidMount() {
        this.refDate = base.syncState(`/schoolYears/`, {
            context: this,
            state: 'schoolYears',
        });
        firebase.auth().onAuthStateChanged(user => {
            if(user) {
                this.authHandler({user});
            } else {
                this.setState({
                    isLoading: false,
                    userIsLogged: false
                })
            }
        })

        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

        window.addEventListener('resize', this.updateWindowDimensions);
    };

    componentWillUnmount() {
        base.removeBinding(this.refDate);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    // Log in functions
    authenticate = (email, password) => {
        // Login to base
        firebaseApp.auth().signInWithEmailAndPassword(email, password).then(this.authHandler).catch(this.errorHandler);
    };
    authHandler = async (authData) => {
        // Get the user unique ID
        const userID = authData.user.uid;
        const userEmail = authData.user.email;

        // Look up the leaders in database
        const leader = await base.fetch(`/leaders/${userID}`, {context: this});
        leader['userID'] = userID;

        // Check what role user has
        authData.user.getIdTokenResult().then((idTokenResult) => {
            // Set the state. For checking role of user
            if(idTokenResult.claims.role) {
                this.setState({
                    currentUser: leader,
                    currentUserRole: idTokenResult.claims.role
                });
                this.updateCurrentUserEmail(userEmail); // Run function, if there was reverting e-mail address via email
            } else {
                this.setState({
                    currentUser: NO_ROLE,
                    currentUserRole: null
                });
            }
            this.setState({
                isLoading: false,
                userIsLogged: true,
                loginDataErrors: {
                    wrongData: false,
                    notice: null
                }
            });
            // Change link to home
            if(authData.operationType === "signIn") {
                this.props.history.push(ROUTES.HOME);
            }
        });
    };
    errorHandler = (error) => {
        const errorCode = error.code;
        if(errorCode === "auth/user-not-found") {
            this.setState({
                loginDataErrors: {
                    wrongData: true,
                    notice: NOTICES.WRONG_USER
                }
            })
        } else if(errorCode === "auth/wrong-password") {
            this.setState({
                loginDataErrors: {
                    wrongData: true,
                    notice: NOTICES.WRONG_PASS
                }
            })
        }
    };

    // Log out function
    logout = async () => {
        console.log("Logging out!");
        await firebase.auth().signOut();
        this.setState({
            userIsLogged: false,
            currentUser: {},
            currentUserRole: null
        });
        // Push url to start url
        this.props.history.push(this.props.match.url);
    };

    updateWindowDimensions = () => {
        this.setState({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        })
    }

    // Update current user profile
    updateCurrentUser = (updatedCurrentUser) => {
        let currentUser = { ...this.state.currentUser };
        currentUser = updatedCurrentUser;
        this.setState({currentUser: currentUser});
        base.post(`/leaders/${currentUser.userID}`, {
            data: currentUser
        });
    }

    // Update current user e-mail address
    updateCurrentUserEmail = (email) => {
        let currentUser = { ...this.state.currentUser };
        currentUser["email"] = email;
        this.setState({currentUser: currentUser})
        base.post(`/leaders/${currentUser.userID}`, {
            data: currentUser
        });
    }

    // Add new school year
    addSchoolYear = (year) => {
        const schoolYears = { ...this.state.schoolYears };
        // Check if object all exists in selected years
        if(!('all' in schoolYears)) {
            schoolYears['all'] = {};
        }
        schoolYears['all'][year.id] = {
            startSchoolDate: year.startDate,
            endSchoolDate: year.endDate,
            endOfSchoolYear: year.endOfSchoolYear
        };
        this.setState({schoolYears: schoolYears});
    }

    // Change current school year
    updateCurrentSchoolYear = (year) => {
        const schoolYears = { ...this.state.schoolYears };
        schoolYears['currentStartSchoolDate'] = year.currentStartSchoolDate;
        schoolYears['currentEndSchoolDate'] = year.currentEndSchoolDate;
        this.setState({schoolYears: schoolYears});
    }

    // End school year
    endSchoolYear = (year) => {
        const schoolYears = { ...this.state.schoolYears };
        schoolYears.all[year.year].endOfSchoolYear = year.checked;
        this.setState({schoolYears: schoolYears});
    }

    render() {
        if(this.state.isLoading) {
            return (
                <div className="loading-screen">
                    <div className="loader-ico"><img src={loaderImage} alt="loader" /></div>
                </div>
            )
        } else {
            if(!this.state.userIsLogged) {
                return (
                    <LoginUser
                        authenticate={this.authenticate}
                        loginDataErrors={this.state.loginDataErrors}
                    />
                )
            } else if((this.state.userIsLogged && this.state.currentUser.userRole === this.state.currentUserRole && this.state.currentUserRole === ALL_ROLES.ADMIN.VALUE) ||
                        (this.state.userIsLogged && this.state.currentUser.userRole === this.state.currentUserRole && this.state.currentUserRole === ALL_ROLES.GG.VALUE) ||
                        (this.state.userIsLogged && this.state.currentUser.userRole === this.state.currentUserRole && this.state.currentUserRole === ALL_ROLES.MC.VALUE)) {
                return (
                    <CanvasAdmin
                        windowWidth={this.state.windowWidth}
                        windowHeight={this.state.windowHeight}
                        currentUser={this.state.currentUser}
                        updateCurrentUser={this.updateCurrentUser}
                        updateCurrentUserEmail={this.updateCurrentUserEmail}
                        schoolYears={this.state.schoolYears}
                        addSchoolYear={this.addSchoolYear}
                        updateCurrentSchoolYear={this.updateCurrentSchoolYear}
                        endSchoolYear={this.endSchoolYear}
                        logout={this.logout}
                    />
                )
            } else if(this.state.userIsLogged && this.state.currentUser.userRole === this.state.currentUserRole && this.state.currentUserRole === ALL_ROLES.TROOP_LEADER.VALUE) {
                return (
                    <CanvasUser
                        windowWidth={this.state.windowWidth}
                        windowHeight={this.state.windowHeight}
                        schoolYears={this.state.schoolYears}
                        currentUser={this.state.currentUser}
                        updateCurrentUser={this.updateCurrentUser}
                        updateCurrentUserEmail={this.updateCurrentUserEmail}
                        logout={this.logout}
                    />
                )
            } else {
                return (
                    <div className="wrapper-user-none-role">
                        <h2>{NOTICES.NO_ROLE.TITLE}</h2>
                        <p>{NOTICES.NO_ROLE.TEXT}</p>
                    </div>
                )
            }
        }
    }
}

export default App;
