import React from 'react';
import {Link} from "react-router-dom";
import {USER_CARD} from "../../constants/routes";
import ALL_ROLES from "../../constants/roles";

class TablesShowUsers extends React.Component {
    render() {
        const orderUsersByName = Object.keys(this.props.users).sort((a, b) => this.props.users[a].firstName.localeCompare(this.props.users[b].firstName, "sl"));
        const removeSelf = orderUsersByName.filter(f => f !== this.props.currentUser.userID);
        return (
            <div className={this.props.classes.wrapperTitle}>
                <table className={`general-table ${this.props.classes.tableTile}`}>
                    <thead>
                        <tr>
                            {this.props.tableHeader.map((key, index) => <th key={index}>{key}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {removeSelf.map((key, index) =>
                            <tr key={key}>
                                <td data-label="Št." className="order">{index+1}</td>
                                <td data-label="Ime in priimek" className="name-surname">{this.props.users[key].firstName} {this.props.users[key].lastName}</td>
                                <td data-label="E-mail" className="e-mail">{this.props.users[key].email}</td>
                                <td data-label="Telefonska številka" className="tel-number">{this.props.users[key].telNumber}</td>
                                <td data-label="Vloga" className="user-roll">{ALL_ROLES[Object.keys(ALL_ROLES).filter(f => ALL_ROLES[f].VALUE === this.props.users[key].userRole)].LABEL}</td>
                                <td data-label="Več" className="more-info"><Link to={`${USER_CARD}/${key}`}>Info</Link></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default TablesShowUsers;