import React from 'react';
import {formatDate} from "../../../helpers";
import {Link} from "react-router-dom";
import {MEETING_CARD} from "../../../constants/routes";

class GroupAllMeetingsTable extends React.Component {
    meetingsOrderByDate = Object.keys(this.props.troopsMeetings[this.props.troopID].meetings).slice().sort((a, b) => new Date(this.props.troopsMeetings[this.props.troopID].meetings[b].date) - new Date(this.props.troopsMeetings[this.props.troopID].meetings[a].date));

    getMeetings = () => {
        const filterData = this.meetingsOrderByDate.filter(f => String(new Date(this.props.troopsMeetings[this.props.troopID].meetings[f].date).getFullYear()) === this.props.selectedYear &&
            String(new Date(this.props.troopsMeetings[this.props.troopID].meetings[f].date).getMonth()) === this.props.selectedMonth);
        const lengthData = filterData.length;
        return filterData.map((key2, index) =>(<this.MeetingSingleTable key={key2} order={index+1} last={(lengthData === index+1) && "last"} slug={key2} troopName={this.props.troopsData[this.props.troopID].troopName} details={this.props.troopsMeetings[this.props.troopID].meetings[key2]} />));
    }

    MeetingSingleTable(props) {
        return (
            <tr {...((props.last) && {className: props.last})}>
                <td data-label="Št." className="order">{props.order}</td>
                <td data-label="Vod" className="troop">{props.troopName}</td>
                <td data-label="Sestanek" className="title">{props.details.title}</td>
                <td data-label="Lokacija" className="place">{props.details.place}</td>
                <td data-label="Datum" className="date">{formatDate(props.details.date)}</td>
                <td data-label="Čas" className="time">{props.details.time}</td>
                <td data-label="Več" className="more-info"><Link to={`${MEETING_CARD}/${props.details.troop}/${props.slug}`}>Info</Link></td>
            </tr>
        )
    };

    render() {
        return(
            <this.getMeetings />
        )
    }
}

export default GroupAllMeetingsTable;