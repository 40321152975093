export function numOfTroopsFamilies(troops, filters) {
    return Object.keys(troops).filter(f => filters.includes(troops[f].familyTroop) ).length;
}

export function numOfTroops(troops, filters, troopRole) {
    return Object.keys(troops).filter(f => filters.includes(troops[f].familyTroop) && troops[f].troopRole === troopRole).length;
}

export function membershipPayed(troops, members, years) {
    // All members
    let allMembers = members.allMembersUnique.length;
    // Membership check
    const startDate = new Date(years.currentStartSchoolDate).getFullYear();
    const endDate = new Date(years.currentEndSchoolDate).getFullYear();
    const combineYears = `${startDate}-${endDate}`;
    let payedMembership = 0;
    let unPaidMembership = 0;
    members.allMembersUnique.map(function (key) {
        if(combineYears in troops[key.troop].members[key.member].progress && troops[key.troop].members[key.member].progress[combineYears].membershipPayment) {
            payedMembership++;
        } else {
            unPaidMembership++;
        }
        return false;
    });
    const payedMembershipPercent = ((payedMembership / allMembers) * 100).toFixed(1);
    const unPaidMembershipPercent = ((unPaidMembership / allMembers) * 100).toFixed(1);

    return {
        'paidMembershipTotal': payedMembership,
        'paidMembershipPercentage': payedMembershipPercent,
        'unPaidMembershipTotal': unPaidMembership,
        'unPaidMembershipPercentage': unPaidMembershipPercent,
    }
}

export function numberOfMembers(troops, troopsData, troopRole, filters) {
    // Troop members
    let members = 0;
    Object.keys(troops).map(function (key, index) {
        if(
            'members' in troops[key] &&
            troopsData[key].familyTroop !== filters.FAMILY.VALUE &&
            troopsData[key].familyTroop !== filters.DONT_COUNT.VALUE &&
            troops[key].troopRole === troopRole
        ) {
            members += Object.keys(troops[key].members).length;
        }
        return false;
    });
    return members;
}

export function numberOfMembersPerYear(troops, troopsData, troopRole, filters, years) {
    let members = [];
    years.map(function (year) {
        let count = 0;
        Object.keys(troops).map(function (key) {
            if(
                'members' in troops[key] &&
                troopsData[key].familyTroop !== filters.FAMILY.VALUE &&
                troopsData[key].familyTroop !== filters.DONT_COUNT.VALUE &&
                troopRole.includes(troops[key].troopRole)
            ) {
                Object.keys(troops[key].members).map(function (key2) {
                    if(year in troops[key].members[key2].progress && troops[key].members[key2].progress[year].membershipPayment) {
                        count++;
                    }
                    return false;
                });
            }
            return false;
        });
        members[year] = count;
        return false;
    });
    return members;
}