import Rebase from "re-base";
import firebase from "firebase/app";
import 'firebase/functions';
import 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBvp6rxYZt17rGW5itsVHPt0GQtnjg_gbE",
    authDomain: "kokrski-rod-base.firebaseapp.com",
    databaseURL: "https://kokrski-rod-base.firebaseio.com",
    projectId: "kokrski-rod-base",
};

/*const firebaseConfig = {
    apiKey: "AIzaSyBNuxIBtk2uOO6NVOFfQlPZXQdtVEB-Ah8",
    authDomain: "kokrski-rod-base-dev-e1916.firebaseapp.com",
    databaseURL: "https://kokrski-rod-base-dev-e1916.firebaseio.com",
    projectId: "kokrski-rod-base-dev-e1916",
};*/

const firebaseApp = firebase.initializeApp(firebaseConfig);
const fireBaseFunctions = firebase.initializeApp(firebaseConfig, "Functions").functions('europe-west3');

const base = Rebase.createClass(firebaseApp.database());

export { firebaseApp, fireBaseFunctions };
export default base;