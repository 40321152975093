import React, {Fragment} from 'react';
import MemberSkillsSingleMember from "./MemberSkillsSingleMember";
import loaderImage from "../../../images/fidget-spinner-loader.svg";
import ALL_ROLES from "../../../constants/roles";

class MemberSingleProgress extends React.Component {
    state = {
        loading: true
    }

    componentDidMount() {
        this.fillEmptyProgress(this.props.selectedYear);
        this.setState({loading: false} );
    }

    getYears = () => {
        const sortByYear = Object.keys(this.props.memberProgressData).sort((a, b) => b.split("-")[0] - a.split("-")[0]);
        return (
            <Fragment>
                <option value={`${new Date(this.props.schoolYears.currentStartSchoolDate).getFullYear()}-${new Date(this.props.schoolYears.currentEndSchoolDate).getFullYear()}`}>{`${new Date(this.props.schoolYears.currentStartSchoolDate).getFullYear()}-${new Date(this.props.schoolYears.currentEndSchoolDate).getFullYear()}`}</option>
                {sortByYear.map(key=>
                    (key !== `${new Date(this.props.schoolYears.currentStartSchoolDate).getFullYear()}-${new Date(this.props.schoolYears.currentEndSchoolDate).getFullYear()}`) ? <option key={key} value={key}>{key}</option> : null
                )}
            </Fragment>
        )
    }

    changeDate = (event) => {
        this.fillEmptyProgress(event.target.value);
        this.props.changeDate(event.target.value);
    }

    fillEmptyProgress = (year) => {
        const updateCurrentMemberProgressData = {
            'selectedMember': this.props.memberData,
            'selectedYear': year
        };
        const emptyFieldsStart = {
            'membershipCard': false,
            'membershipPayment': false,
            'memberActivityWinter': false,
            'memberActivitySummer': false,
            'memberActivityFall': false,
            'memberActivityOther': 'Prazno',
            'memberSkillsLevel': 'Prazno',
            'memberSkillsMisc': ['Prazno']
        }

        if(!('progress' in this.props.memberData)) {
            this.props.memberData['progress'] = {};
        }
        if(!(year in this.props.memberData['progress'])) {
            this.props.updateCurrentMemberProgress(updateCurrentMemberProgressData, emptyFieldsStart, this.props.troopRole);
        }
        return null;
    }

    handleChangeProgress = (event) => {
        const memberProgressData = this.props.memberProgressData[this.props.selectedYear];
        const updateCurrentMemberProgressData = {
            selectedMember: this.props.memberData,
            selectedYear: this.props.selectedYear
        }
        let updateCurrentMemberProgressFields;
        if(event.currentTarget.type === "checkbox") {
            updateCurrentMemberProgressFields = {
                ...memberProgressData,
                [event.currentTarget.name]: event.currentTarget.checked
            };
        } else {
            updateCurrentMemberProgressFields = {
                ...memberProgressData,
                [event.currentTarget.name]: event.currentTarget.value
            };
        }
        this.props.updateCurrentMemberProgress(updateCurrentMemberProgressData, updateCurrentMemberProgressFields, this.props.troopRole);
    }

    render() {
        if(this.state.loading) {
            return (
                <div className="loading-screen">
                    <div className="loader-ico"><img src={loaderImage} alt="loader" /></div>
                </div>
            )
        } else {
            const { membershipCard, membershipPayment, memberActivityFall, memberActivitySummer, memberActivityWinter, memberActivityOther} = this.props.memberData['progress'][this.props.selectedYear];
            return (
                <div className="wrapper-new-component">
                    <div className="box-shadow member-progress-wrapper">
                        <div className="header-member-progress flex-columns items-center">
                            <h2 className="blue-font">Aktivnost</h2>
                            <select name="member-progress-filter-year" className="select-box no-shadow-select" onChange={this.changeDate} value={this.props.selectedYear}>
                                <this.getYears />
                            </select>
                        </div>
                        <div className="flex-columns member-progress-section">
                            <div className="membership-wrapper one-half-flex-with-space">
                                <h4 className="blue-font">Članstvo</h4>
                                <table className="general-table no-shadow-table membership-table">
                                    <thead>
                                    <tr>
                                        <th>Vpisni list</th>
                                        <th>Plačana članarina</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td data-label="Vpisni list"><input type="checkbox" name="membershipCard" className="negative-color" onChange={this.handleChangeProgress} checked={membershipCard} /></td>
                                        {(this.props.currentUserRole === ALL_ROLES.TROOP_LEADER.VALUE) ?
                                            <td data-label="Plačana članarina"><input type="checkbox" disabled="disabled" name="membershipPayment" className="negative-color" checked={membershipPayment} /></td> :
                                            <td data-label="Plačana članarina"><input type="checkbox" name="membershipPayment" className="negative-color" onChange={this.handleChangeProgress} checked={membershipPayment} /></td>
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="member-skills-wrapper one-half-flex-with-space">
                                <h4 className="blue-font">Znanje in veščine</h4>
                                <MemberSkillsSingleMember
                                    key={this.props.memberData.memberID}
                                    order= {1}
                                    updateCurrentMemberProgress={this.props.updateCurrentMemberProgress}
                                    troopsData={this.props.troopsData}
                                    troopsMembers={this.props.troopsMembers}
                                    troopRole={this.props.troopRole}
                                    selectedYear={this.props.selectedYear}
                                    details={this.props.memberData}
                                    inElement={true}
                                    parentEditable={this.props.editable}
                                />
                            </div>
                        </div>
                        <div className="flex-columns">
                            <div className="member-activity-wrapper full-column-flex">
                                <h4 className="blue-font">Udeležba na akcijah</h4>
                                <table className="general-table no-shadow-table membership-table">
                                    <thead>
                                    <tr>
                                        <th>Jesenovanje</th>
                                        <th>Zimovanje</th>
                                        <th>Tabor</th>
                                        <th>Druge akcije</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td data-label="Jesenovanje"><input type="checkbox" name="memberActivityFall" className="negative-color" onChange={this.handleChangeProgress} checked={memberActivityFall} /></td>
                                        <td data-label="Zimovanje"><input type="checkbox" name="memberActivityWinter" className="negative-color" onChange={this.handleChangeProgress} checked={memberActivityWinter} /></td>
                                        <td data-label="Tabor"><input type="checkbox" name="memberActivitySummer" className="negative-color" onChange={this.handleChangeProgress} checked={memberActivitySummer} /></td>
                                        {(this.props.editable) ?
                                            <td id="wrapper-table-edit-data" data-label="Druge akcije" className="member-activity-other"><input name="memberActivityOther" className="edit-data" type="text" onBlur={this.handleChangeProgress} defaultValue={memberActivityOther} /></td> :
                                            <td data-label="Druge akcije" className="member-activity-other">{memberActivityOther}</td>
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default MemberSingleProgress;