import React from 'react';
import ChangeTroopBeforeEditUser from "./ChangeTroopBeforeEditUser";
import {firebaseApp, fireBaseFunctions} from "../../base";
import ALL_ROLES from "../../constants/roles";
import loaderImage from "../../images/fidget-spinner-loader.svg";

class DeleteUser extends React.Component {
    state = {
        areYouSureDelete: false,
        waitOnPromise: false
    }

    deleteUserSure = () => {
        this.setState({areYouSureDelete: true})
    }
    deleteUserSureNo = () => {
        this.setState({areYouSureDelete: false})
        this.props.popUpOpen(false);
    }
    deleteUserSureYes = () => {
        const userData = {
            uid: this.props.userID,
        }

        this.setState({waitOnPromise: true});

        firebaseApp.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
            if(idTokenResult.claims.role === ALL_ROLES.ADMIN.VALUE) {
                const deleteUser = fireBaseFunctions.httpsCallable('deleteTroopLeader');
                deleteUser(userData).then(result => {
                    this.props.deleteUser(this.props.userID);
                    this.setState({ waitOnPromise: false });
                    //this.props.goBack.goBack();
                });
            } else {
                alert('Nimaš ustreznih pravic za urejanje vodnikov!');
                this.setState({waitOnPromise: false});
            }
        }).catch((error) => {
            console.log(error);
            this.setState({waitOnPromise: false});
        });
    }

    render() {
        const userTroopsHave = Object.keys(this.props.troopsData).filter(f => this.props.troopsData[f].troopLeader === this.props.userID);
        return (
            <div className="wrapper-popup">
                <div id="popup-none-form" className="inner-popup are-you-sure-popup box-shadow">
                    <div className="content-popup">
                        <h3>Ali res želiš zbrisati člana vodstva?</h3>
                        {(userTroopsHave.length > 0) ?
                            <ChangeTroopBeforeEditUser
                                userID={this.props.userID}
                                updateCurrentTroop={this.props.updateCurrentTroop}
                                archiveTroop={this.props.archiveTroop}
                                deleteTroop={this.props.deleteTroop}
                                troopsData={this.props.troopsData}
                                users={this.props.users} /> :
                            <div className="buttons-wrapper wait-on-promise">
                                <button type="button" className="yes" onClick={this.deleteUserSureYes}>Da</button>
                                {(!this.state.waitOnPromise) ? null :
                                    <div className="loader-on-submit">
                                        <div className="loader-ico"><img src={loaderImage} alt="loader" /></div>
                                    </div>
                                }
                                <button type="button" className="no" onClick={this.deleteUserSureNo}>Ne</button>
                            </div>
                        }
                    </div>
                    {(userTroopsHave.length > 0) ?
                        <p className="floating-button">
                            <button type="button" className="button-as-link" onClick={this.deleteUserSureNo}>Zapri
                            </button>
                        </p> :
                        null
                    }
                </div>
            </div>
        )
    }
}

export default DeleteUser;