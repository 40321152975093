import React from "react";
import {firebaseApp, fireBaseFunctions} from "../../base";
import ALL_ROLES from "../../constants/roles";
import {formatPhoneNumber} from "../../helpers";
import NOTICES from "../../constants/notices";
import loaderImage from "../../images/fidget-spinner-loader.svg";

class RegisterUser extends React.Component {
    // User info refs
    emailRef = React.createRef();
    passwordRef = React.createRef();
    userNameRef = React.createRef();
    userRoleRef = React.createRef();
    firstNameRef = React.createRef();
    lastNameRef = React.createRef();
    telNumberRef = React.createRef();

    state = {
        error: false,
        errorCode: null,
        errorMessage: null,
        waitOnPromise: false,
        succeed: false
    }

    registerUser = (event) => {
        // Stop the form from submitting
        event.preventDefault();
        const currentForm = event.currentTarget;

        // Load data in variables
        const thisClass = this;
        const email = this.emailRef.current.value;
        const password = this.passwordRef.current.value;
        const userName = this.userNameRef.current.value;
        const firstName = this.firstNameRef.current.value;
        const lastName = this.lastNameRef.current.value;
        const telNumber = this.telNumberRef.current.value;
        const userRole = this.userRoleRef.current.value;

        const userBackEnd = {
            email: email,
            password: password,
            displayName: userName,
        }

        const userBase = {
            userName: userName,
            email: email,
            firstName: firstName,
            lastName: lastName,
            userRole: userRole
        };
        if (telNumber) {
            userBackEnd.phoneNumber = formatPhoneNumber(telNumber);
            userBase.telNumber = telNumber;
        }

        if(userRole === "vloga") {
            this.setState({
                error: true,
                errorCode: "auth/empty-role",
                errorMessage: NOTICES.NO_ROLE_SELECT,
                waitOnPromise: false
            });
            return;
        } else {
            this.setState({
                error: false,
                errorCode: null,
                errorMessage: null,
                waitOnPromise: true
            });
        }

        // Register new user
        let errorMessage;
        firebaseApp.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
            if(idTokenResult.claims.role === ALL_ROLES.ADMIN.VALUE) {
                const addTroopLeader = fireBaseFunctions.httpsCallable('addNewTroopLeader');
                addTroopLeader(userBackEnd).then(result => {
                    if('errorInfo' in result.data) {
                        console.log(result);
                        if(result.data.errorInfo.code === "auth/email-already-exists") {
                             errorMessage = NOTICES.EMAIL_ALREADY_IN_USE;
                        } else if(result.data.errorInfo.code === "auth/phone-number-already-exists" || result.data.errorInfo.code === "auth/invalid-phone-number") {
                            errorMessage = NOTICES.PHONE_ALREADY_IN_USE;
                        }
                        this.setState({
                            error: true,
                            errorCode: result.data.errorInfo.code,
                            errorMessage: errorMessage,
                            waitOnPromise: false
                        });
                    } else {
                        const roleData = {
                            uid: result.data.uid,
                            userRole: userRole
                        }
                        const setRoleToTroopLeader = fireBaseFunctions.httpsCallable('setRoleToUser');
                        setRoleToTroopLeader(roleData).then(result2 => {
                            console.log(result2);
                            this.setState({waitOnPromise: false});
                            if('errorInfo' in result2.data) {
                                alert("Zgodila se je napaka pri dodajanju vloge uporabniku. Prosim zbriši uporabnika in ga probaj dodati znova.");
                            } else {
                                thisClass.props.addTroopLeader(result.data.uid, userBase);
                                this.setState({
                                    error: false,
                                    errorCode: null,
                                    errorMessage: null,
                                    succeed: true
                                });
                                currentForm.reset();
                            }
                        });
                    }
                });
            } else {
                alert('Nimaš ustreznih pravic za dodajanje novih vodnikov!');
                this.setState({waitOnPromise: false});
            }
        }).catch((error) => {
            console.log(error);
            this.setState({waitOnPromise: false});
        });
    };

    closePopUpClicked = () => { this.setState({succeed: false}); }

    render() {
        return (
            <div className="wrapper_forms">
                <form id="register-user" className="form-settings" onSubmit={this.registerUser}>
                    <h1 className="yellow-font">Registracija novega vodnika</h1>
                    <div className="body-form">
                        <div className="form-section general-user-data">
                            <h2 className="white-font">Obvezni podatki</h2>
                            <p className="single-input medium-left user-name">
                                <input name="user-name" className="negative-color" ref={this.userNameRef} type="text" required placeholder="Uporabniško ime" />
                                <label className="white-font">Kot uporabniško ime uporabi taborniško ime</label>
                            </p>
                            <p className="single-input medium-right password">
                                <input name="password" className="negative-color" ref={this.passwordRef} type="password" pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" required placeholder="Geslo" />
                                <label className="white-font">Geslo mora vsebovati vsaj 8 znakov, mora imeti vsaj eno malo in eno veliko črko, mora imeti vsaj eno številko in vsaj en posebni znak!</label>
                            </p>
                            {(this.state.error && this.state.errorCode === "auth/email-already-exists") ?
                                <p className="single-input medium-left e-mail error-input">
                                    <input name="e-mail" className="negative-color" ref={this.emailRef} type="email" required placeholder="E-mail"/>
                                    <label>{this.state.errorMessage}</label>
                                </p> :
                                <p className="single-input medium-left e-mail">
                                    <input name="e-mail" className="negative-color" ref={this.emailRef} type="email" required placeholder="E-mail"/>
                                </p>
                            }
                            {(this.state.error && this.state.errorCode === "auth/empty-role") ?
                                <p className="single-input medium-right user-role error-input">
                                    <select name="user-role" className="negative-color" ref={this.userRoleRef} defaultValue="vloga">
                                        <option value="vloga" disabled>Vloga</option>
                                        {Object.keys(ALL_ROLES).map(key => <option key={key} value={ALL_ROLES[key].VALUE}>{ALL_ROLES[key].LABEL}</option>)}
                                    </select>
                                    <label>{this.state.errorMessage}</label>
                                </p> :
                                <p className="single-input medium-right user-role">
                                    <select name="user-role" className="negative-color" ref={this.userRoleRef} defaultValue="vloga">
                                        <option value="vloga" disabled>Vloga</option>
                                        {Object.keys(ALL_ROLES).map(key => <option key={key} value={ALL_ROLES[key].VALUE}>{ALL_ROLES[key].LABEL}</option>)}
                                    </select>
                                </p>
                            }
                        </div>
                        <div className="form-section extra-user-data">
                            <h2 className="white-font">Dodatni podatki</h2>
                            <p className="single-input medium-left first-name">
                                <input name="first-name" className="negative-color" ref={this.firstNameRef} type="text" required placeholder="Ime" />
                            </p>
                            <p className="single-input medium-right last-name">
                                <input name="last-name" className="negative-color" ref={this.lastNameRef} type="text" required placeholder="Priimek" />
                            </p>
                            {((this.state.error && this.state.errorCode === "auth/phone-number-already-exists") || (this.state.error && this.state.errorCode === "auth/invalid-phone-number")) ?
                                <p className="single-input tel-number error-input">
                                    <input name="tel-number" className="negative-color" ref={this.telNumberRef} type="tel" pattern="[0-9]{3}/[0-9]{3}-[0-9]{3}" placeholder="Telefonska številka" />
                                    <label>{this.state.errorMessage}</label>
                                </p> :
                                <p className="single-input tel-number">
                                    <input name="tel-number" className="negative-color" ref={this.telNumberRef} type="tel" pattern="[0-9]{3}/[0-9]{3}-[0-9]{3}" placeholder="Telefonska številka" />
                                    <label className="white-font">Format: 041/999-999</label>
                                </p>
                            }
                        </div>
                    </div>
                    <div className="footer-form wait-on-promise">
                        <button className="negative-color" type="submit">Registracija</button>
                        {(!this.state.waitOnPromise) ? null :
                            <div className="loader-on-submit">
                                <div className="loader-ico"><img src={loaderImage} alt="loader" /></div>
                            </div>
                        }
                    </div>
                </form>
                {(!this.state.succeed) ? null :
                    <div className="wrapper-popup">
                        <div className="inner-popup on-succeed box-shadow">
                            <div className="content-popup">
                                <p className="floating-button"><button type="button" className="button-as-link" onClick={this.closePopUpClicked}>X</button></p>
                                <p className="form-success-message">Uspešno si registriral novega vodnika, ali člana vodstva.</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default RegisterUser;
