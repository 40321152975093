import React, {Fragment} from 'react';
import ALL_ROLES from "../../constants/roles";
import DecideDeleteOrArchiveTroop from "../troops/adminAccess/DecideDeleteOrArchiveTroop";

class ChangeTroopBeforeEditUser extends React.Component {
    state = {
        popUpDecideTroopDelArc: false,
        selectedTroop: null
    };

    changeUserForTroop = (key, event) => {
        const troop = key;
        const newTroopLeader = event.currentTarget.value;

        const updatedCurrentTroop = {
            ...this.props.troopsData[troop],
            troopLeader: newTroopLeader
        };
        this.props.updateCurrentTroop(updatedCurrentTroop);
    }

    closePopUpSecond = (click) => {
        this.setState({
            popUpDecideTroopDelArc: click,
        })
    }

    decideDeleteOrArchiveTroop = (key, event) => {
        this.setState({
            popUpDecideTroopDelArc: true,
            selectedTroop: key
        });
    }

    getUsersDropDown = (key) => {
        const user = this.props.users[key];
        if(user.userRole === ALL_ROLES.TROOP_LEADER.VALUE && user.userID !== this.props.userID) {
            return <option key={key} value={key}>{user.userName}</option>
        } else {
            return '';
        }
    }

    render() {
        const userTroopsHave = Object.keys(this.props.troopsData).filter(f => this.props.troopsData[f].troopLeader === this.props.userID);
        return (
            <Fragment>
            <div className="extra-edit-before-submit-form">
                <h5>Če želiš spremeniti vlogo vodnika, moraš najprej prestaviti vod k drugem vodniku ali ga zbrisati.</h5>
                <ul className="troops-of-user no-bullets">
                    {userTroopsHave.map(key =>
                        <li key={key} className="flex-columns">
                            <span className="labels">{this.props.troopsData[key].troopName}</span>
                            <span className="data">
                                <select name="user-name" defaultValue="vodnik" onChange={this.changeUserForTroop.bind(this, key)}>
                                    <option value="vodnik" disabled>Vodnik</option>
                                    {Object.keys(this.props.users).map(this.getUsersDropDown)}
                                </select>
                            </span>
                            <span className="delete"><button type="button" className="button-as-link red-font" onClick={this.decideDeleteOrArchiveTroop.bind(this, key)}>Zbriši vod</button></span>
                        </li>
                    )}
                </ul>
            </div>
            {(this.state.popUpDecideTroopDelArc) ? <DecideDeleteOrArchiveTroop
                archiveTroop={this.props.archiveTroop}
                deleteTroop={this.props.deleteTroop}
                selectedTroop={this.state.selectedTroop}
                troopsData={this.props.troopsData}
                popUpOpen={this.closePopUpSecond} /> :
                null
            }
            </Fragment>
        )
    }
}

export default ChangeTroopBeforeEditUser;