import React from 'react';
import TablesShowUsers from "./TablesShowUsers";

class ShowAllUsers extends React.Component {
    render() {
        if(this.props.users) {
            const tableHeader = ['Št', 'Ime in Priimek', 'E-mail', 'Telefonska številka', 'Vloga', 'Več'];
            const classesToSend = {
                wrapperTitle: "wrapper-users",
                tableTile: "users-table"
            }
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    <h1 className="yellow-font">Vodniki & Vodstvo</h1>
                    <div className="single-multi-troop">
                        <TablesShowUsers currentUser={this.props.currentUser} users={this.props.users} classes={classesToSend} tableHeader={tableHeader} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members"><div className="single-multi-troop">
                    <p className="notice-empty white-font">V bazi ni še registriranih uporabnikov!</p>
                </div></div>
            )
        }
    }
}

export default ShowAllUsers;