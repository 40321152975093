import React from 'react';
import {numberOfMembersPerYear} from "../statisticsCalculatorFunctions";
import FAMILIES, {SPECIAL_TROOPS} from "../../../constants/familesClubs";

class SyncMembershipOverYears extends React.Component {
    state = {
        success: false
    }

    getDataOfMembers = (event) => {
        const membersAllPerYearsObject = numberOfMembersPerYear(
            this.props.troopsMembers,
            this.props.troopsData,
            [FAMILIES.MC_FAMILY.VALUE, FAMILIES.GG_FAMILY.VALUE, FAMILIES.PP_CLUB.VALUE, FAMILIES.RR_CLUB.VALUE, FAMILIES.G_CLUB.VALUE],
            SPECIAL_TROOPS,
            Object.keys(this.props.years.all)
        );
        const membersMcPerYearsObject = numberOfMembersPerYear(this.props.troopsMembers, this.props.troopsData, [FAMILIES.MC_FAMILY.VALUE], SPECIAL_TROOPS, Object.keys(this.props.years.all));
        const membersGgPerYearsObject = numberOfMembersPerYear(this.props.troopsMembers, this.props.troopsData, [FAMILIES.GG_FAMILY.VALUE], SPECIAL_TROOPS, Object.keys(this.props.years.all));
        const membersPpPerYearsObject = numberOfMembersPerYear(this.props.troopsMembers, this.props.troopsData, [FAMILIES.PP_CLUB.VALUE], SPECIAL_TROOPS, Object.keys(this.props.years.all));
        const membersRrPerYearsObject = numberOfMembersPerYear(this.props.troopsMembers, this.props.troopsData, [FAMILIES.RR_CLUB.VALUE], SPECIAL_TROOPS, Object.keys(this.props.years.all));
        const membersGPerYearsObject = numberOfMembersPerYear(this.props.troopsMembers, this.props.troopsData, [FAMILIES.G_CLUB.VALUE], SPECIAL_TROOPS, Object.keys(this.props.years.all));

        const startDate = new Date(this.props.years.currentStartSchoolDate).getFullYear();
        const endDate = new Date(this.props.years.currentEndSchoolDate).getFullYear();
        const combineYears = `${startDate}-${endDate}`;

        const numbers = {
            all: membersAllPerYearsObject[combineYears],
            mc: membersMcPerYearsObject[combineYears],
            gg: membersGgPerYearsObject[combineYears],
            pp: membersPpPerYearsObject[combineYears],
            rr: membersRrPerYearsObject[combineYears],
            g: membersGPerYearsObject[combineYears]
        }

        this.props.addTroopsNumbers(numbers);
        this.setState({success: true})
    }

    closeMessage = () => {
        this.setState({success: false})
    }

    render() {
        const startDate = new Date(this.props.years.currentStartSchoolDate).getFullYear();
        const endDate = new Date(this.props.years.currentEndSchoolDate).getFullYear();
        const combineYears = `${startDate}-${endDate}`;
        return (
            <div className="right-side-filters right-side-filters-on-white">
                <div className="button-with-tooltip">
                    <button name="sync-membership-over-years" type="button" className="smaller-button" disabled={(this.props.years.all[combineYears].endOfSchoolYear)&& 'disabled'} onClick={this.getDataOfMembers}>Sinhroniziraj</button>
                    <div className="tooltip box-shadow">
                        {(this.props.years.all[combineYears].endOfSchoolYear) ?
                            <p className="notice-edit">Trenutno izbrano leto je zaključeno, zato sinhronizacija ni več mogoča!</p>
                            : <p className="notice-edit">S klikom na ta gumb boš posodobil graf članstva. Vendar bodi previden, da ko je konec šolskega leta, ne menjaš na prejšnje leto in klikneš na ta gumb, ker potem podatki ne bodo več pravi!</p>
                        }
                    </div>
                    {(this.state.success) &&
                        <p className="notice-edit success-message-out-of-form">
                            <span>Sinhronizacija je bila uspešna!</span>
                            <button type="button" className="button-as-link" onClick={this.closeMessage}>X</button>
                        </p>
                    }
                </div>
            </div>
        )
    }
}

export default SyncMembershipOverYears;