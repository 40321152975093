import React, {Fragment} from "react";
import TablesShowMeetings from "../meetings/TablesShowMeetings";

class ShowAllMeetings extends React.Component {
    render() {
        let checkMeetings;
        if(this.props.activeTroop) {
            if('meetings' in this.props.troopsMeetings[this.props.activeTroop]) {
                checkMeetings = Object.keys(this.props.troopsMeetings[this.props.activeTroop].meetings);
            } else {
                checkMeetings = [];
            }
        } else {
            checkMeetings = Object.keys(this.props.troopsMeetings).filter(f => this.props.troopsMeetings[f].meetings !== undefined);
        }
        if(checkMeetings.length) {
            const tableHeader = ['Št', 'Sestanek', 'Lokacija', 'Datum', 'Čas', 'Več'];
            const classesToSend = {
                wrapperTitle: "single-multi-troop-meetings",
                tableTile: "meetings-table"
            }
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-meetings">
                    {(this.props.activeTroop) ?
                        <Fragment>
                            <h2 className="white-font">Tedenski sestanki</h2>
                            <TablesShowMeetings adminAccess={true} troopID={this.props.activeTroop} troopData={this.props.troopsData} troopsMeetings={this.props.troopsMeetings} classes={classesToSend} tableHeader={tableHeader} />
                        </Fragment> :
                        <Fragment>
                            <h2 className="white-font">Tedenski sestanki</h2>
                            {Object.keys(this.props.troopsMeetings).map(key =>
                                ( this.props.troopsMeetings[key].meetings !== undefined) ?
                                    <TablesShowMeetings key={key} troopID={key} troopsData={this.props.troopsData} troopsMeetings={this.props.troopsMeetings} classes={classesToSend} tableHeader={tableHeader} /> :
                                    <p key={key} className="notice-empty white-font">V bazi še ni zabeleženih sestankov za vod {this.props.troopsData[key].troopName}.</p>
                            )}
                        </Fragment>
                    }
                </div>
            )
        } else {
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    <p className="notice-empty white-font">V bazi še ni zabeleženih sestankov.</p>
                </div>
            )
        }
    }
}
export default ShowAllMeetings;
