import React from "react";
import NotFound from "../NotFound";
import ListData from "../templates/ListData";
import {reTimeInInput} from "../../helpers";

class MeetingCard extends React.Component {
    state = {
        troopMeetingExist: false,
        editable: false,
        areYouSureDelete: false,
        lateNumber: {},
        earlyNumber: {}
    };
    troopId = this.props.match.params.troopId;
    meetingId = this.props.match.params.meetingId;

    componentDidMount() {
        const troopExist = Object.keys(this.props.troopsMeetings).filter(f => f === this.troopId);
        if(troopExist.length) {
            const meetingExist = Object.keys(this.props.troopsMeetings[this.troopId]['meetings']).filter(f => f === this.meetingId);
            if(meetingExist.length) {
                this.setState({  troopMeetingExist: true })
            }
        }

        const lateNumber = {...this.state.lateNumber};
        const earlyNumber = {...this.state.earlyNumber};
        const thisClass = this;
        Object.keys(this.props.troopsMeetings[this.troopId]['meetings'][this.meetingId]['presence']).map(function (key) {
            let number;
            const rawData = thisClass.props.troopsMeetings[thisClass.troopId]['meetings'][thisClass.meetingId]['presence'][key];
            const rawNumber = (Array.isArray(rawData.match(/\d+/))) ? rawData.match(/\d+/)[0] : 9999;
            const lateText = "Zamudil/a "+rawNumber+" minut";
            const earlyText = "Predčasno odšel/la "+rawNumber+" minut";

            if(rawData === lateText) {
                number = thisClass.props.troopsMeetings[thisClass.troopId]['meetings'][thisClass.meetingId]['presence'][key].match(/\d+/)[0];
                lateNumber[key] = number;
                thisClass.setState({lateNumber: lateNumber});
            }
            if(rawData === earlyText) {
                number = thisClass.props.troopsMeetings[thisClass.troopId]['meetings'][thisClass.meetingId]['presence'][key].match(/\d+/)[0];
                earlyNumber[key] = number;
                thisClass.setState({earlyNumber: earlyNumber});
            }
            return null;
        });
    }

    editData = () => {
        this.setState({editable: true});
    };
    stopEditData = () => {
        this.setState({editable: false});
    };

    deleteMeetingSure = () => {
        this.setState({areYouSureDelete: true})
    }
    deleteMeetingSureNo = () => {
        this.setState({areYouSureDelete: false})
    }
    deleteMeetingSureYes = () => {
        this.props.deleteCurrentMeeting(this.props.troopsMeetings[this.troopId]['meetings'][this.meetingId], this.props.troopsMeetings[this.troopId].troopRole);
        this.setState({  troopMeetingExist: false })
        this.props.history.goBack();
    }

    handleChange = (event) => {
        let updatedCurrentMeeting;
        if(event.currentTarget.name === "time-from") {
            updatedCurrentMeeting = {
                ...this.props.troopsMeetings[this.troopId]['meetings'][this.meetingId],
                "time": `${event.currentTarget.value} - ${reTimeInInput(this.props.troopsMeetings[this.troopId]['meetings'][this.meetingId].time, true)}`
            };
        } else if (event.currentTarget.name === "time-to") {
            updatedCurrentMeeting = {
                ...this.props.troopsMeetings[this.troopId]['meetings'][this.meetingId],
                "time": `${reTimeInInput(this.props.troopsMeetings[this.troopId]['meetings'][this.meetingId].time)} - ${event.currentTarget.value}`
            };
        } else {
            updatedCurrentMeeting = {
                ...this.props.troopsMeetings[this.troopId]['meetings'][this.meetingId],
                [event.currentTarget.name]: event.currentTarget.value
            };
        }
        this.props.updateCurrentMeeting(updatedCurrentMeeting, this.props.troopsMeetings[this.troopId].troopRole);
    }

    handleChangePresence = (event) => {
        let updatedCurrentMeeting
        const lateNumber = {...this.state.lateNumber};
        const earlyNumber = {...this.state.earlyNumber};
        updatedCurrentMeeting = {...this.props.troopsMeetings[this.troopId]['meetings'][this.meetingId]};
        if(event.currentTarget.id === "extra-input-select") {
            updatedCurrentMeeting.presence[event.currentTarget.name] = `Zamudil/a ${event.currentTarget.value} minut`;
            lateNumber[event.currentTarget.name] = event.currentTarget.value;
            this.setState({lateNumber: lateNumber});
        } else if(event.currentTarget.id === "extra-input-select-second") {
            updatedCurrentMeeting.presence[event.currentTarget.name] = `Predčasno odšel/la ${event.currentTarget.value} minut`;
            earlyNumber[event.currentTarget.name] = event.currentTarget.value;
            this.setState({earlyNumber: earlyNumber});
        } else {
            updatedCurrentMeeting.presence[event.currentTarget.name] = event.currentTarget.value;
        }
        this.props.updateCurrentMeeting(updatedCurrentMeeting, this.props.troopsMeetings[this.troopId].troopRole);
    }

    render() {
        if(this.state.troopMeetingExist && this.props.troopsMeetings[this.troopId]['meetings'][this.meetingId]) {
            const troopsCount = Object.keys(this.props.troopsMeetings).length;
            const troopName = this.props.troopsData[this.troopId]['troopName'];
            const meetingAllSingleData = this.props.troopsMeetings[this.troopId]['meetings'][this.meetingId];
            const { title, description, date, time, place} = meetingAllSingleData;
            const generalData = [
                {
                    'editable': this.state.editable,
                    'label': 'Datum',
                    'labelEnglish': 'date',
                    'data': date,
                    'inputType': 'date',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Čas',
                    'labelEnglish': 'time',
                    'data': time,
                    'inputType': 'time',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Lokacija',
                    'labelEnglish': 'place',
                    'data': place,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Naslov',
                    'labelEnglish': 'title',
                    'data': title,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Opis',
                    'labelEnglish': 'description',
                    'data': description,
                    'inputType': 'textarea',
                    'handleChange': this.handleChange,
                },
            ];
            let troopEnter;
            if(troopsCount>1) {
                troopEnter = {
                    'editable': this.state.editable,
                    'label': 'Vod',
                    'labelEnglish': 'troopName',
                    'data': troopName,
                    'inputType': 'notEdit',
                    'handleChange': this.handleChange,
                }
            } else {
                troopEnter = null;
            }
            const addTroopToGeneralData = [troopEnter, ...generalData];
            let meetingPresence = [];
            const thisClass = this;
            Object.keys(this.props.troopsMeetings[this.troopId]['meetings'][this.meetingId]['presence']).map(function (key) {
                let member;
                ('members' in thisClass.props.troopsMembers[thisClass.troopId] && key in thisClass.props.troopsMembers[thisClass.troopId]['members']) ?
                    member = `${thisClass.props.troopsMembers[thisClass.troopId]['members'][key].name} ${thisClass.props.troopsMembers[thisClass.troopId]['members'][key].lastName}` :
                    member = `${thisClass.props.troopsMembers[thisClass.troopId]['pastMembers'][key].name} ${thisClass.props.troopsMembers[thisClass.troopId]['pastMembers'][key].lastName}`
                const rawData = thisClass.props.troopsMeetings[thisClass.troopId]['meetings'][thisClass.meetingId]['presence'][key];
                const rawNumber = (Array.isArray(rawData.match(/\d+/))) ? rawData.match(/\d+/)[0] : 9999;
                const lateText = "Zamudil/a "+rawNumber+" minut";
                const earlyText = "Predčasno odšel/la "+rawNumber+" minut";
                let memberLate;
                (rawData !== "Je bil/a prisoten/na" &&
                    rawData !== "Ni bil/a prisoten/na" &&
                    rawData !== "Predčasno odšel/la" &&
                    rawData !== earlyText) ? memberLate = true : memberLate = false;
                let memberEarly;
                (rawData !== "Je bil/a prisoten/na" &&
                    rawData !== "Ni bil/a prisoten/na" &&
                    rawData !== "Zamudil/a" &&
                    rawData !== lateText ) ? memberEarly = true : memberEarly = false;
                let lateNumberSelect;
                (thisClass.state.lateNumber[key] !== undefined) ? lateNumberSelect = `Zamudil/a ${thisClass.state.lateNumber[key]} minut` :
                    lateNumberSelect = "Zamudil/a";
                let earlyNumberSelect;
                (thisClass.state.earlyNumber[key] !== undefined) ? earlyNumberSelect = `Predčasno odšel/la ${thisClass.state.earlyNumber[key]} minut` :
                    earlyNumberSelect = "Predčasno odšel/la";
                const singleMemberPresence = {
                    'editable': thisClass.state.editable,
                    'label': member,
                    'labelEnglish': key,
                    'data': thisClass.props.troopsMeetings[thisClass.troopId]['meetings'][thisClass.meetingId]['presence'][key],
                    'color': (thisClass.props.troopsMeetings[thisClass.troopId]['meetings'][thisClass.meetingId]['presence'][key] === 'Ni bil/a prisoten/na') ? "red-font" : "green-font",
                    'inputType': 'select',
                    'selectOptions' : [
                        {
                            'value': 'Je bil/a prisoten/na',
                            'label': 'Je bil/a prisoten/na'
                        },
                        {
                            'value': 'Ni bil/a prisoten/na',
                            'label': 'Ni bil/a prisoten/na'
                        },
                        {
                            'value': lateNumberSelect,
                            'label': lateNumberSelect
                        },
                        {
                            'value': earlyNumberSelect,
                            'label': earlyNumberSelect
                        }
                    ],
                    'extraInput': memberLate,
                    'extraInputSecond': memberEarly,
                    'handleChange': thisClass.handleChangePresence,
                }
                meetingPresence = [...meetingPresence, singleMemberPresence];
                return null;
            });

            return (
                <div className="cards-presentation meeting-card">
                    <h1 className="yellow-font">Tedensko srečanje</h1>
                    <div className="box-shadow buttons-menu"><ul>
                        {(!this.state.editable) ?
                            <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.editData}>Uredi podatke</button></li> :
                            <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.stopEditData}>Zaključi urejanje</button></li>
                        }
                        <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.deleteMeetingSure}>Zbriši tedensko srečanje</button></li>
                    </ul></div>
                    <div className="box-shadow">
                        <h2 className="blue-font">{title}</h2>
                        <div className="flex-columns meeting-data">
                            <div className="meeting-general left-side-data one-half-flex">
                                <h4 className="blue-font">Splošni podatki</h4>
                                <ListData data={addTroopToGeneralData} />
                            </div>
                            <div className="meeting-presence right-side-data one-half-flex">
                                <h4 className="blue-font">Prisotnost</h4>
                                {(!this.state.editable) ? null :
                                    <p className="notice-edit">Če je član zamudil ali predčasno odšel izberete v spustnem meniju "Zamudil/a" ali "Zamudil/a n minut" ali "Predčasno odšel/la" ali "Predčasno odšel/la n minut" in prikazalo se vam bo polje, kjer vnesete minute.</p> }
                                <ListData data={meetingPresence} />
                            </div>
                        </div>
                    </div>
                    {(!this.state.areYouSureDelete) ? null :
                        <div className="wrapper-popup">
                            <div className="inner-popup are-you-sure-popup box-shadow">
                                <div className="content-popup">
                                    <h4>Ali res želiš zbrisati tedensko srečanje?</h4>
                                    <div className="buttons-wrapper">
                                        <button type="button" className="yes" onClick={this.deleteMeetingSureYes}>Da</button>
                                        <button type="button" className="no" onClick={this.deleteMeetingSureNo}>Ne</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )
        } else {
            return (
                <NotFound />
            )
        }

    }
}

export default MeetingCard;
