import React from 'react';
import ScoutGroupStatistics from "./ScoutGroupStatistics";
import MembershipOverYears from "./MembershipOverYears";
import SyncMembershipOverYears from "./SyncMembershipOverYears";
import ALL_ROLES from "../../../constants/roles";

class StatisticMainCanvas extends React.Component {
    render() {
        return (
            <div className="statistics-main-canvas">
                <h2 className="white-font">Statistični podatki</h2>
                <ScoutGroupStatistics
                    currentUser={this.props.currentUser}
                    troopsData={this.props.troopsData}
                    troopsMembers={this.props.troopsMembers}
                    years={this.props.years}
                />
                <div className="cards-presentation one-column-card membership-over-years">
                    <div className="box-shadow">
                        <div className="flex-columns items-center control-panel-header">
                            <h4 className="blue-font">Članstvo skozi leta</h4>
                            {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null :
                                <SyncMembershipOverYears
                                    troopsData={this.props.troopsData}
                                    troopsMembers={this.props.troopsMembers}
                                    addTroopsNumbers={this.props.addTroopsNumbers}
                                    years={this.props.years}
                                />
                            }
                        </div>
                        <MembershipOverYears
                            troopsNumbers={this.props.troopsNumbers}
                            windowWidth={this.props.windowWidth}
                            windowHeight={this.props.windowHeight}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default StatisticMainCanvas;