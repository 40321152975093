import React, {Fragment} from 'react';
import GroupAllMembersTable from "./GroupAllMembersTable";
import FilterByTroopsAllData from "./FilterByTroopsAllData";

import {
    combineMembersInSingleArray,
    combineMembersInSingleArrayRaw,
    foundMultiMembers
} from "../../../helpers";

import {REGISTER_MEMBER} from "../../../constants/routes";
import {Link} from "react-router-dom";

class GroupAllMembers extends React.Component {
    state = {
        selectedTroop: 'all-troops',
        searchParam: '',
        clickedMultiMember: null,
        findMultiMembers: null
    }

    filterChangeTroop = (selectedTroop) => {
        this.setState({selectedTroop: selectedTroop})
    }

    filterSearchMember = (term) => {
        this.setState({searchParam: term});
    }

    multiMemberDuplicates = (parentMember) => {
        this.setState({
            clickedMultiMember: parentMember.memberID,
            findMultiMembers: parentMember.memberDist
        });
    }

    render() {
        const allMembersRawArray = combineMembersInSingleArrayRaw(this.props.troopsMembers);
        const allMembersRaw = allMembersRawArray.allMembers;
        const duplicateMembersRaw = allMembersRawArray.allMembersDuplicateIndex;
        const allMembers = combineMembersInSingleArray(this.props.troopsMembers);
        const allMembersClean = allMembers.allMembersUnique;
        const duplicatedMembers = allMembers.allMembersDuplicateIndex;
        let orderMemberByName;
        if(this.state.selectedTroop === "all-troops") {
            orderMemberByName = allMembersClean.filter(f => this.props.troopsMembers[f.troop].members[f.member].name.toLowerCase().includes(this.state.searchParam.toLowerCase()) ||
                                                            this.props.troopsMembers[f.troop].members[f.member].lastName.toLowerCase().includes(this.state.searchParam.toLowerCase()) ||
                                                            this.props.troopsMembers[f.troop].members[f.member].address.toLowerCase().includes(this.state.searchParam.toLowerCase()));
        } else {
            orderMemberByName = allMembersRaw.filter(f => (f.troop === this.state.selectedTroop && this.props.troopsMembers[f.troop].members[f.member].name.toLowerCase().includes(this.state.searchParam.toLowerCase())) ||
                                                            (f.troop === this.state.selectedTroop && this.props.troopsMembers[f.troop].members[f.member].lastName.toLowerCase().includes(this.state.searchParam.toLowerCase())) ||
                                                            (f.troop === this.state.selectedTroop && this.props.troopsMembers[f.troop].members[f.member].address.toLowerCase().includes(this.state.searchParam.toLowerCase())));
        }
        const checkMembers = Object.keys(this.props.troopsMembers).filter(f => this.props.troopsMembers[f].members !== undefined);
        if(checkMembers.length) {
            const tableHeader = ['Št.', 'Ime in Priimek', 'Telefonska številka člana', 'Telefonska številka starša', 'Udeležba na sestankih', 'Vod', 'Več'];
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    <div className="flex-columns control-panel-header">
                        <h1 className="yellow-font">Člani vodov</h1>
                        <div className="right-side-filters flex-columns">
                            <FilterByTroopsAllData
                                troopsData={this.props.troopsData}
                                searchParam={this.state.searchParam}
                                filterChangeTroop={this.filterChangeTroop}
                                filterSearchMember={this.filterSearchMember}
                            />
                            <Link className="link-new-member link-as-button negative-color" to={REGISTER_MEMBER}>Nov član</Link>
                        </div>
                    </div>
                    <div className="single-multi-troop single-multi-troop-members">
                        <table className="general-table members-table">
                            <thead>
                            <tr>
                                {tableHeader.map((key, index) => <th key={index}>{key}</th>)}
                            </tr>
                            </thead>
                            <tbody>
                            {orderMemberByName.map((key, index) =>
                                <Fragment key={key.member}>
                                <GroupAllMembersTable
                                    order={index}
                                    trueOrder={(this.state.selectedTroop === "all-troops") ? allMembersClean.findIndex((element) => (element.member === key.member) && true) : allMembersRaw.findIndex((element) => (element.member === key.member) && true)}
                                    troopID={key.troop}
                                    memberID={key.member}
                                    duplicatedMembers={(this.state.selectedTroop === "all-troops") ? duplicatedMembers : duplicateMembersRaw}
                                    clickedMultiMember={this.state.clickedMultiMember}
                                    troopsData={this.props.troopsData}
                                    troopsMembers={this.props.troopsMembers}
                                    troopsMeetings={this.props.troopsMeetings}
                                    schoolYears={this.props.schoolYears}
                                    multiMemberDuplicates={this.multiMemberDuplicates} />
                                {(this.state.clickedMultiMember !== null && this.state.clickedMultiMember === key.member) ?
                                    foundMultiMembers(this.props.troopsMembers, this.state.findMultiMembers, key.member).map((key2) =>
                                        <GroupAllMembersTable
                                            key={key2.member}
                                            order={null}
                                            extraClass="duplicated-lines"
                                            troopID={key2.troop}
                                            memberID={key2.member}
                                            duplicatedMembers={[-9999]}
                                            troopsData={this.props.troopsData}
                                            troopsMembers={this.props.troopsMembers}
                                            troopsMeetings={this.props.troopsMeetings}
                                            schoolYears={this.props.schoolYears} />
                                    ) :
                                    null
                                }
                                </Fragment>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    <p className="notice-empty white-font">V bazi še ni članov.</p>
                </div>
            )
        }
    }
}

export default GroupAllMembers;