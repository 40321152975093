import React, {Fragment} from 'react';
import * as calcStatistics from '../statisticsCalculatorFunctions';
import {combineMembersInSingleArray} from '../../../helpers';
import ALL_ROLES from "../../../constants/roles";
import FAMILIES, {SPECIAL_TROOPS} from "../../../constants/familesClubs";

class ScoutGroupStatistics extends React.Component {
    render() {
        const allTroops = calcStatistics.numOfTroopsFamilies(this.props.troopsData, [SPECIAL_TROOPS.NONE.VALUE]);
        const numOfFamilies = calcStatistics.numOfTroopsFamilies(this.props.troopsData, [SPECIAL_TROOPS.FAMILY.VALUE]);
        const numOfClubs = calcStatistics.numOfTroopsFamilies(this.props.troopsData, [SPECIAL_TROOPS.CLUB.VALUE]);
        const numOfMcTroops = calcStatistics.numOfTroops(this.props.troopsData, [SPECIAL_TROOPS.NONE.VALUE], FAMILIES.MC_FAMILY.VALUE);
        const numOfGgTroops = calcStatistics.numOfTroops(this.props.troopsData, [SPECIAL_TROOPS.NONE.VALUE], FAMILIES.GG_FAMILY.VALUE);
        const allMembers = combineMembersInSingleArray(this.props.troopsMembers).allMembersUnique.length;
        const {paidMembershipTotal, paidMembershipPercentage, unPaidMembershipTotal, unPaidMembershipPercentage} = calcStatistics.membershipPayed(this.props.troopsMembers, combineMembersInSingleArray(this.props.troopsMembers), this.props.years);
        const mcMembers = calcStatistics.numberOfMembers(this.props.troopsMembers, this.props.troopsData, FAMILIES.MC_FAMILY.VALUE, SPECIAL_TROOPS);
        const ggMembers = calcStatistics.numberOfMembers(this.props.troopsMembers, this.props.troopsData, FAMILIES.GG_FAMILY.VALUE, SPECIAL_TROOPS);
        const ppMembers = calcStatistics.numberOfMembers(this.props.troopsMembers, this.props.troopsData, FAMILIES.PP_CLUB.VALUE, SPECIAL_TROOPS);
        const rrMembers = calcStatistics.numberOfMembers(this.props.troopsMembers, this.props.troopsData, FAMILIES.RR_CLUB.VALUE, SPECIAL_TROOPS);
        const gMembers = calcStatistics.numberOfMembers(this.props.troopsMembers, this.props.troopsData, FAMILIES.G_CLUB.VALUE, SPECIAL_TROOPS);
        return (
            <div className="cards-presentation one-column-card scout-group-statistics">
                <div className="flex-columns">
                    <div className="box-shadow one-half-flex-with-space box-margin">
                        <h4 className="blue-font">Podatki klubov, družin in vodov</h4>
                        <div className="flex-columns">
                            <div className="full-column-flex">
                                <div className="data-inner-wrapper">
                                    <ul className="no-bullets">
                                        {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null :
                                        <li className="flex-columns">
                                            <span className="labels one-third-flex">Število vodov</span>
                                            <span className="data two-third-flex">{allTroops}</span>
                                        </li>}
                                        <li className="flex-columns">
                                            <span className="labels one-third-flex">Število družin</span>
                                            <span className="data two-third-flex">{numOfFamilies}</span>
                                        </li>
                                        {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null :
                                        <li className="flex-columns">
                                            <span className="labels one-third-flex">Število klubov</span>
                                            <span className="data two-third-flex">{numOfClubs}</span>
                                        </li> }
                                        {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE) ? null :
                                        <li className="flex-columns">
                                            <span className="labels one-third-flex">Število MČ vodov</span>
                                            <span className="data two-third-flex">{numOfMcTroops}</span>
                                        </li>}
                                        {(this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null :
                                        <li className="flex-columns">
                                            <span className="labels one-third-flex">Število GG vodov</span>
                                            <span className="data two-third-flex">{numOfGgTroops}</span>
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-shadow one-half-flex-with-space box-margin">
                        <h4 className="blue-font">Podatki članov</h4>
                        <div className="flex-columns">
                            <div className="full-column-flex">
                                <div className="data-inner-wrapper">
                                    <ul className="no-bullets">
                                        <li className="flex-columns">
                                            <span className="labels one-third-flex">Skupno število članov</span>
                                            <span className="data two-third-flex">{allMembers}</span>
                                        </li>
                                        {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE) ? null :
                                        <li className="flex-columns">
                                            <span className="labels one-third-flex">Število članov MČ</span>
                                            <span className="data two-third-flex">{mcMembers}</span>
                                        </li> }
                                        {(this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null :
                                        <li className="flex-columns">
                                            <span className="labels one-third-flex">Število članov GG</span>
                                            <span className="data two-third-flex">{ggMembers}</span>
                                        </li> }
                                        {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null : <Fragment>
                                        <li className="flex-columns">
                                            <span className="labels one-third-flex">Število članov PP</span>
                                            <span className="data two-third-flex">{ppMembers}</span>
                                        </li>
                                        <li className="flex-columns">
                                            <span className="labels one-third-flex">Število članov RR</span>
                                            <span className="data two-third-flex">{rrMembers}</span>
                                        </li>
                                        <li className="flex-columns">
                                            <span className="labels one-third-flex">Število članov Grče</span>
                                            <span className="data two-third-flex">{gMembers}</span>
                                        </li>
                                        </Fragment> }
                                        <li className="flex-columns">
                                            <span className="labels one-third-flex">Plačane članarine</span>
                                            <span className="data two-third-flex">{`${paidMembershipTotal} (${paidMembershipPercentage}%)`}</span>
                                        </li>
                                        <li className="flex-columns">
                                            <span className="labels one-third-flex">Neplačane članarine</span>
                                            <span className="data two-third-flex">{`${unPaidMembershipTotal} (${unPaidMembershipPercentage}%)`}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ScoutGroupStatistics;