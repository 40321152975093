import React from 'react';
import TablesShowTroops from "./TablesShowTroops";

class ShowAllTroops extends React.Component {
    render() {
        if(this.props.troopsData && Object.keys(this.props.troopsData).length > 0) {
            const tableHeader = ['Št', 'Ime', 'Družina', 'Vodnik', 'Več'];
            const classesToSend = {
                wrapperTitle: "wrapper-troops",
                tableTile: "troops-table"
            }
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    <h1 className="yellow-font">Vodi</h1>
                    <div className="single-multi-troop">
                        <TablesShowTroops troopsData={this.props.troopsData} users={this.props.users} classes={classesToSend} tableHeader={tableHeader} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members"><div className="single-multi-troop">
                    <p className="notice-empty white-font">V bazi ni aktivnih vodov!</p>
                </div></div>
            )
        }
    }
}

export default ShowAllTroops;