import React from "react";
import {generatePushID, reCapitalizeData, reTimeInInput} from "../../helpers";
import PLACES from "../../constants/meetingsPlaces";

class RegisterWeeklyMeeting extends React.Component {
    // Weekly meeting refs
    weeklyMeetingDate = React.createRef();
    weeklyMeetingStartTime = React.createRef();
    weeklyMeetingEndTime = React.createRef();
    weeklyMeetingPlace = React.createRef();
    weeklyMeetingTitle = React.createRef();
    weeklyMeetingDescription = React.createRef();

    // State for checking which troop selected
    state = {
        selectedTroop: null,
        selectedTroopReset: false,
        lateField: {},
        leftEarlyField: {},
        membersPresence: {},
        showFormSingleTroop: true,
        succeed: false,
        troopName: null
    }

    troopId = this.props.match.params.troopID;
    troopsCount = Object.keys(this.props.troopsData).length;

    componentDidMount() {
        if(this.troopsCount <= 1) {
            this.setState({
                selectedTroop: Object.keys(this.props.troopsData)[0],
            })
        } else {
            if(this.troopId) {
                this.setState({
                    selectedTroop: this.troopId
                })
            } else {
                this.setState({
                    selectedTroop: null
                })
            }

        }
    }

    RegisterWeeklyMeeting = (event) => {
        // Stop the form from submitting
        event.preventDefault();

        const weeklyMeeting = {
            troop: this.state.selectedTroop,
            troopRole: this.props.troopsData[this.state.selectedTroop].troopRole,
            meetingID: `sestanek${generatePushID()}`,
            date: this.weeklyMeetingDate.current.value,
            time: this.weeklyMeetingStartTime.current.value + " - " + this.weeklyMeetingEndTime.current.value,
            place: this.weeklyMeetingPlace.current.value,
            title: this.weeklyMeetingTitle.current.value,
            description: this.weeklyMeetingDescription.current.value,
            presence: this.state.membersPresence
        };

        // Call function in CanvasUser
        this.props.addWeeklyMeeting(weeklyMeeting);

        // Refresh the form
        if(this.troopsCount <= 1) {
            this.setState({
                selectedTroop: Object.keys(this.props.troopsData)[0]
            })
        } else {
            this.setState({
                selectedTroop: null
            })
        }
        this.setState({
            selectedTroopReset: false,
            lateField: {},
            leftEarlyField: {},
            membersPresence: {},
            showFormSingleTroop: false,
            succeed: true,
            troopName: this.props.troopsData[this.state.selectedTroop].troopName
        });
        event.currentTarget.reset();
    }

    TroopSelect = (event) => {
        this.setState({
            selectedTroop: event.target.value,
            selectedTroopReset: true
        })
    }

    TroopDeselect = (event) => {
        event.preventDefault();
        this.setState({
            selectedTroop: null,
            selectedTroopReset: false
        })
    }

    MembersPresence = (key, event) => {
        // Take a copy of existing state
        const lateField = { ...this.state.lateField };
        const leftEarlyField = { ...this.state.leftEarlyField };
        const membersPresence = { ...this.state.membersPresence };
        Object.keys(this.props.troopsMembers[this.state.selectedTroop].members).map(key2 =>
            (key2 === key && event.target.value === "Zamudil/a") ? lateField[key2] = true :
                (key2 !== key ) ? lateField[key2] : lateField[key2] = false
        );
        Object.keys(this.props.troopsMembers[this.state.selectedTroop].members).map(key3 =>
            (key3 === key && event.target.value === "Predčasno odšel/la") ? leftEarlyField[key3] = true :
                (key3 !== key ) ? leftEarlyField[key3] : leftEarlyField[key3] = false
        );
        Object.keys(this.props.troopsMembers[this.state.selectedTroop].members).map(key4 =>
            (key4 === key) ? membersPresence[key4] = event.target.value : membersPresence[key4]
        );
        this.setState({
            lateField: lateField,
            leftEarlyField: leftEarlyField,
            membersPresence: membersPresence
        })
    }
    MembersPresenceLate = (key, event) => {
        const membersPresence = { ...this.state.membersPresence };
        Object.keys(membersPresence).map(key2 =>
            (key2 === key) ? membersPresence[key2] = `Zamudil/a ${event.target.value} minut` : membersPresence[key2]
        );
        this.setState({
            membersPresence: membersPresence
        })
    }

    MembersPresenceLeftEarly = (key, event) => {
        const membersPresence = { ...this.state.membersPresence };
        Object.keys(membersPresence).map(key2 =>
            (key2 === key) ? membersPresence[key2] = `Predčasno odšel/la ${event.target.value} minut` : membersPresence[key2]
        );
        this.setState({
            membersPresence: membersPresence
        })
    }

    closePopUpClicked = () => { this.setState({showFormSingleTroop: true, succeed: false, troopName: null}); }

    form() {
        if(this.state.selectedTroop != null && 'members' in this.props.troopsMembers[this.state.selectedTroop]) {
            return (
                <div className="wrapper_forms">
                    <form id="register-weekly-meeting" className="form-settings" onSubmit={this.RegisterWeeklyMeeting}>
                        <div className="body-form">
                            <div className="form-section general-meeting-data">
                                <h2 className="white-font">Podatki sestanka</h2>
                                <p className="single-input medium-left weekly-meeting-date">
                                    <input name="weekly-meeting-date" className="negative-color" ref={this.weeklyMeetingDate} type="date" required />
                                    <label className="white-font">Datum vodovega srečanja</label>
                                </p>
                                <p className="single-input medium-right input-from-to weekly-meeting-time">
                                    <span className="from">
                                        <label className="white-font">Od</label>
                                        <input name="weekly-meeting-time-start" className="negative-color" ref={this.weeklyMeetingStartTime} type="time" required   defaultValue={reTimeInInput(this.props.troopsData[this.state.selectedTroop].troopMeetingsTime)} />
                                    </span>
                                    <span className="to">
                                        <label className="white-font">Do</label>
                                        <input name="weekly-meeting-time-end" className="negative-color" ref={this.weeklyMeetingEndTime} type="time" required min="06:00" max="23:59" defaultValue={reTimeInInput(this.props.troopsData[this.state.selectedTroop].troopMeetingsTime, true)} />
                                    </span>
                                    <label className="white-font">Termin vodovega srečanja</label>
                                </p>
                                <p className="single-input weekly-meeting-place">
                                    <input name="weekly-meeting-place" className="negative-color" ref={this.weeklyMeetingPlace} type="text" required defaultValue={reCapitalizeData(this.props.troopsData[this.state.selectedTroop].troopMeetingsPlace, PLACES)} />
                                    <label className="white-font">Kraj vodovega srečanja</label>
                                </p>
                                <p className="single-input medium-left weekly-meeting-title">
                                    <input name="weekly-meeting-title" className="negative-color" ref={this.weeklyMeetingTitle} type="text" required placeholder="Naslov sestanka" />
                                </p>
                                <p className="single-input medium-right weekly-meeting-description">
                                    <textarea name="weekly-meeting-description" className="negative-color" form="register-weekly-meeting" ref={this.weeklyMeetingDescription} rows="4" placeholder="Kratek opis sestanka. Kaj ste počeli na sestanku?" />
                                </p>
                            </div>
                            <div className="form-section members-meeting-data">
                                <h2 className="white-font">Prisotnost</h2>
                                <p className="notice-edit white-font">Pri izbiri zamude ali predčasnega odhoda se vam prikaže dodatno polje, kamor vnesete minute zapisane s številko.</p>
                                <ul className="list-inputs members-presence">
                                {Object.keys(this.props.troopsMembers[this.state.selectedTroop].members).map((key) => (
                                    <li key={key} className="single-list-item inline-items">
                                        <p className="list-item-label member"><span className="white-font">{this.props.troopsMembers[this.state.selectedTroop].members[key].name} {this.props.troopsMembers[this.state.selectedTroop].members[key].lastName}</span></p>
                                        <div className="radio-buttons-group" onChange={this.MembersPresence.bind(this, key)} >
                                            <p className="single-radio">
                                                <input type="radio" id={`${key}-member-present`} name={key} value="Je bil/a prisoten/na" required />
                                                <label className="white-font" htmlFor={`${key}-member-present`}>Je bil/a prisoten/na</label>
                                            </p>
                                            <p className="single-radio">
                                                <input type="radio" id={`${key}-member-not-present`} name={key} value="Ni bil/a prisoten/na" required />
                                                <label className="white-font" htmlFor={`${key}-member-not-present`}>Ni bil/a prisoten/na</label>
                                            </p>
                                            <p className="single-radio">
                                                <input type="radio" id={`${key}-member-late`} name={key} value="Zamudil/a" required />
                                                <label className="white-font" htmlFor={`${key}-member-late`}>Zamudil/a</label>
                                            </p>
                                            <p className="single-radio">
                                                <input type="radio" id={`${key}-member-left-early`} name={key} value="Predčasno odšel/la" required />
                                                <label className="white-font" htmlFor={`${key}-member-left-early`}>Predčasno odšel/la</label>
                                            </p>
                                        </div>
                                        {(!this.state.lateField[key]) ? null :
                                            <p className="single-input input-in-list">
                                                <input name="weekly-meeting-late-time" className="negative-color" type="number" min="1" max="60" onChange={this.MembersPresenceLate.bind(this, key)} required placeholder="10" />
                                            </p>
                                        }
                                        {(!this.state.leftEarlyField[key]) ? null :
                                            <p className="single-input input-in-list">
                                                <input name="weekly-meeting-late-time" className="negative-color" type="number" min="1" max="60" onChange={this.MembersPresenceLeftEarly.bind(this, key)} required placeholder="5" />
                                            </p>
                                        }
                                    </li>
                                ))}
                                </ul>
                            </div>
                        </div>
                        <div className="footer-form">
                            <button className="negative-color" type="submit">Vnesi vodovo srečanje</button>
                        </div>
                    </form>
                </div>
            )
        } else {
            if(this.state.selectedTroop == null) {
                return (<p className="notice-empty white-font">V spustnem meniju izberi vod, če želiš dodati podatke tedenskega srečanja. Spustni meni se prikaže, ko ima vod izbranega vodnika.</p>);
            } else {
                return (<p className="notice-empty white-font">Nimaš še dodanih članov voda. Dodaj kakšnega!</p>);
            }
        }
    }

    succeedPopUpThankYou() {
        return (
            (!this.state.succeed) ? null :
                <div className="wrapper-popup">
                    <div className="inner-popup on-succeed box-shadow">
                        <div className="content-popup">
                            <p className="floating-button"><button type="button" className="button-as-link" onClick={this.closePopUpClicked}>X</button></p>
                            <p className="form-success-message">Uspešno si vnesel nov tedenski sestanek za vod {this.state.troopName}</p>
                        </div>
                    </div>
                </div>

        )
    }

    render() {
        if(this.troopsCount > 1) {
            let defaultValueSelect = "izbira-voda";
            if(this.state.selectedTroop !== null) {
                defaultValueSelect = this.state.selectedTroop
            }
            return (
                <div className="wrapper-weekly-meeting-multi-troop">
                    <h1 className="yellow-font">Vnos tedenskega sestanka</h1>
                    <div className="select-single troop-selection">
                        <h2 className="white-font">Izbira voda</h2>
                        <div className="single-input weekly-meetup-troop div-as-p">
                            {(!this.state.selectedTroopReset) ?
                                <select name="weekly-meetup-troop" className="negative-color" onChange={this.TroopSelect} required value={defaultValueSelect}>
                                    <option value="izbira-voda" disabled>Izberi vod</option>
                                    {Object.keys(this.props.troopsData).map(key => <option key={key} value={key}>{this.props.troopsData[key].troopName}</option>)}
                                </select> :
                                <h3 className="yellow-font">{this.props.troopsData[this.state.selectedTroop].troopName} <button type="button" className="button-as-link red-font" onClick={this.TroopDeselect}>Zamenjaj vod</button></h3>
                            }
                        </div>
                    </div>
                    {this.form()}
                    {this.succeedPopUpThankYou()}
                </div>
            )
        } else {
            return (
                <div className="wrapper-weekly-meeting-single-troop">
                    <h1 className="yellow-font">Vnos tedenskega sestanka</h1>
                    {(this.state.showFormSingleTroop) ? this.form() : null}
                    {this.succeedPopUpThankYou()}
                </div>
            )
        }
    }
}

export default RegisterWeeklyMeeting;
