import React from 'react';

class PastMemberSingleTable extends React.Component {
    state = {
        lastMemberYear: null
    }
    componentDidMount() {
        if(this.props.details.progress) {
            const filterActivity = Object.keys(this.props.details.progress).filter(f => this.props.details.progress[f].membershipPayment === true);
            const sortByYear = filterActivity.sort((a, b) => b.split("-")[0] - a.split("-")[0]);
            if(sortByYear.length) {
                this.setState({
                    lastMemberYear: sortByYear[0]
                })
            } else {
                this.setState({
                    lastMemberYear: '/'
                })
            }
        } else {
            this.setState({
                lastMemberYear: '/'
            })
        }
    }

    restoreMemberFunction = () => {
        this.props.restoreCurrentMember(this.props.details, this.props.troopRole);
    }

    render() {
        return (
            <tr>
                <td data-label="Št." className="order">{this.props.order}</td>
                <td data-label="Ime in priimek" className="name-surname">{this.props.details.name} {this.props.details.lastName}</td>
                <td data-label="Član do leta" className="member-activity">{this.state.lastMemberYear}</td>
                <td data-label="Kontakt" className="contact-number">{this.props.details.telMember}</td>
                <td data-label="Obnovitev" className="restore"><button type="button" name="restoreMember" className="button-as-link in-table-link red-font" onClick={this.restoreMemberFunction}>Obnovi člana</button></td>
            </tr>
        )
    }
}

export default PastMemberSingleTable;