import React from 'react';
import MemberSkillsSingleMember from "./MemberSkillsSingleMember";

const MemberSkills = (props) => (
    <div className="wrapper-multi-troop wrapper-multi-troop-members">
        <h2 className="white-font">Veščine in znanja</h2>
        {Object.keys(props.troopsMembers).map(key =>
            <div key={key} className="wrapper-member-skills single-multi-troop">
                {(Object.keys(props.troopsData).length > 1) ? <h4 className="yellow-font">{props.troopsData[key].troopName}</h4> : null}
                <div className="member-skills flex-columns flex-multi-lines">
                    {Object.keys(props.troopsMembers[key]['members']).map((key2, index) =>
                        <MemberSkillsSingleMember
                            key={key2}
                            order={index+1}
                            updateCurrentMemberProgress={props.updateCurrentMemberProgress}
                            troopsMembers={props.troopsMembers}
                            selectedYear={props.selectedYear}
                            details={props.troopsMembers[key].members[key2]}
                            inElement={false}
                        />
                    )}
                </div>
            </div>
        )}
    </div>
);

export default MemberSkills;