import React, {Fragment} from 'react';
import {reTimeInInput, reCapitalizeData} from "../../../helpers";
import ListData from "../../templates/ListData";
import ALL_ROLES from "../../../constants/roles";
import * as ROUTES from "../../../constants/routes";
import FAMILIES, {SPECIAL_TROOPS} from "../../../constants/familesClubs";
import PLACES from "../../../constants/meetingsPlaces";
import DAYS_OF_THE_WEEK from "../../../constants/daysOfTheWeek";
import {REGISTER_MEMBER, REGISTER_WEEKLY_MEETING} from "../../../constants/routes";
import {Link} from "react-router-dom";

import ShowAllMembers from "../../members/ShowAllMembers";
import PastMembers from "../../members/PastMembers";
import ShowAllMeetings from "../../meetings/ShowAllMeetings";
import DecideDeleteOrArchiveTroop from "./DecideDeleteOrArchiveTroop";

class TroopsCardsSingleAdmin extends React.Component {
    state = {
        editable: false,
        popUpDecideTroopDelArc: false,
    };

    troopId = this.props.match.params.troopID;

    editData = () => {
        this.setState({editable: true});
    };
    stopEditData = () => {
        this.setState({editable: false});
    };

    closePopUpSecond = (click) => {
        this.setState({
            popUpDecideTroopDelArc: click
        })
    }

    decideDeleteOrArchiveTroop = () => {
        this.setState({
            popUpDecideTroopDelArc: true
        });
    }

    handleChange = (event) => {
        let updatedTroop;
        if(event.currentTarget.name === "troopMeetingsTime-from") {
            updatedTroop = {
                ...this.props.troopsData[this.troopId],
                "troopMeetingsTime": `${event.currentTarget.value} - ${reTimeInInput(this.props.troopsData[this.troopId].troopMeetingsTime, true)}`
            };
        } else if (event.currentTarget.name === "troopMeetingsTime-to") {
            updatedTroop = {
                ...this.props.troopsData[this.troopId],
                "troopMeetingsTime": `${reTimeInInput(this.props.troopsData[this.troopId].troopMeetingsTime)} - ${event.currentTarget.value}`
            };
        } else {
            updatedTroop = {
                ...this.props.troopsData[this.troopId],
                [event.currentTarget.name]: event.currentTarget.value
            };
        }
        this.props.updateCurrentTroop(updatedTroop);
    }

    render() {
        if(this.props.troopsData[this.troopId]) {
            const { troopName, troopRole, familyTroop, troopMeetingsPlace, troopDayOfTheWeek, troopMeetingsTime, troopLeader, troopAssistant} = this.props.troopsData[this.troopId];
            const usersDropDown = Object.keys(this.props.users).filter(key => (this.props.users[key].userRole === ALL_ROLES.TROOP_LEADER.VALUE));
            const troopProfile = [
                {
                    'editable': this.state.editable,
                    'label': 'Ime voda',
                    'labelEnglish': 'troopName',
                    'data': troopName,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Starostna veja',
                    'labelEnglish': 'troopRole',
                    'data': troopRole,
                    'prettyData': reCapitalizeData(troopRole, FAMILIES),
                    'inputType': 'select',
                    'selectOptions' : Object.keys(FAMILIES).map(key => ({value: FAMILIES[key].VALUE, label: FAMILIES[key].LABEL})),
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Posebnost voda',
                    'labelEnglish': 'familyTroop',
                    'data': familyTroop,
                    'prettyData': reCapitalizeData(familyTroop, SPECIAL_TROOPS),
                    'inputType': 'select',
                    'selectOptions' : Object.keys(SPECIAL_TROOPS).map(key => ({value: SPECIAL_TROOPS[key].VALUE, label: SPECIAL_TROOPS[key].LABEL})),
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Lokacija srečanj',
                    'labelEnglish': 'troopMeetingsPlace',
                    'data': troopMeetingsPlace,
                    'prettyData': reCapitalizeData(troopMeetingsPlace, PLACES),
                    'inputType': 'select',
                    'selectOptions' : Object.keys(PLACES).map(key => ({value: PLACES[key].VALUE, label: PLACES[key].LABEL})),
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Dan vodovih srečanj',
                    'labelEnglish': 'troopDayOfTheWeek',
                    'data': troopDayOfTheWeek,
                    'inputType': 'select',
                    'selectOptions' : DAYS_OF_THE_WEEK.map(key => ({value: key, label: key})),
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Termin vodovih srečanj',
                    'labelEnglish': 'troopMeetingsTime',
                    'data': troopMeetingsTime,
                    'inputType': 'time',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Vodnik',
                    'labelEnglish': 'troopLeader',
                    'data': troopLeader,
                    'prettyData': (troopLeader) ? `${this.props.users[troopLeader].firstName} ${this.props.users[troopLeader].lastName}` : "/",
                    'inputType': 'select',
                    'selectOptions' : usersDropDown.map(key => ({value: this.props.users[key].userID, label: this.props.users[key].userName})),
                    'emptyOption' : 'Vodnik',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Pomočnik vodnika',
                    'labelEnglish': 'troopAssistant',
                    'data': troopAssistant,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
            ]
            return (
                <Fragment>
                    <h1 className="yellow-font">Vodova izkaznica</h1>
                    <div className="box-shadow buttons-menu"><ul>
                        {(!this.state.editable) ?
                            <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.editData}>Uredi podatke</button></li> :
                            <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.stopEditData}>Zaključi urejanje</button></li>
                        }
                        {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null : <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.decideDeleteOrArchiveTroop}>Zbriši vod</button></li> }
                    </ul></div>
                    <div className="troop-profile-card">
                        <div className="flex-columns">
                            <div className="cards-presentation troop-card box-shadow box-margin two-third-flex">
                                <h2 className="blue-font">{troopName}</h2>
                                <div className="flex-columns troop-profile">
                                    <div className="troop-data full-column-flex">
                                        {(!this.state.editable) ? null : <p className="notice-edit">Če spreminjate starostno vejo voda, počakajte, da se nova starostna veja prikaže, preden delate kaj drugega. Ta postopek je malo daljši, ker se mora celotni vod prenesti v drugo starostno vejo.</p> }
                                        <ListData data={troopProfile} />
                                    </div>
                                </div>
                            </div>
                            <div className="my-profile-buttons one-third-flex">
                                <Link className="link-new-member link-as-button negative-color" to={`${REGISTER_MEMBER}/${this.troopId}`}>Nov član</Link>
                                <Link className="link-new-meeting link-as-button negative-color" to={`${REGISTER_WEEKLY_MEETING}/${this.troopId}`}>Dodaj sestanek</Link>
                            </div>
                        </div>
                    </div>
                    {((this.state.popUpDecideTroopDelArc && this.props.currentUser.userRole !== ALL_ROLES.GG.VALUE) || (this.state.popUpDecideTroopDelArc && this.props.currentUser.userRole !== ALL_ROLES.MC.VALUE)) ? <DecideDeleteOrArchiveTroop
                            archiveTroop={this.props.archiveTroop}
                            deleteTroop={this.props.deleteTroop}
                            selectedTroop={this.troopId}
                            troopsData={this.props.troopsData}
                            popUpOpen={this.closePopUpSecond} /> :
                        null
                    }
                    <ShowAllMembers troopsData={this.props.troopsData} troopsMembers={this.props.troopsMembers} troopsMeetings={this.props.troopsMeetings} schoolYears={this.props.schoolYears} activeTroop={this.troopId} />
                    <PastMembers troopsData={this.props.troopsData} restoreCurrentMember={this.props.restoreCurrentMember} troopsMembers={this.props.troopsMembers} activeTroop={this.troopId} />
                    <ShowAllMeetings troopsData={this.props.troopsData} troopsMeetings={this.props.troopsMeetings} activeTroop={this.troopId} />
                </Fragment>
            )
        } else {
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    <p className="notice-empty white-font">Ta vod je zbrisan ali ne obstaja!</p>
                    <p className="notice-empty"><Link className="red-font underline-link" to={ROUTES.HOME}> Vrni se domov</Link></p>
                </div>
            )
        }
    }
}

export default TroopsCardsSingleAdmin;