import React from "react";
import { Link } from "react-router-dom";
import {MEMBER_CARD} from "../../../constants/routes";

class MemberActivitySingleTable extends React.Component {
    handleChange = (event) => {
        const updateCurrentMemberProgressData = {
            selectedMember: this.props.details,
            selectedYear: this.props.selectedYear
        }
        let updateCurrentMemberProgressFields;
        if(event.currentTarget.type === "checkbox") {
            updateCurrentMemberProgressFields = {
                ...this.props.troopsMembers[this.props.details.troop]['members'][this.props.details.memberID]['progress'][this.props.selectedYear],
                [event.currentTarget.name]: event.currentTarget.checked
            };
        } else {
            updateCurrentMemberProgressFields = {
                ...this.props.troopsMembers[this.props.details.troop]['members'][this.props.details.memberID]['progress'][this.props.selectedYear],
                [event.currentTarget.name]: event.currentTarget.value
            };
        }
        this.props.updateCurrentMemberProgress(updateCurrentMemberProgressData, updateCurrentMemberProgressFields);
    }

    render() {
        return (
            <tr>
                <td data-label="Št." className="order">{this.props.order}</td>
                <td data-label="Član" className="member">{this.props.details.name} {this.props.details.lastName}</td>
                <td data-label="Jesenovanje" className="member-activity-fall"><input type="checkbox" name="memberActivityFall" className="negative-color" onChange={this.handleChange} checked={this.props.details['progress'][this.props.selectedYear]['memberActivityFall']} /></td>
                <td data-label="Zimovanje" className="member-activity-winter"><input type="checkbox" name="memberActivityWinter" className="negative-color" onChange={this.handleChange} checked={this.props.details['progress'][this.props.selectedYear]['memberActivityWinter']} /></td>
                <td data-label="Tabor" className="member-activity-summer"><input type="checkbox" name="memberActivitySummer" className="negative-color" onChange={this.handleChange} checked={this.props.details['progress'][this.props.selectedYear]['memberActivitySummer']} /></td>
                {(this.props.otherActivityEdit) ?
                    <td id="wrapper-table-edit-data" data-label="Druge akcije" className="member-activity-other"><input name="memberActivityOther" className="edit-data" type="text" onBlur={this.handleChange} defaultValue={this.props.details['progress'][this.props.selectedYear]['memberActivityOther']} /></td> :
                    <td data-label="Druge akcije" className="member-activity-other">{this.props.details['progress'][this.props.selectedYear]['memberActivityOther']}</td>
                }
                <td data-label="Več" className="more-info"><Link to={`${MEMBER_CARD}/${this.props.details.troop}/${this.props.details.memberID}`}>Info</Link></td>
            </tr>
        );
    }
}

export default MemberActivitySingleTable;