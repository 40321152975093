import React from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryStack, VictoryTooltip } from 'victory';

const MembershipOverYears = (props) => (
    <div className="membership-over-years-chart">
        {(Object.keys(props.troopsNumbers).length > 0) ?
            <VictoryChart
                theme={VictoryTheme.material}
                height={(props.windowWidth > 767) ? 200 : 400}
                domainPadding={25}
                padding={(props.windowWidth > 767) ? { top: 0, right: 10, bottom: 35, left: 25 } : { top: 10, right: 10, bottom: 40, left: 34 }}
            >
                <VictoryAxis
                    tickValues={Object.keys(props.troopsNumbers).map(key => key)}
                    tickFormat={Object.keys(props.troopsNumbers).map(key => `${key}(${props.troopsNumbers[key].all})`)}
                    fixLabelOverlap={(props.windowWidth <= 767)}
                    label="Šolsko leto"
                    style={{
                        axis: {stroke: "#006edd"},
                        axisLabel: (props.windowWidth > 767) ? {fontFamily: 'Roboto', fontSize: 6, padding: 24} : {fontFamily: 'Roboto', fontSize: 10, padding: 28},
                        ticks: {stroke: "#006edd", size: 3},
                        tickLabels: (props.windowWidth > 767) ? {fontFamily: 'Roboto', fontSize: 6, padding: 4, angle: -20} : {fontFamily: 'Roboto', fontSize: 10, padding: 6, angle: -10},
                    }}
                />
                <VictoryAxis
                    dependentAxis
                    tickFormat={(x) => x}
                    fixLabelOverlap={(props.windowWidth <= 767)}
                    label="Št. članov"
                    style={{
                        axis: {stroke: "#006edd"},
                        axisLabel: (props.windowWidth > 767) ? {fontFamily: 'Roboto', fontSize: 6, padding: 16} : {fontFamily: 'Roboto', fontSize: 10, padding: 24},
                        ticks: {stroke: "#006edd", size: 3},
                        tickLabels: (props.windowWidth > 767) ? {fontFamily: 'Roboto', fontSize: 6, padding: 4} : {fontFamily: 'Roboto', fontSize: 10, padding: 4}
                    }}
                />
                <VictoryStack
                    colorScale={["#DA251C", "#025a4a", "#3164c0", "#e15e32", "#793f96"]}
                    animate={{
                        duration: 2000,
                        onLoad: { duration: 1000 }
                    }}
                    labelComponent={ <VictoryTooltip
                        dy={1}
                        cornerRadius={4}
                        pointerLength={3}
                        pointerWidth={5}
                        flyoutPadding={3}
                        flyoutStyle={{
                            stroke: "#002244",
                            strokeWidth: 0.75,
                            fill:"#ffffff"
                        }}
                        style={(props.windowWidth > 767) ? [
                            { fill: "#1F1916", fontSize: 5, fontFamily: 'Roboto' },
                        ] : [
                            { fill: "#1F1916", fontSize: 10, fontFamily: 'Roboto' },
                        ]}
                    />}
                >
                    <VictoryBar
                        data={Object.keys(props.troopsNumbers).map(key => ({year: key, members: props.troopsNumbers[key].mc}))}
                        labels={Object.keys(props.troopsNumbers).map(key => `${props.troopsNumbers[key].mc} MČ članov`)}
                        x="year"
                        y="members"
                        //barRatio={1}
                    />
                    <VictoryBar
                        data={Object.keys(props.troopsNumbers).map(key => ({year: key, members: props.troopsNumbers[key].gg}))}
                        labels={Object.keys(props.troopsNumbers).map(key => `${props.troopsNumbers[key].gg} GG članov`)}
                        x="year"
                        y="members"
                        //barRatio={1}
                    />
                    <VictoryBar
                        data={Object.keys(props.troopsNumbers).map(key => ({year: key, members: props.troopsNumbers[key].pp}))}
                        labels={Object.keys(props.troopsNumbers).map(key => `${props.troopsNumbers[key].pp} PP članov`)}
                        x="year"
                        y="members"
                        //barRatio={1}
                    />
                    <VictoryBar
                        data={Object.keys(props.troopsNumbers).map(key => ({year: key, members: props.troopsNumbers[key].rr}))}
                        labels={Object.keys(props.troopsNumbers).map(key => `${props.troopsNumbers[key].rr} RR članov`)}
                        x="year"
                        y="members"
                        //barRatio={1}
                    />
                    <VictoryBar
                        data={Object.keys(props.troopsNumbers).map(key => ({year: key, members: props.troopsNumbers[key].g}))}
                        labels={Object.keys(props.troopsNumbers).map(key => `${props.troopsNumbers[key].g} Grča članov`)}
                        x="year"
                        y="members"
                        //barRatio={1}
                    />
                </VictoryStack>
            </VictoryChart> :
            <p className="notice-empty">Graf še ni bil nikoli sinhroniziran!</p>
        }
    </div>
);

export default MembershipOverYears;