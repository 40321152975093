import React, {Fragment} from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { NavLink } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import ALL_ROLES from "../../constants/roles";

class MainMenuAdmin extends React.Component {
    state = {
        mobileMenuOpen: false,
        dataMembersSubMenuOpen: false,
        dataTroopsSubMenuOpen: false
    }

    dataMembersOpenSubMenu = () => {
        this.setState({dataMembersSubMenuOpen: true})
    }
    dataMembersCloseSubMenu = () => {
        this.setState({dataMembersSubMenuOpen: false})
    }

    dataTroopsOpenSubMenu = () => {
        this.setState({dataTroopsSubMenuOpen: true})
    }
    dataTroopsCloseSubMenu = () => {
        this.setState({dataTroopsSubMenuOpen: false})
    }

    openMobileMenu = () => {
        this.setState({mobileMenuOpen: true})
    }
    closeMobileMenu = () => {
        this.setState({mobileMenuOpen: false})
    }

    menuLinksRender = () => {
        return (
            <Fragment>
                <div className="menu-links">
                    <nav>
                        <NavLink className="nav-link link-dashboard" to={ROUTES.HOME} onClick={this.closeMobileMenu}>Nadzorna plošča</NavLink>
                        <NavLink className="nav-link link-my-profile" to={ROUTES.MY_PROFILE} onClick={this.closeMobileMenu}>Moj profil</NavLink>
                        <div className={`nav-link has-sub-menu ${(this.state.activeParent || this.props.subMenuActive) ? "active" : ""}`} onMouseEnter={this.dataMembersOpenSubMenu} onMouseLeave={this.dataMembersCloseSubMenu}>
                            <button>Podatki članov</button>
                            <TransitionGroup component={null}>
                            {(!this.state.dataMembersSubMenuOpen) ? null :
                                <CSSTransition classNames="fade" timeout={400}>
                                    <nav className="sub-menu floating-sub-menu fade--overlay">
                                        <NavLink className="nav-link link-all-meetings" to={ROUTES.ALL_MEETINGS} onClick={this.closeMobileMenu}>Sestanki</NavLink>
                                        <NavLink className="nav-link link-all-members" to={ROUTES.ALL_MEMBERS} onClick={this.closeMobileMenu}>Vsi člani</NavLink>
                                        <NavLink className="nav-link link-all-members-membership" to={ROUTES.MEMBERS_MEMBERSHIP} onClick={this.closeMobileMenu}>Članarine</NavLink>
                                        <NavLink className="nav-link link-all-members-activities" to={ROUTES.MEMBERS_ACTIVITIES} onClick={this.closeMobileMenu}>Akcije</NavLink>
                                        <NavLink className="nav-link link-all-members-activities" to={ROUTES.MEMBERS_SKILLS} onClick={this.closeMobileMenu}>Veščine</NavLink>
                                    </nav>
                                </CSSTransition>
                            }
                            </TransitionGroup>
                        </div>
                        {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ?
                            <NavLink className="nav-link link-troops" to={ROUTES.TROOPS} onClick={this.closeMobileMenu}>Vodi</NavLink> :
                            <div className={`nav-link has-sub-menu ${(this.state.activeParent || this.props.subMenuActive) ? "active" : ""}`} onMouseEnter={this.dataTroopsOpenSubMenu} onMouseLeave={this.dataTroopsCloseSubMenu}>
                                <button>Vodi</button>
                                <TransitionGroup component={null}>
                                {(!this.state.dataTroopsSubMenuOpen) ? null :
                                    <CSSTransition classNames="fade" timeout={400}>
                                        <nav className="sub-menu floating-sub-menu fade--overlay">
                                            <NavLink className="nav-link link-troops" to={ROUTES.TROOPS} onClick={this.closeMobileMenu}>Vodi</NavLink>
                                            <NavLink className="nav-link link-new-troop" to={ROUTES.REGISTER_TROOP} onClick={this.closeMobileMenu}>Nov vod</NavLink>
                                            <NavLink className="nav-link link-past-troops" to={ROUTES.PAST_TROOPS} onClick={this.closeMobileMenu}>Arhivirani vodi</NavLink>
                                        </nav>
                                    </CSSTransition>
                                }
                                </TransitionGroup>
                            </div> }
                        {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null :
                            <Fragment>
                            <NavLink className="nav-link link-leaders" to={ROUTES.LEADERS} onClick={this.closeMobileMenu}>Vodstvo</NavLink>
                            <NavLink className="nav-link link-new-troop-leader" to={ROUTES.REGISTER_TROOP_LEADER} onClick={this.closeMobileMenu}>Nov vodnik</NavLink>
                            </Fragment>
                        }
                    </nav>
                </div>
                <div className="sidebar-footer">
                    <button name="logout" className="logout-button" onClick={() => this.props.logout()}>Odjava</button>
                </div>
            </Fragment>
        )
    }

    render() {
        return (
            <aside className="main-menu">
                <div className="logo"><img src="/images/kr-znak-big.png" alt="kokrski-rod" /></div>
                {(this.props.windowWidth < 993) ? null :
                    <div className="title_area">
                        <h2 className="yellow-font">Pozdravljen/a {this.props.userName}</h2>
                    </div>
                }
                {(this.props.windowWidth < 993) ?
                    <div className="mobile-menu">
                        <div className="hamburger" onClick={this.openMobileMenu}>
                            <span className="hamburger-line" />
                        </div>
                        {(!this.state.mobileMenuOpen) ? null :
                            <div className="mobile-menu-links">
                                <div className="hamburger hamburger-close" onClick={this.closeMobileMenu}>
                                    <span className="hamburger-cross-line" />
                                </div>
                                <this.menuLinksRender />
                            </div>
                        }
                    </div> :
                    <this.menuLinksRender />
                }
            </aside>
        )
    }
}

export default MainMenuAdmin;
