import React from "react";
import { Link } from "react-router-dom";
import {HOME} from "./../constants/routes";

const NotFound = () => (
    <div className="fourofour-error-wrapper">
        <h1 className="yellow-font">404 Napaka</h1>
        <div className="fourofour-image"><img src="/images/404-image.png" alt="404" /></div>
        <p className="fourofour-back-home-link"><Link className="white-font" to={HOME}>Vrni se domov</Link></p>
    </div>
);

export default NotFound;
