import React from 'react';
import {Link} from "react-router-dom";

import {SKILL_LEVELS, SKILL_MISC} from "../../../constants/skills";
import {MEMBER_CARD} from "../../../constants/routes";

class MemberSkillsSingleMember extends React.Component {
    state = {
        editable: false,
    };

    multiMemberClicked = (memberID, memberDist, event) => {
        const memberData = {
            memberID: memberID,
            memberDist: memberDist
        }
        this.props.multiMemberDuplicates(memberData);
    }

    multiMemberClosed = (event) => {
        const memberData = {
            memberID: null,
            memberDist: null
        }
        this.props.multiMemberDuplicates(memberData);
    }

    handleChange = (event) => {
        const updateCurrentMemberProgressData = {
            selectedMember: this.props.details,
            selectedYear: this.props.selectedYear
        }
        let updateCurrentMemberProgressFields;
        updateCurrentMemberProgressFields = {
            ...this.props.troopsMembers[this.props.details.troop]['members'][this.props.details.memberID]['progress'][this.props.selectedYear],
            [event.currentTarget.name]: event.currentTarget.value
        };
        this.props.updateCurrentMemberProgress(updateCurrentMemberProgressData, updateCurrentMemberProgressFields, this.props.troopRole);
    }

    handleChangeMultiple = (key, event) => {
        const updateCurrentMemberProgressData = {
            selectedMember: this.props.details,
            selectedYear: this.props.selectedYear
        }
        let updateCurrentMemberProgressFields;
        updateCurrentMemberProgressFields = {
            ...this.props.troopsMembers[this.props.details.troop]['members'][this.props.details.memberID]['progress'][this.props.selectedYear],
        };
        updateCurrentMemberProgressFields[event.currentTarget.name][key] = event.currentTarget.value;
        this.props.updateCurrentMemberProgress(updateCurrentMemberProgressData, updateCurrentMemberProgressFields, this.props.troopRole);
    }

    addEmptySkill = () => {
        const updateCurrentMemberProgressData = {
            selectedMember: this.props.details,
            selectedYear: this.props.selectedYear
        }
        let updateCurrentMemberProgressFields;
        updateCurrentMemberProgressFields = {
            ...this.props.troopsMembers[this.props.details.troop]['members'][this.props.details.memberID]['progress'][this.props.selectedYear],
        };
        updateCurrentMemberProgressFields.memberSkillsMisc.push("Prazno");
        this.props.updateCurrentMemberProgress(updateCurrentMemberProgressData, updateCurrentMemberProgressFields, this.props.troopRole);
    }

    removeSkill = (key, event) => {
        const updateCurrentMemberProgressData = {
            selectedMember: this.props.details,
            selectedYear: this.props.selectedYear
        }
        let updateCurrentMemberProgressFields;
        updateCurrentMemberProgressFields = {
            ...this.props.troopsMembers[this.props.details.troop]['members'][this.props.details.memberID]['progress'][this.props.selectedYear],
        };
        updateCurrentMemberProgressFields[event.currentTarget.name][key] = null;
        this.props.updateCurrentMemberProgress(updateCurrentMemberProgressData, updateCurrentMemberProgressFields, this.props.troopRole);
    }

    editData = () => {
        this.setState({editable: true});
    };
    stopEditData = () => {
        this.setState({editable: false});
    };

    alphabeticallySkillMisc = () => {
        const orderedSkills = SKILL_MISC.sort((a, b) => a.localeCompare(b, "sl"));
        return (
            orderedSkills.map(key =>
                <option key={key} value={key}>{key}</option>
            )
        )
    }

    contentSkills = () => {
        return (
            <div className="data-inner-wrapper">
                <ul className="no-bullets">
                    {(this.props.noTroopWrapper) && <li className="flex-columns">
                        <span className="labels one-third-flex">Vod</span>
                        <span className="data two-third-flex">{this.props.troopsData[this.props.details.troop].troopName}</span>
                    </li> }
                    <li className="flex-columns">
                        <span className="labels one-third-flex">Znanje</span>
                        {((!this.props.inElement && !this.state.editable) || (this.props.inElement && !this.props.parentEditable)) ?
                            <span className="data two-third-flex">{this.props.details['progress'][this.props.selectedYear].memberSkillsLevel}</span> :
                            <span id="wrapper-edit-data" className="data two-third-flex"><select name="memberSkillsLevel" className="edit-data" onChange={this.handleChange} value={this.props.details['progress'][this.props.selectedYear].memberSkillsLevel}>
                                    {SKILL_LEVELS.map(key => <option key={key} value={key}>{key}</option>)}
                                </select></span>
                        }
                    </li>
                    {Object.keys(this.props.details['progress'][this.props.selectedYear].memberSkillsMisc).map((key, index) =>
                        <li key={key} className="flex-columns">
                            <span className="labels one-third-flex">{`Veščina ${index+1}`}</span>
                            {((!this.props.inElement && !this.state.editable) || (this.props.inElement && !this.props.parentEditable)) ?
                                <span key={key} className="data two-third-flex">{this.props.details['progress'][this.props.selectedYear].memberSkillsMisc[key]}</span> :
                                <span key={key} id="wrapper-edit-data-with-button" className="data two-third-flex flex-columns">
                                    <select name="memberSkillsMisc" className="edit-data" onChange={this.handleChangeMultiple.bind(this, key)} value={this.props.details['progress'][this.props.selectedYear].memberSkillsMisc[key]}>
                                        <option value="Prazno">Prazno</option>
                                        {this.alphabeticallySkillMisc()}
                                    </select>
                                    {(index > 0) ?
                                        <button type="button" name="memberSkillsMisc" className="button-as-link button-add-remove-more" onClick={this.removeSkill.bind(this, key)}>-</button> :
                                        null
                                    }
                                    <button type="button" name="memberSkillsMisc" className="button-as-link button-add-remove-more" onClick={this.addEmptySkill}>+</button>
                                </span>
                            }
                        </li>
                    )}
                    {(!this.props.inElement) ?
                        <li className="flex-columns">
                            <span className="labels one-third-flex">Več</span>
                            <span className="data two-third-flex link-line"><Link to={`${MEMBER_CARD}/${this.props.details.troop}/${this.props.details.memberID}`}>Info</Link></span>
                        </li> :
                        null
                    }
                </ul>
            </div>
        )
    }

    render() {
        if(this.props.inElement) {
            return (
                <this.contentSkills />
            )
        } else if(this.props.duplicates) {
            return (
                <div id="single-member-skills" className={`box-shadow cards-presentation one-half-flex-with-space has-floating-buttons ${(this.props.extraClass !== undefined) ? this.props.extraClass : ''} ${(this.props.noTroopWrapper) && "single-column-margin-bot"}`}>
                    <h5 className="blue-font">
                        {this.props.details.name} {this.props.details.lastName} {}
                        {((this.props.duplicatedMembers.includes(this.props.trueOrder)) && (this.props.clickedMultiMember === null || this.props.clickedMultiMember !== this.props.memberID)) ?
                            <button id={this.props.order} type="button" className="button-as-link red-font in-table-link no-underline" onClick={
                                this.multiMemberClicked.bind(this,
                                    this.props.memberID,
                                    this.props.details.name+this.props.details.lastName+this.props.details.birth.replace(/\s+/g, ''))}>(Multi)
                            </button> :
                            (this.props.duplicatedMembers.includes(this.props.trueOrder) && this.props.clickedMultiMember === this.props.memberID) &&
                            <button type="button" className="button-as-link red-font in-table-link no-underline" onClick={this.multiMemberClosed}>(Zapri)</button>
                        }
                    </h5>
                    <this.contentSkills />
                    {(!this.state.editable) ?
                        <p className="floating-button"><button type="button" className="button-as-link" onClick={this.editData}>Uredi podatke</button></p> :
                        <p className="floating-button"><button type="button" className="button-as-link" onClick={this.stopEditData}>Zaključi urejanje</button></p>
                    }
                </div>
            )
        } else {
            return (
                <div id="single-member-skills" className={`box-shadow cards-presentation one-half-flex-with-space has-floating-buttons ${(this.props.noTroopWrapper) && "single-column-margin-bot"}`}>
                    <h5 className="blue-font">{this.props.details.name} {this.props.details.lastName}</h5>
                    <this.contentSkills />
                    {(!this.state.editable) ?
                        <p className="floating-button"><button type="button" className="button-as-link" onClick={this.editData}>Uredi podatke</button></p> :
                        <p className="floating-button"><button type="button" className="button-as-link" onClick={this.stopEditData}>Zaključi urejanje</button></p>
                    }
                </div>
            )
        }
    }
}

export default MemberSkillsSingleMember;