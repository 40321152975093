import React, {Fragment} from 'react';
import firebase from "firebase/app";
import 'firebase/auth';

class ChangePassword extends React.Component {
    state = {
        errorEmail: false,
        errorPass: false,
        success: false
    }

    // Form refs
    emailRef = React.createRef();
    oldPasswordRef = React.createRef();

    newPasswordRef = React.createRef();

    changePassForm = (event) => {
        event.preventDefault();
        const thisClass = this;

        const currentUser = firebase.auth().currentUser;
        const currentUserEmail = currentUser.email;
        const credential = firebase.auth.EmailAuthProvider.credential(
            this.emailRef.current.value,
            this.oldPasswordRef.current.value
        );

        if(currentUserEmail === this.emailRef.current.value) {
            currentUser.reauthenticateWithCredential(credential).then(function () {
                thisClass.setState({errorPass: false})
                currentUser.updatePassword(thisClass.newPasswordRef.current.value).then(function () {
                    thisClass.setState({success: true})
                }).catch(function (error) {
                    console.log(error);
                })
            }).catch(function (error) {
                thisClass.setState({errorPass: true})
                console.log(error);
            })
            this.setState({errorEmail: false})
        } else {
            this.setState({errorEmail: true})
        }
    }

    closePopUpClicked = () => {
        this.props.popUpOpen(false);
        this.setState({success: false})
    }

    render() {
        return (
            <div className="wrapper-popup">
                <div className="inner-popup box-shadow">
                    <div id="popup-form" className="content-popup wrapper_forms">
                        <form id="change-password" className="form-settings" onSubmit={this.changePassForm}>
                            <h2>Menjava gesla</h2>
                            {(this.state.success) ?
                                <p className="form-success-message">Uspešno si zamenjal/a svoje geslo, ko se boš ponovno vpisoval/s v bazo uporabi novo geslo.</p> :
                                <Fragment>
                                    <p className="form-description">Za menjavo gesla morate ponovno vpisati svoj e-mail naslov ter  staro geslo.</p>
                                    <div className="body-form">
                                        <div className="form-section old-data">
                                            <h4>Trenutni podatki</h4>
                                            <p className="single-input old-pass-email-change">
                                                <input name="old-pass-email-change" className="popup-input" ref={this.emailRef} type="email" required placeholder="E-mail naslov" />
                                                {(this.state.errorEmail) ? <label className="notice-error">Vnešeni e-mail se ne ujema z vašim e-mailom!</label> : null}
                                            </p>
                                            <p className="single-input old-pass-change">
                                                <input name="old-pass-change" ref={this.oldPasswordRef} type="password" required placeholder="Staro geslo" />
                                                {(this.state.errorPass) ? <label className="notice-error">Vnešeno geslo je nepravilno!</label> : null}
                                            </p>
                                        </div>
                                        <div className="form-section new-data">
                                            <h4>Novi podatki</h4>
                                            <p className="single-input new-pass-change">
                                                <input name="new-pass-change" ref={this.newPasswordRef} type="password" pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" required placeholder="Novo geslo" />
                                                <label>Geslo mora vsebovati vsaj 8 znakov, mora imeti vsaj eno malo in eno veliko črko, mora imeti vsaj eno številko in vsaj en posebni znak!</label>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="footer-form">
                                        <button type="submit">Zamenjaj geslo</button>
                                    </div>
                                </Fragment>
                            }
                        </form>
                    </div>
                    <p className="floating-button"><button type="button" className="button-as-link" onClick={this.closePopUpClicked}>Zapri</button></p>
                </div>
            </div>
        )
    }
}

export default ChangePassword;