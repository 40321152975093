import React from 'react';

class DecideDeleteOrArchiveTroop extends React.Component {

    closePopUpClicked = () => {
        this.props.popUpOpen(false);
    }

    archiveSelectedTroop = () => {
        this.props.archiveTroop(this.props.troopsData[this.props.selectedTroop]);
        this.props.popUpOpen(false);
    }

    deleteSelectedTroop = () => {
        this.props.deleteTroop(this.props.troopsData[this.props.selectedTroop]);
        this.props.popUpOpen(false);
    }

    render() {
        return (
            <div className="wrapper-popup popup-second">
                <div className="inner-popup are-you-sure-popup are-you-sure-bigger-buttons box-shadow">
                    <div className="content-popup">
                        <h4>Ali res želiš zbrisati vod?</h4>
                        <div className="buttons-wrapper">
                            <button type="button" className="yes" onClick={this.archiveSelectedTroop}>Arhiviraj vod</button>
                            <button type="button" className="no" onClick={this.deleteSelectedTroop}>Zbriši vod</button>
                        </div>
                    </div>
                    <p className="floating-button"><button type="button" className="button-as-link" onClick={this.closePopUpClicked}>Zapri</button></p>
                </div>
            </div>
        )
    }
}

export default DecideDeleteOrArchiveTroop;