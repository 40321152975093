import React, {Fragment} from 'react';
import firebase from "firebase/app";
import 'firebase/auth';

class ForgottenPassword extends React.Component {
    // Form refs
    emailRef = React.createRef();

    state = {
        errorEmail: false,
        success: false
    }

    resetPassForm = (event) => {
        event.preventDefault();
        const thisClass = this;

        firebase.auth().sendPasswordResetEmail(thisClass.emailRef.current.value).then(function() {
            thisClass.setState({errorEmail: false, success: true})
        }).catch(function() {
            thisClass.setState({errorEmail: true})
        });
    }

    closePopUpClicked = () => {
        this.props.closePopUpResetPassword();
        this.setState({success: false})
    }

    render() {
        return (
            <div className="wrapper-popup">
                <div className="inner-popup box-shadow">
                    <div id="popup-form" className="content-popup wrapper_forms">
                        <form id="reset-password" className="form-settings" onSubmit={this.resetPassForm}>
                            <h2>Pozabljeno geslo</h2>
                            {(this.state.success) ?
                                <p className="form-success-message">Na vaš e-mail naslov smo poslali povezavo, kjer resetirate geslo.</p> :
                                <Fragment>
                                    <p className="form-description">Vpišite svoj e-mail naslov, kamor bomo poslali povezavo kjer resetirate geslo.</p>
                                    <div className="body-form">
                                        <div className="form-section reset-pass-section">
                                            <p className="single-input email-of-user">
                                                <input name="email-reset-pass" className="popup-input" ref={this.emailRef} type="email" required placeholder="E-mail naslov" />
                                                {(this.state.errorEmail) ? <label className="notice-error">Vnešeni e-mail naslov ne obstaja.</label> : null}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="footer-form">
                                        <button type="submit">Resetiraj geslo</button>
                                    </div>
                                </Fragment>
                            }
                        </form>
                    </div>
                    <p className="floating-button"><button type="button" className="button-as-link" onClick={this.closePopUpClicked}>Zapri</button></p>
                </div>
            </div>
        )
    }
}

export default ForgottenPassword;