const PLACES = {
    OS_FP_KRANJ: {
        VALUE: "os-fp-kranj",
        LABEL: "OŠ Franceta Prešerna Kranj"
    },
    OS_FP_KOKRICA: {
        VALUE: "os-fp-kokrica",
        LABEL: "OŠ Franceta Prešerna Kokrica"
    },
    OS_OREHEK: {
        VALUE: "os-orehek",
        LABEL: "OŠ Orehek"
    },
    OS_NAKLO: {
        VALUE: "os-naklo",
        LABEL: "OŠ Naklo"
    },
    OS_PODLJUBELJ: {
        VALUE: "os-podljubelj",
        LABEL: "OŠ Podljubelj"
    }
}

export default PLACES;