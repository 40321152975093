import React from 'react';

class PercentageBar extends React.Component {
    render() {
        const startDate = Date.parse(this.props.schoolYears.currentStartSchoolDate);
        const EndDate = Date.parse(this.props.schoolYears.currentEndSchoolDate);
        if(this.props.meetings) {
            const meetingsByYear = Object.keys(this.props.meetings).filter(f =>
                Date.parse(new Date(this.props.meetings[f].date)) >= startDate && Date.parse(new Date(this.props.meetings[f].date)) <= EndDate
            )
            const filterPercentageAttendance = meetingsByYear.filter(f =>
                !(this.props.memberID in this.props.meetings[f].presence) ||
                this.props.meetings[f].presence[this.props.memberID] === "Ni bil/a prisoten/na"
            )
            const reverseNumber = meetingsByYear.length - filterPercentageAttendance.length;
            const finalNumber = (reverseNumber/meetingsByYear.length)*100;

            return (
                <p className="wrapper-percentage-bar flex-columns items-center items-center-inline">
                <span className="percentage-bar">
                    <span className="blue-bar" style={{width: `${finalNumber}%`}} />
                </span>
                    <span className="meetings-in-number">{`${reverseNumber}/${meetingsByYear.length}`}</span>
                </p>
            )
        } else {
            return (
                <p className="wrapper-percentage-bar flex-columns items-center items-center-inline">
                    <span>/</span>
                </p>
            )
        }
    }
}

export default PercentageBar;