import React from 'react';

import {Link} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import ALL_ROLES from "../../constants/roles";

import ListData from "../templates/ListData";
import ChangeEmailForUser from "./ChangeEmailForUser";
import ChangePasswordForUser from "./ChangePasswordForUser";
import ChangeRollForUser from "./ChangeRollForUser";
import DeleteUser from "./DeleteUser";

class UserCard extends React.Component {
    state = {
        editable: false,
        popUpEmailActive: false,
        popUpPasswordActive: false,
        popUpRollActive: false,
        popupDeleteUserActive: false
    }

    userId = this.props.match.params.userID;

    editData = () => {
        this.setState({editable: true});
    };
    stopEditData = () => {
        this.setState({editable: false});
    };

    openPopUpEmailChange = () => {
        this.setState({popUpEmailActive: true})
    }
    openPopUpPasswordChange = () => {
        this.setState({popUpPasswordActive: true})
    }
    openPopUpRollChange = () => {
        this.setState({popUpRollActive: true})
    }
    openPopUpDeleteUser = () => {
        this.setState({popupDeleteUserActive: true})
    }

    closePopUp = (click) => {
        this.setState({
            popUpEmailActive: click,
            popUpPasswordActive: click,
            popUpRollActive: click,
            popupDeleteUserActive: click
        })
    }

    handleChange = (event) => {
        const updatedUser = {
            ...this.props.users[this.userId],
            [event.currentTarget.name]: event.currentTarget.value
        };
        this.props.updateUser(updatedUser);
    }

    render() {
        if(this.props.users[this.userId]) {
            const userAllData = this.props.users[this.userId];
            const { email, firstName, lastName, telNumber, userName, userRole} = userAllData;
            const troops = Object.keys(this.props.troopsData).filter(f => this.props.troopsData[f].troopLeader === this.userId);
            const userProfile = [
                {
                    'editable': this.state.editable,
                    'label': 'Uporabniško ime',
                    'labelEnglish': 'userName',
                    'data': userName,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'E-mail',
                    'labelEnglish': 'email',
                    'data': email,
                    'inputType': 'notEdit',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Tel. številka',
                    'labelEnglish': 'telNumber',
                    'data': telNumber,
                    'inputType': 'tel',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Ime',
                    'labelEnglish': 'firstName',
                    'data': firstName,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Priimek',
                    'labelEnglish': 'lastName',
                    'data': lastName,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Vloga',
                    'labelEnglish': 'userRole',
                    'data': ALL_ROLES[Object.keys(ALL_ROLES).filter(f => ALL_ROLES[f].VALUE === userRole)].LABEL,
                    'inputType': 'notEdit',
                    'handleChange': this.handleChange,
                }
            ]
            if(troops.length > 0) {
                userProfile.push(
                    {
                        'editable': this.state.editable,
                        'label': 'Vod/i',
                        'labelEnglish': 'troops',
                        'data': troops.map((key, index) => (index > 0 ) ? `, ${this.props.troopsData[key].troopName}` : this.props.troopsData[key].troopName),
                        'inputType': 'notEdit',
                        'handleChange': this.handleChange,
                    }
                );
            }
            return (
                <div className="cards-presentation user-card">
                    <h1 className="yellow-font">Uporabniški profil</h1>
                    <div className="box-shadow buttons-menu"><ul>
                        {(!this.state.editable) ?
                            <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.editData}>Uredi podatke</button></li> :
                            <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.stopEditData}>Zaključi urejanje</button></li>
                        }
                        <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.openPopUpEmailChange}>Zamenjaj e-mail naslov</button></li>
                        <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.openPopUpPasswordChange}>Zamenjaj geslo</button></li>
                        <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.openPopUpRollChange}>Zamenjaj vlogo</button></li>
                        <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.openPopUpDeleteUser}>Zbriši uporabniški račun</button></li>
                    </ul></div>
                    <div className="box-shadow two-third-size">
                        <h2 className="blue-font">{firstName} {lastName}</h2>
                        <div className="flex-columns my-profile">
                            <div className="personal-data full-column-flex">
                                <ListData data={userProfile} />
                            </div>
                            {(!this.state.editable) ? null:
                                <p className="notice-edit">Če želiš spremeniti vodom vodnika, to storiš na profilu posameznega voda.</p>
                            }
                        </div>
                    </div>
                    {(this.state.popUpEmailActive) ? <ChangeEmailForUser userID={this.userId} userAllData={userAllData}  updateUser={this.props.updateUser} popUpOpen={this.closePopUp} /> : null}
                    {(this.state.popUpPasswordActive) ? <ChangePasswordForUser userID={this.userId} popUpOpen={this.closePopUp} /> : null}
                    {(this.state.popUpRollActive) ? <ChangeRollForUser userID={this.userId} userAllData={userAllData}  updateUser={this.props.updateUser} updateCurrentTroop={this.props.updateCurrentTroop} archiveTroop={this.props.archiveTroop} deleteTroop={this.props.deleteTroop} troopsData={this.props.troopsData} users={this.props.users} popUpOpen={this.closePopUp} /> : null}
                    {(this.state.popupDeleteUserActive) ? <DeleteUser userID={this.userId} deleteUser={this.props.deleteUser} updateCurrentTroop={this.props.updateCurrentTroop} archiveTroop={this.props.archiveTroop} deleteTroop={this.props.deleteTroop} troopsData={this.props.troopsData} users={this.props.users} popUpOpen={this.closePopUp} goBack={this.props.history} /> : null}
                </div>
            )
        } else {
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    <p className="notice-empty white-font">Ta uporabniški račun je zbrisan ali ne obstaja!</p>
                    <p className="notice-empty"><Link className="red-font underline-link" to={ROUTES.HOME}> Vrni se domov</Link></p>
                </div>
            )
        }
    }
}

export default UserCard;