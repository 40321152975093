// Main pages
export const LANDING = '/';
export const HOME = '/doma';
export const MY_PROFILE = '/moj-profil';
// Admin pages
export const LEADERS = '/vodstvo';
export const TROOPS = '/vodi';
export const REGISTER_TROOP_LEADER = '/registriraj-vodnika';
// Admin members all data
export const ALL_MEETINGS = '/vsa-tedenska-srecanja';
export const ALL_MEMBERS = '/vsi-clani';
export const MEMBERS_MEMBERSHIP = '/clanarine';
export const MEMBERS_ACTIVITIES = '/akcije';
export const MEMBERS_SKILLS = '/vescine';
// Troops pages
export const PAST_TROOPS = '/arhivirani-vodi';
export const TROOP_CARD = '/podatki-voda';
export const TROOPS_CARDS = '/podatki-vodov';
export const REGISTER_TROOP = '/registriraj-vod';
// User pages
export const USER_CARD = '/uporabniske-izkaznice';
// Member pages
export const MEMBERS = '/clani';
export const PAST_MEMBERS = '/bivsi-clani';
export const MEMBER_CARD = '/clanske-izkaznice';
export const REGISTER_MEMBER = '/registriraj-clana';
// Meeting pages
export const MEETING_CARD = '/tedenska-srecanja';
export const REGISTER_WEEKLY_MEETING = '/vnos-tedenskega-sestanka';
