import React from 'react';
import TroopsCardsSingle from "./TroopsCardsSingle";

class TroopsCards extends React.Component {
    render() {
        return (
            <div className="wrapper-all-troops">
                <h1 className="yellow-font">Podatki vodov</h1>
                <div className="flex-columns flex-multi-lines">
                    {Object.keys(this.props.troopsData).map(key =>
                        <TroopsCardsSingle
                            key={key}
                            troopID={key}
                            updateCurrentTroop={this.props.updateCurrentTroop}
                            troopsData={this.props.troopsData}
                            details={this.props.troopsData[key]}
                        />
                    )};
                </div>
            </div>
        )
    }
}

export default TroopsCards;