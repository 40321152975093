import React from 'react';
import {reTimeInInput, reCapitalizeData} from "../../../helpers";
import PLACES from "../../../constants/meetingsPlaces";
import DAYS_OF_THE_WEEK from "../../../constants/daysOfTheWeek";
import FAMILIES, {SPECIAL_TROOPS} from "../../../constants/familesClubs";

class TroopsCardsSingle extends React.Component {
    state = {
        editable: false,
    };

    editData = () => {
        this.setState({editable: true});
    };
    stopEditData = () => {
        this.setState({editable: false});
    };

    handleChange = (event) => {
        let updatedCurrentTroop;
        if(event.currentTarget.name === "troopMeetingsTime-from") {
            updatedCurrentTroop = {
                ...this.props.troopsData[this.props.troopID],
                "troopMeetingsTime": `${event.currentTarget.value} - ${reTimeInInput(this.props.troopsData[this.props.troopID].troopMeetingsTime, true)}`
            };
        } else if (event.currentTarget.name === "troopMeetingsTime-to") {
            updatedCurrentTroop = {
                ...this.props.troopsData[this.props.troopID],
                "troopMeetingsTime": `${reTimeInInput(this.props.troopsData[this.props.troopID].troopMeetingsTime)} - ${event.currentTarget.value}`
            };
        } else {
            updatedCurrentTroop = {
                ...this.props.troopsData[this.props.troopID],
                [event.currentTarget.name]: event.currentTarget.value
            };
        }
        this.props.updateCurrentTroop(updatedCurrentTroop);
    }

    render() {
        const { troopName, troopRole, familyTroop, troopMeetingsPlace, troopDayOfTheWeek, troopMeetingsTime, troopAssistant} = this.props.details;
        return (
            <div className="cards-presentation troop-card box-shadow box-margin one-half-flex-with-space has-floating-buttons">
                <h2 className="blue-font">{this.props.details.troopName}</h2>
                {(!this.state.editable) ? null:
                    <p className="notice-edit">Če želiš spremeniti starostno vejo ali posebnost voda, prosi svojega načelnika družine ali načelnika rodu, da ti  spremeni podatke.</p>
                }
                <div className="data-inner-wrapper">
                    <ul className="no-bullets">
                        <li className="flex-columns items-center">
                            <span className="labels one-third-flex">Ime voda</span>
                            {(!this.state.editable) ?
                                <span className="data two-third-flex">{troopName}</span> :
                                <span id="wrapper-edit-data" className="data two-third-flex">
                                    <input name="troopName" className="edit-data" type="text" onBlur={this.handleChange} defaultValue={troopName} />
                                </span>
                            }
                        </li>
                        <li className="flex-columns items-center">
                            <span className="labels one-third-flex">Starostna veja</span>
                            <span className="data two-third-flex">{reCapitalizeData(troopRole, FAMILIES)}</span>
                        </li>
                        <li className="flex-columns items-center">
                            <span className="labels one-third-flex">Posebnost voda</span>
                            <span className="data two-third-flex">{reCapitalizeData(familyTroop, SPECIAL_TROOPS)}</span>
                        </li>
                        <li className="flex-columns items-center">
                            <span className="labels one-third-flex">Lokacija srečanj</span>
                            {(!this.state.editable) ?
                                <span className="data two-third-flex">{reCapitalizeData(troopMeetingsPlace, PLACES)}</span> :
                                <span id="wrapper-edit-data" className="data two-third-flex">
                                    <select name="troopMeetingsPlace" className="edit-data" onChange={this.handleChange} value={troopMeetingsPlace}>
                                        {Object.keys(PLACES).map(key => <option key={PLACES[key].VALUE} value={PLACES[key].VALUE}>{PLACES[key].LABEL}</option>)}
                                    </select>
                                </span>
                            }
                        </li>
                        <li className="flex-columns items-center">
                            <span className="labels one-third-flex">Dan vodovih srečanj</span>
                            {(!this.state.editable) ?
                                <span className="data two-third-flex">{troopDayOfTheWeek}</span> :
                                <span id="wrapper-edit-data" className="data two-third-flex">
                                    <select name="troopDayOfTheWeek" className="edit-data" onChange={this.handleChange} value={troopDayOfTheWeek}>
                                        {DAYS_OF_THE_WEEK.map(key => <option key={key} value={key}>{key}</option>)}
                                    </select>
                                </span>
                            }
                        </li>
                        <li className="flex-columns items-center">
                            <span className="labels one-third-flex">Termin vodovih srečanj</span>
                            {(!this.state.editable) ?
                                <span className="data two-third-flex">{troopMeetingsTime}</span> :
                                <span id="wrapper-edit-data" className="data two-third-flex">
                                    <input name="troopMeetingsTime-from" className="edit-data edit-data-time edit-data-time-start" type="time" min="06:00" max="23:59" onBlur={this.handleChange} defaultValue={reTimeInInput(troopMeetingsTime)} />
                                    <input name="troopMeetingsTime-to" className="edit-data edit-data-time edit-data-time-stop" type="time" min="06:00" max="23:59" onBlur={this.handleChange} defaultValue={reTimeInInput(troopMeetingsTime, true)} />
                                </span>
                            }
                        </li>
                        {(!troopAssistant && !this.state.editable) ? null :
                            <li className="flex-columns items-center">
                                <span className="labels one-third-flex">Pomočnik vodnika</span>
                                {(!this.state.editable) ?
                                    <span className="data two-third-flex">{troopAssistant}</span> :
                                    <span id="wrapper-edit-data" className="data two-third-flex">
                                    <input name="troopAssistant" className="edit-data" type="text" onBlur={this.handleChange} defaultValue={troopAssistant} />
                                </span>
                                }
                            </li>
                        }
                    </ul>
                </div>
                {(!this.state.editable) ?
                    <p className="floating-button"><button type="button" className="button-as-link" onClick={this.editData}>Uredi podatke</button></p> :
                    <p className="floating-button"><button type="button" className="button-as-link" onClick={this.stopEditData}>Zaključi urejanje</button></p>
                }
            </div>
        )
    }
}

export default TroopsCardsSingle;