import React from 'react';

class FilterByTroopsAllData extends React.Component {
    changeTroop = (event) => {
        this.props.filterChangeTroop(event.currentTarget.value);
    }

    searchMember = (event) => {
        this.props.filterSearchMember(event.currentTarget.value);
    }

    render() {
        const orderTroopsByName = Object.keys(this.props.troopsData).sort((a, b) => this.props.troopsData[a].troopName.localeCompare(this.props.troopsData[b].troopName, "sl"));
        return (
            <div className="wrapper-filter-by-troop-all-data">
                {(this.props.meetings) ? null : <input className="negative-color box-shadow select-box input-search normal-space-right" type="text" placeholder="Išči..." value={this.props.searchParam} onChange={this.searchMember} /> }
                <select name="all-data-filter-by-troop" className="negative-color box-shadow select-box" onChange={this.changeTroop} defaultValue="all-troops">
                    <option value="all-troops">Vsi vodi</option>
                    {orderTroopsByName.map(key =>
                        <option key={key} value={key}>{this.props.troopsData[key].troopName}</option>
                    )}
                </select>
            </div>
        )
    }
}

export default FilterByTroopsAllData;