import React from 'react';
import GroupAllMeetingsTable from "./GroupAllMeetingsTable";
import FilterByTroopsAllData from "./FilterByTroopsAllData";

import MONTHS from "../../../constants/monthsNames";
import {REGISTER_WEEKLY_MEETING} from "../../../constants/routes";
import {Link} from "react-router-dom";

class GroupAllMeetings extends React.Component {
    state = {
        fullDates: {},
        selectedYear: null,
        selectedMonth: null,
        selectedTroop: 'all-troops',
        dateLoaded: false
    }

    componentDidMount() {
        const checkIfTroopHaveMeeting = Object.keys(this.props.troopsMeetings).filter(f => this.props.troopsMeetings[f].meetings);
        const allDates = [];
        checkIfTroopHaveMeeting.map(key => Object.keys(this.props.troopsMeetings[key].meetings).map(key2 => allDates.push(this.props.troopsMeetings[key].meetings[key2].date)));
        const meetingsOrderByDate = allDates.sort((a, b) => new Date(b) - new Date(a));

        const selectedYear = String(new Date(meetingsOrderByDate[0]).getFullYear());
        const selectedMonth = String(new Date(meetingsOrderByDate[0]).getMonth());

        this.setState({
            fullDates: meetingsOrderByDate,
            selectedYear: selectedYear,
            selectedMonth: selectedMonth,
            dateLoaded: true
        })

    }

    getYears = () => {
        let years = [];
        this.state.fullDates.map(key => years.push(new Date(key).getFullYear()));
        const distinctYears = [...new Set(years)];
        return distinctYears.map(key => <option key={key} value={key}>{key}</option>);
    }

    getMonths = () => {
        let months = [];
        const filterYears = this.state.fullDates.filter(f => String(new Date(f).getFullYear()) === this.state.selectedYear);
        filterYears.map(key => months.push(new Date(key).getMonth()));
        const distinctMonths = [...new Set(months)];
        return distinctMonths.map(key => <option key={key} value={key}>{MONTHS[key]}</option>);
    }

    selectYear = (event) => {
        const filterYears = this.state.fullDates.filter(f => String(new Date(f).getFullYear()) === event.target.value);
        this.setState({
            selectedYear: event.target.value,
            selectedMonth: String(new Date(filterYears[0]).getMonth())
        })
    }

    selectMonth = (event) => {
        this.setState({
            selectedMonth: event.target.value
        })
    }

    filterChangeTroop = (selectedTroop) => {
        this.setState({selectedTroop: selectedTroop})
    }

    render() {
        const checkMeetings = Object.keys(this.props.troopsMeetings).filter(f => this.props.troopsMeetings[f].meetings !== undefined);
        let orderTroopsByName;
        if(this.state.selectedTroop === "all-troops") {
            orderTroopsByName = Object.keys(this.props.troopsData).sort((a, b) => this.props.troopsData[a].troopName.localeCompare(this.props.troopsData[b].troopName, "sl"));
        } else {
            const filterTroops = Object.keys(this.props.troopsMeetings).filter(f => f === this.state.selectedTroop);
            orderTroopsByName = filterTroops.sort((a, b) => this.props.troopsData[a].troopName.localeCompare(this.props.troopsData[b].troopName, "sl"));
        }
        if(checkMeetings.length && this.state.dateLoaded) {
            const tableHeader = ['Št.', 'Vod', 'Sestanek', 'Lokacija', 'Datum', 'Čas', 'Več'];
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-meetings wrapper-group-all-meetings">
                    <h1 className="yellow-font">Tedenski sestanki</h1>
                    <div className="wrapper-inner-all-meetings">
                        <div className="wrapper-meetings-filter-by-date">
                            <select name="meetings-filter-year" className="negative-color box-shadow select-box" value={this.state.selectedYear} onChange={this.selectYear}>
                                <this.getYears />
                            </select>
                            <select name="meetings-filter-month" className="negative-color box-shadow select-box" value={this.state.selectedMonth} onChange={this.selectMonth}>
                                <this.getMonths />
                            </select>
                            <FilterByTroopsAllData troopsData={this.props.troopsData} filterChangeTroop={this.filterChangeTroop} meetings={true} />
                            <Link className="link-new-meeting link-as-button negative-color" to={REGISTER_WEEKLY_MEETING}>Dodaj sestanek</Link>
                        </div>
                        <div className="single-multi-troop single-multi-troop-meetings">
                            <table className="general-table meetings-table">
                                <thead>
                                <tr>
                                    {tableHeader.map((key, index) => <th key={index}>{key}</th>)}
                                </tr>
                                </thead>
                                <tbody>
                                    {orderTroopsByName.map(key =>
                                        ( this.props.troopsMeetings[key].meetings !== undefined) ?
                                            <GroupAllMeetingsTable
                                                key={key}
                                                troopID={key}
                                                troopsData={this.props.troopsData}
                                                troopsMeetings={this.props.troopsMeetings}
                                                fullDates={this.state.fullDates}
                                                selectedYear={this.state.selectedYear}
                                                selectedMonth={this.state.selectedMonth} /> : null
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    <p className="notice-empty white-font">V bazi še ni zabeleženih sestankov.</p>
                </div>
            )
        }
    }
}

export default GroupAllMeetings;