import React from 'react';
import TablesShowMembers from "../TablesShowMembers";

class Membership extends React.Component {
    render() {
        const tableHeader = ['Št', 'Član', 'Vpisni list', 'Plačana članarina', 'Več'];
        const classesToSend = {
            wrapperTitle: "wrapper-membership-table",
            tableTile: "membership-table"
        }
        return (
            <div className="wrapper-multi-troop wrapper-multi-troop-members">
                <h2 className="white-font">Članstvo</h2>
                {Object.keys(this.props.troopsMembers).map(key =>
                    <TablesShowMembers key={key} troopID={key} tableSort="membership" updateCurrentMemberProgress={this.props.updateCurrentMemberProgress} troopsData={this.props.troopsData} troopsMembers={this.props.troopsMembers} selectedYear={this.props.selectedYear} classes={classesToSend} tableHeader={tableHeader} />
                )}
            </div>
        )
    }
}

export default Membership;