import React from "react";
import { Link } from "react-router-dom";
import {MEETING_CARD} from "../../constants/routes";
import { formatDate } from "../../helpers";

const MeetingSingleTable = (props) => (
    <tr>
        <td data-label="Št." className="order">{props.order}</td>
        <td data-label="Sestanek" className="title">{props.details.title}</td>
        <td data-label="Lokacija" className="place">{props.details.place}</td>
        <td data-label="Datum" className="date">{formatDate(props.details.date)}</td>
        <td data-label="Čas" className="time">{props.details.time}</td>
        <td data-label="Več" className="more-info"><Link to={`${MEETING_CARD}/${props.details.troop}/${props.slug}`}>Info</Link></td>
    </tr>
);

export default MeetingSingleTable;
