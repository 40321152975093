import React from "react";
import { Link } from "react-router-dom";
import {MEMBER_CARD} from "../../constants/routes";
import PercentageBar from "../templates/PercentageBar";

const MemberSingleTable = (props) => (
    <tr>
        <td data-label="Št." className="order">{props.order}</td>
        <td data-label="Ime in priimek" className="name-surname">{props.details.name} {props.details.lastName}</td>
        <td data-label="Telefonska številka člana" className="tel-number">{props.details.telMember}</td>
        <td data-label="Telefonska številka starša" className="tel-number">{props.details.telParent}</td>
        <td data-label="Udeležba na sestankih" className="attendance"><PercentageBar memberID={props.details.memberID} meetings={props.meetings} schoolYears={props.schoolYears} /></td>
        <td data-label="Več" className="more-info"><Link to={`${MEMBER_CARD}/${props.details.troop}/${props.slug}`}>Info</Link></td>
    </tr>
);

export default MemberSingleTable;
