import React, {Fragment} from "react";
import { NavLink } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

class MainMenuUser extends React.Component {
    state = {
        mobileMenuOpen: false
    }

    openMobileMenu = () => {
        this.setState({mobileMenuOpen: true})
    }
    closeMobileMenu = () => {
        this.setState({mobileMenuOpen: false})
    }

    menuLinksRender = () => {
        return (
            <Fragment>
                <div className="menu-links">
                    <nav>
                        <NavLink className="nav-link link-dashboard" to={ROUTES.HOME} onClick={this.closeMobileMenu}>Nadzorna plošča</NavLink>
                        <NavLink className="nav-link link-my-profile" to={ROUTES.MY_PROFILE} onClick={this.closeMobileMenu}>Moj profil</NavLink>
                        <NavLink className="nav-link link-troops" to={ROUTES.TROOPS_CARDS} onClick={this.closeMobileMenu}>Vod/i</NavLink>
                        <NavLink className="nav-link link-members" to={ROUTES.MEMBERS} onClick={this.closeMobileMenu}>Člani</NavLink>
                        <NavLink className="nav-link link-past-members" to={ROUTES.PAST_MEMBERS} onClick={this.closeMobileMenu}>Bivši člani</NavLink>
                        <NavLink className="nav-link link-new-member" to={ROUTES.REGISTER_MEMBER} onClick={this.closeMobileMenu}>Nov član</NavLink>
                        <NavLink className="nav-link link-new-weekly-meeting" to={ROUTES.REGISTER_WEEKLY_MEETING} onClick={this.closeMobileMenu}>Vnesi nov sestanek</NavLink>
                    </nav>
                </div>
                <div className="sidebar-footer">
                    <button name="logout" className="logout-button" onClick={() => this.props.logout()}>Odjava</button>
                </div>
            </Fragment>
        )
    }

    render() {
        return (
            <aside className="main-menu">
                <div className="logo"><img src="/images/kr-znak-big.png" alt="kokrski-rod" /></div>
                {(this.props.windowWidth < 993) ? null :
                    <div className="title_area">
                        <h2 className="yellow-font">Pozdravljen/a {this.props.userName}</h2>
                    </div>
                }
                {(this.props.windowWidth < 993) ?
                    <div className="mobile-menu">
                        <div className="hamburger" onClick={this.openMobileMenu}>
                            <span className="hamburger-line" />
                        </div>
                        {(!this.state.mobileMenuOpen) ? null :
                            <div className="mobile-menu-links">
                                <div className="hamburger hamburger-close" onClick={this.closeMobileMenu}>
                                    <span className="hamburger-cross-line" />
                                </div>
                                <this.menuLinksRender />
                            </div>
                        }
                    </div> :
                    <this.menuLinksRender />
                }
            </aside>
        )
    }
}

export default MainMenuUser;
