import React from 'react';
import {Link} from "react-router-dom";
import {MEMBER_CARD} from "../../../../constants/routes";

class GroupMembersMembershipTable extends React.Component {
    multiMemberClicked = (memberID, memberDist, event) => {
        const memberData = {
            memberID: memberID,
            memberDist: memberDist
        }
        this.props.multiMemberDuplicates(memberData);
    }

    multiMemberClosed = (event) => {
        const memberData = {
            memberID: null,
            memberDist: null
        }
        this.props.multiMemberDuplicates(memberData);
    }

    handleChange = (key, event) => {
        const updateCurrentMemberProgressData = {
            selectedMember: this.props.troopsMembers[this.props.troopID].members[key],
            selectedYear: this.props.selectedYear
        }
        let updateCurrentMemberProgressFields;
        if(event.currentTarget.type === "checkbox") {
            updateCurrentMemberProgressFields = {
                ...this.props.troopsMembers[this.props.troopID].members[key]['progress'][this.props.selectedYear],
                [event.currentTarget.name]: event.currentTarget.checked
            };
        } else {
            updateCurrentMemberProgressFields = {
                ...this.props.troopsMembers[this.props.troopID].members[key]['progress'][this.props.selectedYear],
                [event.currentTarget.name]: event.currentTarget.value
            };
        }
        this.props.updateCurrentMemberProgress(updateCurrentMemberProgressData, updateCurrentMemberProgressFields, this.props.troopsMembers[this.props.troopID].troopRole);
    }

    render() {
        return (
            <tr className={this.props.extraClass}>
                <td data-label="Št." className="order">{(this.props.order !== null) ? this.props.order +1 : ''}</td>
                <td data-label="Član" className="member">
                    {this.props.troopsMembers[this.props.troopID].members[this.props.memberID].name} {this.props.troopsMembers[this.props.troopID].members[this.props.memberID].lastName} {}
                    {((this.props.duplicatedMembers.includes(this.props.trueOrder)) && (this.props.clickedMultiMember === null || this.props.clickedMultiMember !== this.props.memberID)) ?
                        <button id={this.props.order} type="button" className="button-as-link red-font in-table-link no-underline" onClick={
                            this.multiMemberClicked.bind(this,
                                this.props.memberID,
                                this.props.troopsMembers[this.props.troopID].members[this.props.memberID].name+this.props.troopsMembers[this.props.troopID].members[this.props.memberID].lastName+this.props.troopsMembers[this.props.troopID].members[this.props.memberID].birth.replace(/\s+/g, ''))}>(Multi)
                        </button> :
                        (this.props.duplicatedMembers.includes(this.props.trueOrder) && this.props.clickedMultiMember === this.props.memberID) &&
                        <button type="button" className="button-as-link red-font in-table-link no-underline" onClick={this.multiMemberClosed}>(Zapri)</button>
                    }
                </td>
                <td data-label="Vpisni list" className="membership-card"><input type="checkbox" name="membershipCard" className="negative-color" onChange={this.handleChange.bind(this, this.props.memberID)} checked={this.props.troopsMembers[this.props.troopID].members[this.props.memberID]['progress'][this.props.selectedYear]['membershipCard']} /></td>
                <td data-label="Plačana članarina" className="membership-payment"><input type="checkbox" name="membershipPayment" className="negative-color" onChange={this.handleChange.bind(this, this.props.memberID)} checked={this.props.troopsMembers[this.props.troopID].members[this.props.memberID]['progress'][this.props.selectedYear]['membershipPayment']} /></td>
                <td data-label="Vod" className="troop">{this.props.troopsData[this.props.troopID].troopName}</td>
                <td data-label="Več" className="more-info"><Link to={`${MEMBER_CARD}/${this.props.troopsMembers[this.props.troopID].members[this.props.memberID].troop}/${this.props.memberID}`}>Info</Link></td>
            </tr>
        )
    }
}

export default GroupMembersMembershipTable;