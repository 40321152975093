const NOTICES = {
    WRONG_USER: "Ta vodnik ne obstaja!",
    WRONG_PASS: "Napačno geslo!",
    NO_ROLE: {
        TITLE: "Nimaš dodeljene nobene vloge",
        TEXT: "Vprašaj načelnika, da ti dodeli pravilno vlogo!"
    },
    NO_ROLE_SELECT: "Nisi izbral vloge",
    NO_TROOP_SELECT: "Nisi izbral voda",
    EMAIL_ALREADY_IN_USE: "Email je že uporabljen za drugi račun.",
    PHONE_ALREADY_IN_USE: "Telefonska številka je že uporabljena za drugi račun.",
    TROOP_NAME_ALREADY_IN_USE: "Vod s tem imenom že obstaja."
};

export default NOTICES;
