import React from 'react';
import ForgottenPassword from "./ForgottenPassword";

class LoginUser extends React.Component {
    // User info refs
    emailRef = React.createRef();
    passwordRef = React.createRef();

    state = {
        resetPasswordPopUp: false
    }

    loginData = (event) => {
        // Prevent default
        event.preventDefault();
        // Load data in variables
        const email = this.emailRef.current.value;
        const password = this.passwordRef.current.value;

        this.props.authenticate(email, password);
    };

    openPopUpResetPassword = () => { this.setState({resetPasswordPopUp: true}) }
    closePopUpResetPassword = () => { this.setState({resetPasswordPopUp: false}) }

    render() {
        return (
            <div className="wrapper-login-user">
                <form className="login-user" onSubmit={this.loginData}>
                    <div className="logo"><img src="/images/kr-znak-big.png" alt="kokrski-rod" /></div>
                    <h2>Prijava v bazo</h2>
                    {(!this.props.loginDataErrors.wrongData) ? null : <p className="notice-error">{this.props.loginDataErrors.notice}</p> }
                    <div className="body-form">
                        <input name="e-mail" ref={this.emailRef} type="email" required placeholder="E-mail" />
                        <input name="password" ref={this.passwordRef} type="password" required placeholder="Geslo" />
                        <p className="reset-password-link"><button type="button" className="button-as-label" onClick={this.openPopUpResetPassword}>Pozabljeno geslo?</button></p>
                    </div>
                    <div className="footer-form">
                        <button type="submit">Prijava</button>
                    </div>
                </form>
                {(this.state.resetPasswordPopUp) ? <ForgottenPassword closePopUpResetPassword={this.closePopUpResetPassword} /> : null}
            </div>
        )
    }
}

export default LoginUser;
