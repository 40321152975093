import React, {Fragment} from 'react';
import loaderImage from "../../images/fidget-spinner-loader.svg";
import {firebaseApp, fireBaseFunctions} from "../../base";
import ALL_ROLES from "../../constants/roles";
import ChangeTroopBeforeEditUser from "./ChangeTroopBeforeEditUser";

class ChangeRollForUser extends React.Component {
    state = {
        popUpDecideTroopDelArc: false,
        selectedTroop: null,
        rollSelected: false,
        userTroopLeader: false,
        waitOnPromise: false,
        succeed: false
    }

    roleRef = React.createRef();

    closePopUpClicked = () => {
        this.props.popUpOpen(false);
        this.setState({succeed: false})
    }

    checkUserTroop = (event) => {
        const selection = event.currentTarget.value;
        if(selection) {
            this.setState({rollSelected: true});
        }
        if(selection !== ALL_ROLES.TROOP_LEADER.VALUE) {
            this.setState({userTroopLeader: false});
        } else {
            this.setState({userTroopLeader: true});
        }
    }

    changeRollForm = (event) => {
        event.preventDefault();

        const currentForm = event.currentTarget;
        const newRole = this.roleRef.current.value;

        const userData = {
            uid: this.props.userID,
            userRole: newRole
        }

        this.setState({waitOnPromise: true});

        firebaseApp.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
            if(idTokenResult.claims.role === ALL_ROLES.ADMIN.VALUE) {
                const changeUserRole = fireBaseFunctions.httpsCallable('setRoleToUser');
                changeUserRole(userData).then(result => {
                    const updatedUser = {
                        ...this.props.userAllData,
                        userRole: newRole
                    };
                    this.props.updateUser(updatedUser);
                    this.setState({
                        succeed: true,
                        waitOnPromise: false,
                    });
                    currentForm.reset();
                });
            } else {
                alert('Nimaš ustreznih pravic za urejanje vodnikov!');
                this.setState({waitOnPromise: false});
            }
        }).catch((error) => {
            console.log(error);
            this.setState({waitOnPromise: false});
        });
    }

    render() {
        const userTroopsHave = Object.keys(this.props.troopsData).filter(f => this.props.troopsData[f].troopLeader === this.props.userID);
        return (
            <div className="wrapper-popup">
                <div className="inner-popup box-shadow">
                    <div id="popup-form" className="content-popup wrapper_forms">
                        <form id="change-roll" className="form-settings" onSubmit={this.changeRollForm}>
                            <h2 className="no-extra-notice">Menjava vloge</h2>
                            {(this.state.succeed) ?
                                <p className="form-success-message">Uspešno si zamenjal/a vlogo uporabnika.</p> :
                                <Fragment>
                                    <div className="body-form">
                                        <div className="form-section new-data">
                                            <p className="single-input user-roll-change">
                                                <select name="user-role" required onChange={this.checkUserTroop} defaultValue="vloga" ref={this.roleRef}>
                                                    <option value="vloga" disabled>Vloga</option>
                                                    {Object.keys(ALL_ROLES).map(key => <option key={key} value={ALL_ROLES[key].VALUE}>{ALL_ROLES[key].LABEL}</option>)}
                                                </select>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="footer-form wait-on-promise">
                                        {(this.state.userTroopLeader || (userTroopsHave.length === 0 && this.state.rollSelected)) ?
                                            <Fragment>
                                                <button type="submit">Zamenjaj vlogo</button>
                                                {(!this.state.waitOnPromise) ? null :
                                                    <div className="loader-on-submit">
                                                        <div className="loader-ico"><img src={loaderImage} alt="loader" /></div>
                                                    </div>
                                                }
                                            </Fragment> :
                                            (this.state.rollSelected && userTroopsHave.length > 0) ? <ChangeTroopBeforeEditUser
                                                userID={this.props.userID}
                                                updateCurrentTroop={this.props.updateCurrentTroop}
                                                archiveTroop={this.props.archiveTroop}
                                                deleteTroop={this.props.deleteTroop}
                                                troopsData={this.props.troopsData}
                                                users={this.props.users} /> :
                                                null
                                        }
                                    </div>
                                </Fragment>
                            }
                        </form>
                    </div>
                    <p className="floating-button"><button type="button" className="button-as-link" onClick={this.closePopUpClicked}>Zapri</button></p>
                </div>
            </div>
        )
    }
}

export default ChangeRollForUser;