import React from "react";
import base, {firebaseApp} from "../../base";
import { Route, Switch } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import NotFound from "../NotFound";
import MyProfile from "../myProfile/MyProfile";
import MainMenuAdmin from "../admin/MainMenuAdmin";
import OverviewInfoAdmin from "../admin/OverviewInfoAdmin";

import GroupAllMeetings from "./membersData/GroupAllMeetings";
import GroupAllMembers from "./membersData/GroupAllMembers";
import GroupMembersMembership from "./membersData/progress/GroupMembersMembership";
import GroupMembersActivities from "./membersData/progress/GroupMembersActivities";
import GroupMembersSkills from "./membersData/progress/GroupMembersSkills";

import RegisterUser from "../users/RegisterUser";
import ShowAllUsers from "../users/ShowAllUsers";
import UserCard from "../users/UserCard";

import ShowAllTroops from "../troops/adminAccess/ShowAllTroops";
import PastTroops from "../troops/adminAccess/PastTroops";
import RegisterTroop from "../troops/adminAccess/RegisterTroop";
import TroopsCardsSingleAdmin from "../troops/adminAccess/TroopsCardsSingleAdmin";

import MemberCard from "../members/MemberCard";
import MeetingCard from "../meetings/MeetingCard";
import RegisterMember from "../members/RegisterMember";
import RegisterWeeklyMeeting from "../meetings/RegisterWeeklyMeeting";

import Footer from "../Footer";

import loaderImage from "../../images/fidget-spinner-loader.svg";
import ALL_ROLES from "../../constants/roles";
import FAMILIES from "../../constants/familesClubs";

class CanvasAdmin extends React.Component {
    // States
    state = {
        loadingGeneral: true,
        troopsDataMC: {},
        loadingTroopsDataMC: true,
        troopsDataGG: {},
        loadingTroopsDataGG: true,
        troopsDataPP: {},
        loadingTroopsDataPP: true,
        troopsDataRR: {},
        loadingTroopsDataRR: true,
        troopsDataG: {},
        loadingTroopsDataG: true,
        troopsMembersMC: {},
        loadingTroopsMembersMC: true,
        troopsMembersGG: {},
        loadingTroopsMembersGG: true,
        troopsMembersPP: {},
        loadingTroopsMembersPP: true,
        troopsMembersRR: {},
        loadingTroopsMembersRR: true,
        troopsMembersG: {},
        loadingTroopsMembersG: true,
        troopsMeetingsMC: {},
        loadingTroopsMeetingsMC: true,
        troopsMeetingsGG: {},
        loadingTroopsMeetingsGG: true,
        troopsMeetingsPP: {},
        loadingTroopsMeetingsPP: true,
        troopsMeetingsRR: {},
        loadingTroopsMeetingsRR: true,
        troopsMeetingsG: {},
        loadingTroopsMeetingsG: true,
        archiveTroops: {},
        loadingArchiveTroops: true,
        troopLeaders: {},
        loadingTroopLeaders: true,
        troopsNumbers: {},
        loadingTroopsNumbers: true
    };

    // When component mount check database
    componentDidMount() {
        firebaseApp.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
            if(idTokenResult.claims.role === ALL_ROLES.ADMIN.VALUE) {
                this.ref1 = base.syncState(`/troopsData/`, {
                    context: this,
                    state: 'troopsDataMC',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.MC_FAMILY.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsDataMC: false});
                        this.checkLoading();
                    }
                });
                this.ref2 = base.syncState(`/troopsData/`, {
                    context: this,
                    state: 'troopsDataGG',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.GG_FAMILY.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsDataGG: false});
                        this.checkLoading();
                    }
                });
                this.ref3 = base.syncState(`/troopsData/`, {
                    context: this,
                    state: 'troopsDataPP',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.PP_CLUB.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsDataPP: false});
                        this.checkLoading();
                    }
                });
                this.ref4 = base.syncState(`/troopsData/`, {
                    context: this,
                    state: 'troopsDataRR',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.RR_CLUB.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsDataRR: false});
                        this.checkLoading();
                    }
                });
                this.ref5 = base.syncState(`/troopsData/`, {
                    context: this,
                    state: 'troopsDataG',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.G_CLUB.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsDataG: false});
                        this.checkLoading();
                    }
                });
                this.ref6 = base.syncState(`/troopsMembers/`, {
                    context: this,
                    state: 'troopsMembersMC',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.MC_FAMILY.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMembersMC: false});
                        this.checkLoading();
                    }
                });
                this.ref7 = base.syncState(`/troopsMembers/`, {
                    context: this,
                    state: 'troopsMembersGG',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.GG_FAMILY.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMembersGG: false});
                        this.checkLoading();
                    }
                });
                this.ref8 = base.syncState(`/troopsMembers/`, {
                    context: this,
                    state: 'troopsMembersPP',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.PP_CLUB.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMembersPP: false});
                        this.checkLoading();
                    }
                });
                this.ref9 = base.syncState(`/troopsMembers/`, {
                    context: this,
                    state: 'troopsMembersRR',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.RR_CLUB.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMembersRR: false});
                        this.checkLoading();
                    }
                });
                this.ref10 = base.syncState(`/troopsMembers/`, {
                    context: this,
                    state: 'troopsMembersG',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.G_CLUB.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMembersG: false});
                        this.checkLoading();
                    }
                });
                this.ref11 = base.syncState(`/troopsMeetings/`, {
                    context: this,
                    state: 'troopsMeetingsMC',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.MC_FAMILY.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMeetingsMC: false});
                        this.checkLoading();
                    }
                });
                this.ref12 = base.syncState(`/troopsMeetings/`, {
                    context: this,
                    state: 'troopsMeetingsGG',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.GG_FAMILY.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMeetingsGG: false});
                        this.checkLoading();
                    }
                });
                this.ref13 = base.syncState(`/troopsMeetings/`, {
                    context: this,
                    state: 'troopsMeetingsPP',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.PP_CLUB.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMeetingsPP: false});
                        this.checkLoading();
                    }
                });
                this.ref14 = base.syncState(`/troopsMeetings/`, {
                    context: this,
                    state: 'troopsMeetingsRR',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.RR_CLUB.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMeetingsRR: false});
                        this.checkLoading();
                    }
                });
                this.ref15 = base.syncState(`/troopsMeetings/`, {
                    context: this,
                    state: 'troopsMeetingsG',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.G_CLUB.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMeetingsG: false});
                        this.checkLoading();
                    }
                });
                this.ref16 = base.syncState(`/archiveTroops/`, {
                    context: this,
                    state: 'archiveTroops',
                    then(){
                        this.setState({loadingArchiveTroops: false});
                        this.checkLoading();
                    }
                });
                this.ref17 = base.syncState(`/leaders/`, {
                    context: this,
                    state: 'troopLeaders',
                    then(){
                        this.setState({loadingTroopLeaders: false});
                        this.checkLoading();
                    }
                });
                this.ref18 = base.syncState(`/troopsNumbers/`, {
                    context: this,
                    state: 'troopsNumbers',
                    then(){
                        this.setState({loadingTroopsNumbers: false});
                        this.checkLoading();
                    }
                });
            } else if(idTokenResult.claims.role === ALL_ROLES.GG.VALUE) {
                this.ref1 = base.syncState(`/troopsData/`, {
                    context: this,
                    state: 'troopsDataGG',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.GG_FAMILY.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsDataGG: false});
                        this.checkLoading();
                    }
                });
                this.ref2 = base.syncState(`/troopsMembers/`, {
                    context: this,
                    state: 'troopsMembersGG',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.GG_FAMILY.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMembersGG: false});
                        this.checkLoading();
                    }
                });
                this.ref3 = base.syncState(`/troopsMeetings/`, {
                    context: this,
                    state: 'troopsMeetingsGG',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.GG_FAMILY.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMeetingsGG: false});
                        this.checkLoading();
                    }
                });
                this.ref4 = base.syncState(`/leaders/`, {
                    context: this,
                    state: 'troopLeaders',
                    queries: {
                        orderByChild: 'userRole',
                        equalTo: ALL_ROLES.TROOP_LEADER.VALUE
                    },
                    then(){
                        this.setState({loadingTroopLeaders: false});
                        this.checkLoading();
                    }
                });
                this.ref5 = base.syncState(`/troopsNumbers/`, {
                    context: this,
                    state: 'troopsNumbers',
                    then(){
                        this.setState({loadingTroopsNumbers: false});
                        this.checkLoading();
                    }
                });
                this.setState({
                    loadingTroopsDataMC: false,
                    loadingTroopsDataPP: false,
                    loadingTroopsDataRR: false,
                    loadingTroopsDataG: false,
                    loadingTroopsMembersMC: false,
                    loadingTroopsMembersPP: false,
                    loadingTroopsMembersRR: false,
                    loadingTroopsMembersG: false,
                    loadingTroopsMeetingsMC: false,
                    loadingTroopsMeetingsPP: false,
                    loadingTroopsMeetingsRR: false,
                    loadingTroopsMeetingsG: false,
                    loadingArchiveTroops: false
                });
            } else if(idTokenResult.claims.role === ALL_ROLES.MC.VALUE) {
                this.ref1 = base.syncState(`/troopsData/`, {
                    context: this,
                    state: 'troopsDataMC',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.MC_FAMILY.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsDataMC: false});
                        this.checkLoading();
                    }
                });
                this.ref2 = base.syncState(`/troopsMembers/`, {
                    context: this,
                    state: 'troopsMembersMC',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.MC_FAMILY.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMembersMC: false});
                        this.checkLoading();
                    }
                });
                this.ref3 = base.syncState(`/troopsMeetings/`, {
                    context: this,
                    state: 'troopsMeetingsMC',
                    queries: {
                        orderByChild: 'troopRole',
                        equalTo: FAMILIES.MC_FAMILY.VALUE
                    },
                    then(){
                        this.setState({loadingTroopsMeetingsMC: false});
                        this.checkLoading();
                    }
                });
                this.ref4 = base.syncState(`/leaders/`, {
                    context: this,
                    state: 'troopLeaders',
                    queries: {
                        orderByChild: 'userRole',
                        equalTo: ALL_ROLES.TROOP_LEADER.VALUE
                    },
                    then(){
                        this.setState({loadingTroopLeaders: false});
                        this.checkLoading();
                    }
                });
                this.ref5 = base.syncState(`/troopsNumbers/`, {
                    context: this,
                    state: 'troopsNumbers',
                    then(){
                        this.setState({loadingTroopsNumbers: false});
                        this.checkLoading();
                    }
                });
                this.setState({
                    loadingTroopsDataGG: false,
                    loadingTroopsDataPP: false,
                    loadingTroopsDataRR: false,
                    loadingTroopsDataG: false,
                    loadingTroopsMembersGG: false,
                    loadingTroopsMembersPP: false,
                    loadingTroopsMembersRR: false,
                    loadingTroopsMembersG: false,
                    loadingTroopsMeetingsGG: false,
                    loadingTroopsMeetingsPP: false,
                    loadingTroopsMeetingsRR: false,
                    loadingTroopsMeetingsG: false,
                    loadingArchiveTroops: false
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    // Remove all data from states. Safety for memory lik
    componentWillUnmount() {
        if(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) {
            base.removeBinding(this.ref1); base.removeBinding(this.ref2); base.removeBinding(this.ref3); base.removeBinding(this.ref4); base.removeBinding(this.ref5);
        } else {
            base.removeBinding(this.ref1); base.removeBinding(this.ref2); base.removeBinding(this.ref3); base.removeBinding(this.ref4);
            base.removeBinding(this.ref5); base.removeBinding(this.ref6); base.removeBinding(this.ref7); base.removeBinding(this.ref8);
            base.removeBinding(this.ref9); base.removeBinding(this.ref10); base.removeBinding(this.ref11); base.removeBinding(this.ref12);
            base.removeBinding(this.ref13); base.removeBinding(this.ref14); base.removeBinding(this.ref15); base.removeBinding(this.ref16);
            base.removeBinding(this.ref17); base.removeBinding(this.ref18);
        }
    }

    /* ----------------------------------------------> Combine functions <------------------------------------------- */

    checkLoading = () => {
        if(this.state.loadingTroopsDataMC === false && this.state.loadingTroopsDataGG === false && this.state.loadingTroopsDataPP === false && this.state.loadingTroopsDataRR === false && this.state.loadingTroopsDataG === false &&
            this.state.loadingTroopsMembersMC === false && this.state.loadingTroopsMembersGG === false && this.state.loadingTroopsMembersPP === false && this.state.loadingTroopsMembersRR === false && this.state.loadingTroopsMembersG === false &&
            this.state.loadingTroopsMeetingsMC === false && this.state.loadingTroopsMeetingsGG === false && this.state.loadingTroopsMeetingsPP === false && this.state.loadingTroopsMeetingsRR === false && this.state.loadingTroopsMeetingsG === false &&
            this.state.loadingArchiveTroops === false && this.state.loadingTroopLeaders === false && this.state.loadingTroopsNumbers === false) {
            this.setState({loadingGeneral: false})
        }
    }

    troopsData() {
        const combinedTroopsData = {};
        Object.keys(this.state.troopsDataMC).map(key => combinedTroopsData[key] = this.state.troopsDataMC[key]);
        Object.keys(this.state.troopsDataGG).map(key => combinedTroopsData[key] = this.state.troopsDataGG[key]);
        Object.keys(this.state.troopsDataPP).map(key => combinedTroopsData[key] = this.state.troopsDataPP[key]);
        Object.keys(this.state.troopsDataRR).map(key => combinedTroopsData[key] = this.state.troopsDataRR[key]);
        Object.keys(this.state.troopsDataG).map(key => combinedTroopsData[key] = this.state.troopsDataG[key]);

        return combinedTroopsData;
    }

    troopsMembers() {
        const combinedTroopsMembers = {};
        Object.keys(this.state.troopsDataMC).map(key => combinedTroopsMembers[key] = this.state.troopsMembersMC[key]);
        Object.keys(this.state.troopsDataGG).map(key => combinedTroopsMembers[key] = this.state.troopsMembersGG[key]);
        Object.keys(this.state.troopsDataPP).map(key => combinedTroopsMembers[key] = this.state.troopsMembersPP[key]);
        Object.keys(this.state.troopsDataRR).map(key => combinedTroopsMembers[key] = this.state.troopsMembersRR[key]);
        Object.keys(this.state.troopsDataG).map(key => combinedTroopsMembers[key] = this.state.troopsMembersG[key]);

        return combinedTroopsMembers;
    }

    troopsMeetings() {
        const combinedTroopsMeetings = {};
        Object.keys(this.state.troopsDataMC).map(key => combinedTroopsMeetings[key] = this.state.troopsMeetingsMC[key]);
        Object.keys(this.state.troopsDataGG).map(key => combinedTroopsMeetings[key] = this.state.troopsMeetingsGG[key]);
        Object.keys(this.state.troopsDataPP).map(key => combinedTroopsMeetings[key] = this.state.troopsMeetingsPP[key]);
        Object.keys(this.state.troopsDataRR).map(key => combinedTroopsMeetings[key] = this.state.troopsMeetingsRR[key]);
        Object.keys(this.state.troopsDataG).map(key => combinedTroopsMeetings[key] = this.state.troopsMeetingsG[key]);

        return combinedTroopsMeetings;
    }

    saveDataToTroops(troopRole, type) {
        let troopsDataName;
        let troopsData;
        let troopsMembers;
        let troopsMeetings;
        if(troopRole === FAMILIES.MC_FAMILY.VALUE) {
            troopsDataName = 'MC';
            troopsData = { ...this.state.troopsDataMC };
            troopsMembers = { ...this.state.troopsMembersMC };
            troopsMeetings = { ...this.state.troopsMeetingsMC };
        } else if(troopRole === FAMILIES.GG_FAMILY.VALUE) {
            troopsDataName = 'GG';
            troopsData = { ...this.state.troopsDataGG };
            troopsMembers = { ...this.state.troopsMembersGG };
            troopsMeetings = { ...this.state.troopsMeetingsGG };
        } else if(troopRole === FAMILIES.PP_CLUB.VALUE) {
            troopsDataName = 'PP';
            troopsData = { ...this.state.troopsDataPP };
            troopsMembers = { ...this.state.troopsMembersPP };
            troopsMeetings = { ...this.state.troopsMeetingsPP };
        } else if(troopRole === FAMILIES.RR_CLUB.VALUE) {
            troopsDataName = 'RR';
            troopsData = { ...this.state.troopsDataRR };
            troopsMembers = { ...this.state.troopsMembersRR };
            troopsMeetings = { ...this.state.troopsMeetingsRR };
        } else {
            troopsDataName = 'G';
            troopsData = { ...this.state.troopsDataG };
            troopsMembers = { ...this.state.troopsMembersG };
            troopsMeetings = { ...this.state.troopsMeetingsG };
        }

        switch (type) {
            case "dataName":
                return troopsDataName;
            case "data":
                return troopsData;
            case "members":
                return troopsMembers;
            case "meetings":
                return troopsMeetings;
            default:
                return null;
        }
    }

    /* ----------------------------------------------> Admin functions <--------------------------------------------- */
    // Add new troop
    addTroop = (troop) => {
        const troopsDataName = this.saveDataToTroops(troop.troopRole, 'dataName');

        const troopsData = this.saveDataToTroops(troop.troopRole, 'data');
        const troopsMembers = this.saveDataToTroops(troop.troopRole, 'members');
        const troopsMeetings = this.saveDataToTroops(troop.troopRole, 'meetings');

        troopsData[troop.troopID] = troop;
        troopsMembers[troop.troopID] = {
            troopID: troop.troopID,
            troopLeader: troop.troopLeader,
            troopRole: troop.troopRole
        };
        troopsMeetings[troop.troopID] = {
            troopID: troop.troopID,
            troopLeader: troop.troopLeader,
            troopRole: troop.troopRole
        };

        this.setState({
            [`troopsData${troopsDataName}`]: troopsData,
            [`troopsMembers${troopsDataName}`]: troopsMembers,
            [`troopsMeetings${troopsDataName}`]: troopsMeetings
        });
    };

    // Update selected troop
    updateCurrentTroop = (troop) => {
        const oldTroopID = Object.keys(this.troopsData()).filter(f => this.troopsData()[f].troopID === troop.troopID);
        const oldTroopRole = this.troopsData()[oldTroopID[0]].troopRole;

        const troopsDataName = this.saveDataToTroops(oldTroopRole, 'dataName');

        const troopsData = this.saveDataToTroops(oldTroopRole, 'data');
        const troopsMembers = this.saveDataToTroops(oldTroopRole, 'members');
        const troopsMeetings = this.saveDataToTroops(oldTroopRole, 'meetings');

        if(oldTroopRole !== troop.troopRole && this.props.currentUser.userRole !== ALL_ROLES.GG.VALUE && this.props.currentUser.userRole !== ALL_ROLES.MC.VALUE) {
            this.setState({
                loadingGeneral: true,
                loadingTroopsDataMC: true,
                loadingTroopsMembersMC: true,
                loadingTroopsMeetingsMC: true
            });
        }

        troopsData[troop.troopID] = troop;

        troopsMembers[troop.troopID].troopLeader = troop.troopLeader;
        troopsMembers[troop.troopID].troopRole = troop.troopRole;

        troopsMeetings[troop.troopID].troopLeader = troop.troopLeader;
        troopsMeetings[troop.troopID].troopRole = troop.troopRole;

        this.setState(
            {
                [`troopsData${troopsDataName}`]: troopsData,
                [`troopsMembers${troopsDataName}`]: troopsMembers,
                [`troopsMeetings${troopsDataName}`]: troopsMeetings
            },
            this.troopRoleIsDone(oldTroopRole, troop.troopRole)
        );
    }

    troopRoleIsDone = (oldTroopRole, newTroopRole) => {
        if(oldTroopRole !== newTroopRole && this.props.currentUser.userRole !== ALL_ROLES.GG.VALUE && this.props.currentUser.userRole !== ALL_ROLES.MC.VALUE) {
            base.listenTo('troopsData', {
                context: this,
                asArray: true,
                then(votesData){
                    this.setState({loadingTroopsDataMC: false});
                    this.checkLoading();
                }
            });
            base.listenTo('troopsMembers', {
                context: this,
                asArray: true,
                then(votesData){
                    this.setState({loadingTroopsMembersMC: false});
                    this.checkLoading();
                }
            });
            base.listenTo('troopsMeetings', {
                context: this,
                asArray: true,
                then(votesData){
                    this.setState({loadingTroopsMeetingsMC: false});
                    this.checkLoading();
                }
            });
        }
        return null;
    }

    // Archive troop
    archiveTroop = (troop) => {
        const troopsDataName = this.saveDataToTroops(troop.troopRole, 'dataName');

        const troopsData = this.saveDataToTroops(troop.troopRole, 'data');
        const troopsMembers = this.saveDataToTroops(troop.troopRole, 'members');
        const troopsMeetings = this.saveDataToTroops(troop.troopRole, 'meetings');
        const archiveTroops = { ...this.state.archiveTroops };

        archiveTroops[troop.troopID] = troop;
        if('members' in troopsMembers[troop.troopID]) {
            archiveTroops[troop.troopID].members = troopsMembers[troop.troopID].members;
        }
        if('meetings' in troopsMeetings[troop.troopID]) {
            archiveTroops[troop.troopID].meetings = troopsMeetings[troop.troopID].meetings;
        }
        if('troopLeader' in archiveTroops[troop.troopID]) {
            delete archiveTroops[troop.troopID].troopLeader;
        }
        troopsData[troop.troopID] = null;
        troopsMembers[troop.troopID] = null;
        troopsMeetings[troop.troopID] = null;
        this.setState({
            archiveTroops: archiveTroops,
            [`troopsData${troopsDataName}`]: troopsData,
            [`troopsMembers${troopsDataName}`]: troopsMembers,
            [`troopsMeetings${troopsDataName}`]: troopsMeetings
        });
    }

    // Reverse Archive troop
    reverseArchiveTroop = (troop) => {
        const archiveTroops = { ...this.state.archiveTroops };

        const troopsDataName = this.saveDataToTroops(troop.troopRole, 'dataName');

        const troopsData = this.saveDataToTroops(troop.troopRole, 'data');
        const troopsMembers = this.saveDataToTroops(troop.troopRole, 'members');
        const troopsMeetings = this.saveDataToTroops(troop.troopRole, 'meetings');

        troopsData[troop.troopID] = troop;

        troopsMembers[troop.troopID] = {
            troopID: troop.troopID,
            troopRole: troop.troopRole
        }
        if('members' in troop) {
            troopsMembers[troop.troopID].members = troop.members;
        }
        troopsMeetings[troop.troopID] = {
            troopID: troop.troopID,
            troopRole: troop.troopRole
        }
        if('meetings' in troop) {
            troopsMeetings[troop.troopID].meetings = troop.meetings;
        }

        archiveTroops[troop.troopID] = null;
        if('members' in troopsData[troop.troopID]) {
            delete troopsData[troop.troopID].members;
        }
        if('meetings' in troopsData[troop.troopID]) {
            delete troopsData[troop.troopID].meetings;
        }

        this.setState({
            archiveTroops: archiveTroops,
            [`troopsData${troopsDataName}`]: troopsData,
            [`troopsMembers${troopsDataName}`]: troopsMembers,
            [`troopsMeetings${troopsDataName}`]: troopsMeetings
        });
    }

    // Delete troop for good
    deleteTroop = (troop) => {
        const troopsDataName = this.saveDataToTroops(troop.troopRole, 'dataName');

        const troopsData = this.saveDataToTroops(troop.troopRole, 'data');
        const troopsMembers = this.saveDataToTroops(troop.troopRole, 'members');
        const troopsMeetings = this.saveDataToTroops(troop.troopRole, 'meetings');

        troopsData[troop.troopID] = null;
        troopsMembers[troop.troopID] = null;
        troopsMeetings[troop.troopID] = null;

        this.setState({
            [`troopsData${troopsDataName}`]: troopsData,
            [`troopsMembers${troopsDataName}`]: troopsMembers,
            [`troopsMeetings${troopsDataName}`]: troopsMeetings
        });
    }

    // Delete past troop for good
    deleteArchiveTroop = (troop) => {
        const archiveTroops = { ...this.state.archiveTroops };
        archiveTroops[troop] = null;
        this.setState({archiveTroops: archiveTroops});
    }

    // Add new troop leader
    addTroopLeader = (id, troopLeader) => {
        // Take a copy of existing state
        const troopLeaders = { ...this.state.troopLeaders };
        // Add a new user to staff
        troopLeaders[id] = troopLeader;
        troopLeaders[id]['userID'] = id;
        // Set the new object to state
        this.setState({
            troopLeaders: troopLeaders
        });
    };

    // Update user (other)
    updateUser = (updatedUser) => {
        const troopLeaders = { ...this.state.troopLeaders };
        troopLeaders[updatedUser.userID] = updatedUser;
        this.setState({troopLeaders: troopLeaders});
    }

    // Delete user
    deleteUser = (user) => {
        const troopLeaders = { ...this.state.troopLeaders };
        troopLeaders[user] = null;
        this.setState({troopLeaders: troopLeaders});
    }

    // Add troops number to state and database
    addTroopsNumbers = (numbers) => {
        const startDate = new Date(this.props.schoolYears.currentStartSchoolDate).getFullYear();
        const endDate = new Date(this.props.schoolYears.currentEndSchoolDate).getFullYear();
        const combineYears = `${startDate}-${endDate}`;

        const troopsNumbers = { ...this.state.troopsNumbers };
        if(!(combineYears in troopsNumbers)) {
            troopsNumbers[combineYears] = {}
        }
        troopsNumbers[combineYears] = numbers;
        this.setState({troopsNumbers: troopsNumbers});
    }

    /* -----------------------------------------------> User functions <--------------------------------------------- */
    // Add new member
    addMember = (member) => {
        const troopsDataName = this.saveDataToTroops(member.troopRole, 'dataName');
        // Take a copy of existing state
        const troopsMembers = this.saveDataToTroops(member.troopRole, 'members');

        // Check if object members exists in selected troop
        if(!('members' in troopsMembers[member.troop])) {
            troopsMembers[member.troop]['members'] = {};
        }

        // Add a new member to members in troop
        troopsMembers[member.troop]['members'][member.memberID] = member;
        delete troopsMembers[member.troop]['members'][member.memberID].troopRole;

        // Set the new object to state
        this.setState({ [`troopsMembers${troopsDataName}`]: troopsMembers });
    };

    // Update current member - based on member page
    updateCurrentMember = (updateCurrentMember, troopRole) => {
        const troopsDataName = this.saveDataToTroops(troopRole, 'dataName');
        // Take a copy of existing state
        const troopsMembers = this.saveDataToTroops(troopRole, 'members');

        troopsMembers[updateCurrentMember.troop]['members'][updateCurrentMember.memberID] = updateCurrentMember;

        // Set the new object to state
        this.setState({ [`troopsMembers${troopsDataName}`]: troopsMembers });
    }

    // Delete current member - based on member page
    deleteCurrentMember = (currentMember, troopRole) => {
        const troopsDataName = this.saveDataToTroops(troopRole, 'dataName');
        // Take a copy of existing state
        const troopsMembers = this.saveDataToTroops(troopRole, 'members');

        troopsMembers[currentMember.troop]['members'][currentMember.memberID] = null;

        // Check if object past members exists in selected troop
        if(!('pastMembers' in troopsMembers[currentMember.troop])) {
            troopsMembers[currentMember.troop]['pastMembers'] = {};
        }
        troopsMembers[currentMember.troop]['pastMembers'][currentMember.memberID] = currentMember;

        // Set the new object to state
        this.setState({ [`troopsMembers${troopsDataName}`]: troopsMembers });
    }

    // Update current member - progress data
    updateCurrentMemberProgress = (updateCurrentMemberProgressData, updatedCurrentMemberProgressFields, troopRole) => {
        const troopsDataName = this.saveDataToTroops(troopRole, 'dataName');
        // Take a copy of existing state
        const troopsMembers = this.saveDataToTroops(troopRole, 'members');

        // Check if object progress exists in selected troop
        if(!('progress' in troopsMembers[updateCurrentMemberProgressData.selectedMember.troop]['members'][updateCurrentMemberProgressData.selectedMember.memberID])) {
            troopsMembers[updateCurrentMemberProgressData.selectedMember.troop]['members'][updateCurrentMemberProgressData.selectedMember.memberID]['progress'] = {};
        }

        // Add new progress to member.
        troopsMembers[updateCurrentMemberProgressData.selectedMember.troop]['members'][updateCurrentMemberProgressData.selectedMember.memberID]['progress'][updateCurrentMemberProgressData.selectedYear] = updatedCurrentMemberProgressFields;

        // Set the new object to state
        this.setState({ [`troopsMembers${troopsDataName}`]: troopsMembers });
    }

    // Restore member from past members
    restoreCurrentMember = (currentMember, troopRole) => {
        const troopsDataName = this.saveDataToTroops(troopRole, 'dataName');
        // Take a copy of existing state
        const troopsMembers = this.saveDataToTroops(troopRole, 'members');

        troopsMembers[currentMember.troop]['pastMembers'][currentMember.memberID] = null;

        // Check if object members exists in selected troop
        if(!('members' in troopsMembers[currentMember.troop])) {
            troopsMembers[currentMember.troop]['members'] = {};
        }
        troopsMembers[currentMember.troop]['members'][currentMember.memberID] = currentMember;

        // Set the new object to state
        this.setState({ [`troopsMembers${troopsDataName}`]: troopsMembers });
    }

    // Functions for manipulating weekly meetings of troop
    addWeeklyMeeting = (meeting) => {
        const troopsDataName = this.saveDataToTroops(meeting.troopRole, 'dataName');
        // Take a copy of existing state
        const troopsMeetings = this.saveDataToTroops(meeting.troopRole, 'meetings');

        // Check if object meetings exists in selected troop
        if(!('meetings' in troopsMeetings[meeting.troop])) {
            troopsMeetings[meeting.troop]['meetings'] = {};
        }

        // Add new meeting to meetings.
        troopsMeetings[meeting.troop]['meetings'][meeting.meetingID] = meeting;
        delete troopsMeetings[meeting.troop]['meetings'][meeting.meetingID].troopRole;

        // Set the new object to state
        this.setState({ [`troopsMeetings${troopsDataName}`]: troopsMeetings} );
    }

    // Update current meeting - based on meeting page
    updateCurrentMeeting = (updateCurrentMeeting, troopRole) => {
        const troopsDataName = this.saveDataToTroops(troopRole, 'dataName');
        // Take a copy of existing state
        const troopsMeetings = this.saveDataToTroops(troopRole, 'meetings');

        troopsMeetings[updateCurrentMeeting.troop]['meetings'][updateCurrentMeeting.meetingID] = updateCurrentMeeting;

        // Set the new object to state
        this.setState({ [`troopsMeetings${troopsDataName}`]: troopsMeetings} );
    }

    // Delete current meeting - based on meeting page
    deleteCurrentMeeting = (currentMeeting, troopRole) => {
        const troopsDataName = this.saveDataToTroops(troopRole, 'dataName');
        // Take a copy of existing state
        const troopsMeetings = this.saveDataToTroops(troopRole, 'meetings');

        troopsMeetings[currentMeeting.troop]['meetings'][currentMeeting.meetingID] = null;

        // Set the new object to state
        this.setState({ [`troopsMeetings${troopsDataName}`]: troopsMeetings} );
    }

    /* ---------------------------------------------> Render front end <--------------------------------------------- */
    render() {
        // Send props to elements in routes
        const proOverviewInfo = (props) => {
            return (
                <OverviewInfoAdmin
                    currentUser={this.props.currentUser}
                    troopsData={this.troopsData()}
                    troopsMembers={this.troopsMembers()}
                    schoolYears={this.props.schoolYears}
                    addSchoolYear={this.props.addSchoolYear}
                    updateCurrentSchoolYear={this.props.updateCurrentSchoolYear}
                    endSchoolYear={this.props.endSchoolYear}
                    troopsNumbers={this.state.troopsNumbers}
                    addTroopsNumbers={this.addTroopsNumbers.bind(this)}
                    windowWidth={this.props.windowWidth}
                    windowHeight={this.props.windowHeight}
                    {...props}
                />
            )
        }
        const proMyProfile = (props) => {
            return (
                <MyProfile
                    updateCurrentUser={this.props.updateCurrentUser}
                    updateCurrentUserEmail={this.props.updateCurrentUserEmail}
                    user={this.props.currentUser}
                    {...props}
                />
            );
        };
        const proLeaders = (props) => {
            return (
                <ShowAllUsers
                    currentUser={this.props.currentUser}
                    users={this.state.troopLeaders}
                    {...props}
                />
            );
        };
        const proAllTroops = (props) => {
            return (
                <ShowAllTroops
                    troopsData={this.troopsData()}
                    users={this.state.troopLeaders}
                    {...props}
                />
            );
        };
        const proPastTroops = (props) => {
            return (
                <PastTroops
                    reverseArchiveTroop={this.reverseArchiveTroop.bind(this)}
                    deleteTroop={this.deleteArchiveTroop.bind(this)}
                    archiveTroops={this.state.archiveTroops}
                    users={this.state.troopLeaders}
                    {...props}
                />
            );
        };
        const proRegisterTroop = (props) => {
            return (
                <RegisterTroop
                    addTroop={this.addTroop.bind(this)}
                    troopsData={this.troopsData()}
                    users={this.state.troopLeaders}
                    {...props}
                />
            );
        };
        const proRegisterTroopLeader = (props) => {
            return (
                <RegisterUser
                    addTroopLeader={this.addTroopLeader.bind(this)}
                    {...props}
                />
            );
        };
        const proUserCard = (props) => {
            return (
                <UserCard
                    updateUser={this.updateUser.bind(this)}
                    deleteUser={this.deleteUser.bind(this)}
                    updateCurrentTroop={this.updateCurrentTroop.bind(this)}
                    archiveTroop={this.archiveTroop.bind(this)}
                    deleteTroop={this.deleteTroop.bind(this)}
                    users={this.state.troopLeaders}
                    troopsData={this.troopsData()}
                    {...props}
                />
            );
        };
        const proTroopCard = (props) => {
            return (
                <TroopsCardsSingleAdmin
                    currentUser={this.props.currentUser}
                    updateCurrentTroop={this.updateCurrentTroop.bind(this)}
                    restoreCurrentMember={this.restoreCurrentMember.bind(this)}
                    archiveTroop={this.archiveTroop.bind(this)}
                    deleteTroop={this.deleteTroop.bind(this)}
                    users={this.state.troopLeaders}
                    troopsData={this.troopsData()}
                    troopsMembers={this.troopsMembers()}
                    troopsMeetings={this.troopsMeetings()}
                    schoolYears={this.props.schoolYears}
                    {...props}
                />
            );
        };
        const proGroupAllMeetings = (props) => {
            return (
                <GroupAllMeetings
                    troopsData={this.troopsData()}
                    troopsMeetings={this.troopsMeetings()}
                    {...props}
                />
            );
        };
        const proGroupAllMembers = (props) => {
            return (
                <GroupAllMembers
                    troopsData={this.troopsData()}
                    troopsMembers={this.troopsMembers()}
                    troopsMeetings={this.troopsMeetings()}
                    schoolYears={this.props.schoolYears}
                    {...props}
                />
            );
        };
        const proGroupAllMembership = (props) => {
            return (
                <GroupMembersMembership
                    troopsData={this.troopsData()}
                    troopsMembers={this.troopsMembers()}
                    updateCurrentMemberProgress={this.updateCurrentMemberProgress}
                    schoolYears={this.props.schoolYears}
                    {...props}
                />
            );
        };
        const proGroupMembersActivities = (props) => {
            return (
                <GroupMembersActivities
                    windowWidth={this.props.windowWidth}
                    windowHeight={this.props.windowHeight}
                    troopsData={this.troopsData()}
                    troopsMembers={this.troopsMembers()}
                    updateCurrentMemberProgress={this.updateCurrentMemberProgress}
                    schoolYears={this.props.schoolYears}
                    {...props}
                />
            );
        };
        const proGroupMembersSkills = (props) => {
            return (
                <GroupMembersSkills
                    troopsData={this.troopsData()}
                    troopsMembers={this.troopsMembers()}
                    updateCurrentMemberProgress={this.updateCurrentMemberProgress}
                    schoolYears={this.props.schoolYears}
                    {...props}
                />
            );
        };
        const proMemberCard = (props) => {
            return (
                <MemberCard
                    currentUserRole={this.props.currentUser.userRole}
                    addMember={this.addMember.bind(this)}
                    updateCurrentMember={this.updateCurrentMember}
                    deleteCurrentMember={this.deleteCurrentMember}
                    updateCurrentMemberProgress={this.updateCurrentMemberProgress}
                    troopsData={this.troopsData()}
                    troopsMembers={this.troopsMembers()}
                    schoolYears={this.props.schoolYears}
                    {...props}
                />
            );
        };
        const proRegisterMember = (props) => {
            return (
                <RegisterMember
                    addMember={this.addMember.bind(this)}
                    troopsData={this.troopsData()}
                    currentYear={{currentStartSchoolDate: this.props.schoolYears.currentStartSchoolDate, currentEndSchoolDate: this.props.schoolYears.currentEndSchoolDate}}
                    {...props}
                />
            );
        };
        const proMeetingCard = (props) => {
            return (
                <MeetingCard
                    updateCurrentMeeting={this.updateCurrentMeeting}
                    deleteCurrentMeeting={this.deleteCurrentMeeting}
                    troopsData={this.troopsData()}
                    troopsMeetings={this.troopsMeetings()}
                    troopsMembers={this.troopsMembers()}
                    {...props}
                />
            );
        };
        const proRegisterWeeklyMeeting = (props) => {
            return (
                <RegisterWeeklyMeeting
                    addWeeklyMeeting={this.addWeeklyMeeting.bind(this)}
                    troopsData={this.troopsData()}
                    troopsMembers={this.troopsMembers()}
                    {...props}
                />
            );
        };
        if(this.state.loadingGeneral) {
            return (
                <div className="loading-screen">
                    <div className="loader-ico"><img src={loaderImage} alt="loader" /></div>
                </div>
            )
        } else {
            return (
                <div className="wrapper-main-app">
                    <MainMenuAdmin
                        windowWidth={this.props.windowWidth}
                        windowHeight={this.props.windowHeight}
                        logout={this.props.logout}
                        currentUser={this.props.currentUser}
                        userName={this.props.currentUser.userName}
                    />
                    <main className="main-canvas">
                        <Switch>
                            <Route path={ROUTES.HOME} exact render={proOverviewInfo}/>
                            <Route path={ROUTES.MY_PROFILE} render={proMyProfile}/>
                            <Route path={ROUTES.ALL_MEETINGS} render={proGroupAllMeetings}/>
                            <Route path={ROUTES.ALL_MEMBERS} render={proGroupAllMembers}/>
                            <Route path={ROUTES.MEMBERS_MEMBERSHIP} render={proGroupAllMembership}/>
                            <Route path={ROUTES.MEMBERS_ACTIVITIES} render={proGroupMembersActivities}/>
                            <Route path={ROUTES.MEMBERS_SKILLS} render={proGroupMembersSkills}/>
                            <Route path={ROUTES.TROOPS} render={proAllTroops} />
                            {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null : <Route path={ROUTES.REGISTER_TROOP} render={proRegisterTroop}/> }
                            {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null : <Route path={ROUTES.PAST_TROOPS} render={proPastTroops}/> }
                            {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null : <Route path={ROUTES.LEADERS} render={proLeaders}/> }
                            {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null : <Route path={ROUTES.REGISTER_TROOP_LEADER} render={proRegisterTroopLeader}/> }
                            {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null : <Route path={`${ROUTES.USER_CARD}/:userID`} render={proUserCard}/> }
                            <Route path={`${ROUTES.TROOP_CARD}/:troopID`} render={proTroopCard}/>
                            <Route path={`${ROUTES.REGISTER_MEMBER}/:troopID?`} render={proRegisterMember}/>
                            <Route path={`${ROUTES.MEMBER_CARD}/:troopId/:memberId`} render={proMemberCard}/>
                            <Route path={`${ROUTES.REGISTER_WEEKLY_MEETING}/:troopID?`} render={proRegisterWeeklyMeeting}/>
                            <Route path={`${ROUTES.MEETING_CARD}/:troopId/:meetingId`} render={proMeetingCard}/>
                            <Route render={NotFound}/>
                        </Switch>
                    </main>
                    <Footer />
                </div>
            )
        }
    }
}

export default CanvasAdmin;
