import React from "react";
import ShowAllMembers from "../members/ShowAllMembers";
import ShowAllMeetings from "../meetings/ShowAllMeetings";

const OverviewInfoUser = (props) => (
    <div className="overview-info">
        <h1 className="yellow-font">Nadzorna plošča</h1>
        <ShowAllMembers troopsData={props.troopsData} troopsMembers={props.troopsMembers} troopsMeetings={props.troopsMeetings} schoolYears={props.schoolYears}/>
        <ShowAllMeetings troopsData={props.troopsData} troopsMeetings={props.troopsMeetings} />
    </div>
);

export default OverviewInfoUser;
