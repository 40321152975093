const FAMILIES = {
    MC_FAMILY: {
        VALUE: "mc-vod",
        LABEL: "MČ vod"
    },
    GG_FAMILY: {
        VALUE: "gg-vod",
        LABEL: "GG vod"
    },
    PP_CLUB: {
        VALUE: "pp-klub",
        LABEL: "PP klub"
    },
    RR_CLUB: {
        VALUE: "rr-klub",
        LABEL: "RR klub"
    },
    G_CLUB: {
        VALUE: "grce-klub",
        LABEL: "Grče klub"
    }
}

export const SPECIAL_TROOPS = {
    NONE: {
        VALUE: "none",
        LABEL: "Klasičen vod"
    },
    NONE_IN_FAMILY: {
        VALUE: "neuvrsceni",
        LABEL: "Neuvrščeni"
    },
    DONT_COUNT: {
        VALUE: "zunaj-strukture",
        LABEL: "Zunaj strukture"
    },
    FAMILY: {
        VALUE: "druzina",
        LABEL: "Družina"
    },
    CLUB: {
        VALUE: "klub",
        LABEL: "Klub"
    }
}

export default FAMILIES;