import React from "react";
import { generatePushID } from "../../helpers";
import NOTICES from "../../constants/notices";
import {PHOTOS, FOOD} from "../../constants/registerMembersOptions";

class RegisterMember extends React.Component {
    // Member info refs
    memberTroopRef = React.createRef();

    memberNameRef = React.createRef();
    memberLastNameRef = React.createRef();
    memberBirthRef = React.createRef();
    memberSchoolClassRef = React.createRef();
    memberAddressRef = React.createRef();
    memberPostRef = React.createRef();
    memberEmailRef = React.createRef();
    memberTelRef = React.createRef();
    memberBirthCityRef = React.createRef();
    memberCitizenshipRef = React.createRef();
    memberIdNumberRef = React.createRef();

    memberParentEmailRef = React.createRef();
    memberParentTelRef = React.createRef();
    memberParentFatherEmailRef = React.createRef();
    memberParentFatherTelRef = React.createRef();

    memberScoutNameRef = React.createRef();
    memberScoutIdRef = React.createRef();

    memberPicturesRef = React.createRef();
    memberFoodRef = React.createRef();
    memberHealthRef = React.createRef();

    state = {
        error: false,
        errorMessage: null,
        succeed: false,
        troopName: null
    }

    troopId = this.props.match.params.troopID;

    registerMember = (event) => {
        // Stop the form from submitting
        event.preventDefault();

        // Check if multi user have multi troops
        const troopsCount = Object.keys(this.props.troopsData).length;
        let troopName;
        if(troopsCount > 1) {
            troopName = this.memberTroopRef.current.value;
        } else {
            troopName = Object.keys(this.props.troopsData)[0];
        }

        if(troopName === "izbira-voda") {
            this.setState({
                error: true,
                errorMessage: NOTICES.NO_TROOP_SELECT
            })
            return;
        } else {
            this.setState({
                error: false,
                errorMessage: null,
                troopName: this.props.troopsData[troopName].troopName
            })
        }

        // Current school year
        const startDate = new Date(this.props.currentYear.currentStartSchoolDate).getFullYear();
        const endDate = new Date(this.props.currentYear.currentEndSchoolDate).getFullYear();
        const combineYears = `${startDate}-${endDate}`;

        // Load data in variables
        const member = {
            troop: troopName,
            troopRole: this.props.troopsData[troopName].troopRole,
            memberID: `clan${generatePushID()}`,
            name: this.memberNameRef.current.value,
            lastName: this.memberLastNameRef.current.value,
            birth: this.memberBirthRef.current.value,
            schoolClass: this.memberSchoolClassRef.current.value,
            address: this.memberAddressRef.current.value,
            post: this.memberPostRef.current.value,
            emailMember: this.memberEmailRef.current.value,
            telMember: this.memberTelRef.current.value,
            birthCity: this.memberBirthCityRef.current.value,
            citizenship: this.memberCitizenshipRef.current.value,
            memberIdNumber: this.memberIdNumberRef.current.value,
            emailParent: this.memberParentEmailRef.current.value,
            telParent: this.memberParentTelRef.current.value,
            emailParentFather: this.memberParentFatherEmailRef.current.value,
            telParentFather: this.memberParentFatherTelRef.current.value,
            scoutName: this.memberScoutNameRef.current.value,
            scoutIdNumber: this.memberScoutIdRef.current.value,
            pictureAlow: this.memberPicturesRef.current.value,
            food: this.memberFoodRef.current.value,
            health: this.memberHealthRef.current.value,
            progress: {
                [combineYears]: {
                    'membershipCard': false,
                    'membershipPayment': false,
                    'memberActivityWinter': false,
                    'memberActivitySummer': false,
                    'memberActivityFall': false,
                    'memberActivityOther': 'Prazno',
                    'memberSkillsLevel': 'Prazno',
                    'memberSkillsMisc': ['Prazno']
                }
            }
        };

        // Call function in CanvasUser
        this.props.addMember(member);
        this.setState({succeed: true});

        // Refresh the form
        event.currentTarget.reset();
    };

    closePopUpClicked = () => { this.setState({succeed: false, troopName: null}); }

    multiTroops = () => {
        const troopsCount = Object.keys(this.props.troopsData).length;

        if(troopsCount > 1) {
            return (
                <div className="form-section troop-selection">
                    <h2 className="white-font">Izbira voda</h2>
                    {(this.state.error) ?
                        <p className="single-input member-troop error-input">
                            <select name="member-troop" className="negative-color" ref={this.memberTroopRef} defaultValue={(this.troopId) ? this.troopId : "izbira-voda"}>
                                <option value="izbira-voda" disabled>Izberi vod</option>
                                {Object.keys(this.props.troopsData).map(key => <option key={key} value={key}>{this.props.troopsData[key].troopName}</option>)}
                            </select>
                            <label>{this.state.errorMessage}</label>
                        </p> :
                        <p className="single-input member-troop">
                            <select name="member-troop" className="negative-color" ref={this.memberTroopRef} defaultValue={(this.troopId) ? this.troopId : "izbira-voda"}>
                                <option value="izbira-voda" disabled>Izberi vod</option>
                                {Object.keys(this.props.troopsData).map(key => <option key={key} value={key}>{this.props.troopsData[key].troopName}</option>)}
                            </select>
                        </p>
                    }
                </div>
            )
        } else {
            return null;
        }
    };

    render() {
        if(Object.entries(this.props.troopsData).length !== 0) {
            return (
                <div className="wrapper_forms">
                    <form id="register-member" className="form-settings" onSubmit={this.registerMember}>
                        <h1 className="yellow-font">Registracija novega člana</h1>
                        <div className="body-form">
                            <this.multiTroops />
                            <div className="form-section child-member-data">
                                <h2 className="white-font">Podatki otroka</h2>
                                <p className="single-input medium-left member-name">
                                    <input name="member-name" className="negative-color" ref={this.memberNameRef} type="text" required placeholder="Ime otroka" />
                                </p>
                                <p className="single-input medium-right member-lastname">
                                    <input name="member-lastname" className="negative-color" ref={this.memberLastNameRef} type="text" required placeholder="Priimek otroka" />
                                </p>
                                <p className="single-input medium-left member-birth">
                                    <input name="member-birth" className="negative-color" ref={this.memberBirthRef} type="date" required />
                                    <label className="white-font">Datum rojstva</label>
                                </p>
                                <p className="single-input medium-right member-school-class">
                                    <input name="member-school-class" className="negative-color" ref={this.memberSchoolClassRef} type="number" min="1" max="9" placeholder="5" />
                                    <label className="white-font">Razred v katerega hodi otrok.</label>
                                </p>
                                <p className="single-input medium-left member-address">
                                    <input name="member-address" className="negative-color" ref={this.memberAddressRef} type="text" required placeholder="Naslov" />
                                </p>
                                <p className="single-input medium-right member-post">
                                    <input name="member-post" className="negative-color" ref={this.memberPostRef} type="text" required placeholder="Poštna številka in pošta" />
                                </p>
                                <p className="single-input medium-left member-email">
                                    <input name="member-email" className="negative-color" ref={this.memberEmailRef} type="email" placeholder="E-poštni naslov otroka" />
                                </p>
                                <p className="single-input medium-right member-tel">
                                    <input name="member-tel" className="negative-color" ref={this.memberTelRef} type="tel" pattern="[0-9]{3}/[0-9]{3}-[0-9]{3}" placeholder="Telefonska številka otroka" />
                                    <label className="white-font">Format: 041/999-999</label>
                                </p>
                                <p className="single-input medium-left member-birth-city">
                                    <input name="member-birth-city" className="negative-color" ref={this.memberBirthCityRef} type="text" placeholder="Kraj rojstva" />
                                </p>
                                <p className="single-input medium-right member-citizenship">
                                    <input name="member-citizenship" className="negative-color" ref={this.memberCitizenshipRef} type="text" placeholder="Državljanstvo" />
                                </p>
                                <p className="single-input medium-left member-ID-number">
                                    <input name="member-ID-number" className="negative-color" ref={this.memberIdNumberRef} type="text" placeholder="Številka osebnega dokumenta" />
                                </p>
                            </div>
                            <div className="form-section parent-member-data">
                                <h2 className="white-font">Podatki staršev</h2>
                                <p className="single-input medium-left member-parent-email">
                                    <input name="member-parent-email" className="negative-color" ref={this.memberParentEmailRef} type="email" placeholder="E-poštni naslov matere" />
                                </p>
                                <p className="single-input medium-right member-parent-tel">
                                    <input name="member-parent-tel" className="negative-color" ref={this.memberParentTelRef} type="tel" pattern="[0-9]{3}/[0-9]{3}-[0-9]{3}" placeholder="Telefonska številka matere" />
                                    <label className="white-font">Format: 041/999-999</label>
                                </p>
                                <p className="single-input medium-left member-parent-email-father">
                                    <input name="member-parent-email-father" className="negative-color" ref={this.memberParentFatherEmailRef} type="email" placeholder="E-poštni naslov očeta" />
                                </p>
                                <p className="single-input medium-right member-parent-tel-father">
                                    <input name="member-parent-tel-father" className="negative-color" ref={this.memberParentFatherTelRef} type="tel" pattern="[0-9]{3}/[0-9]{3}-[0-9]{3}" placeholder="Telefonska številka očeta" />
                                    <label className="white-font">Format: 041/999-999</label>
                                </p>
                            </div>
                            <div className="form-section scout-member-data">
                                <h2 className="white-font">Taborniški podatki</h2>
                                <p className="single-input medium-left member-scout-name">
                                    <input name="member-scout-name" className="negative-color" ref={this.memberScoutNameRef} type="text" placeholder="Taborniško ime" />
                                </p>
                                <p className="single-input medium-right member-scout-id">
                                    <input name="member-scout-id" className="negative-color" ref={this.memberScoutIdRef} type="text" placeholder="Taborniška številka" />
                                </p>
                            </div>
                            <div className="form-section extra-info-member-data">
                                <h2 className="white-font">Dodatni podatki</h2>
                                <p className="single-input medium-left member-pictures">
                                    <select name="member-pictures" className="negative-color" ref={this.memberPicturesRef} defaultValue={PHOTOS.DA.VALUE}>
                                        {Object.keys(PHOTOS).map(key => <option key={PHOTOS[key].VALUE} value={PHOTOS[key].VALUE}>{PHOTOS[key].LABEL}</option>)}
                                    </select>
                                    <label className="white-font">Objavljanje slik otrok</label>
                                </p>
                                <p className="single-input medium-right member-food">
                                    <select name="member-food" className="negative-color" ref={this.memberFoodRef} defaultValue={FOOD[0]}>
                                        {FOOD.map(key => <option key={key} value={key}>{key}</option>)}
                                    </select>
                                    <label className="white-font">Vrsta prehrane</label>
                                </p>
                                <p className="single-input member-health">
                                    <textarea name="member-health" className="negative-color" form="register-member" ref={this.memberHealthRef} rows="4" placeholder="Zdravstvene posebnosti" />
                                </p>
                            </div>
                        </div>
                        <div className="footer-form">
                            <button className="negative-color" type="submit">Registracija člana</button>
                        </div>
                    </form>
                    {(!this.state.succeed) ? null :
                        <div className="wrapper-popup">
                            <div className="inner-popup on-succeed box-shadow">
                                <div className="content-popup">
                                    <p className="floating-button"><button type="button" className="button-as-link" onClick={this.closePopUpClicked}>X</button></p>
                                    <p className="form-success-message">Uspešno si dodal novega člana vodu {this.state.troopName}</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )
        } else {
            return (<p className="notice-empty white-font">Nimaš še izbranega voda. Kontaktiraj načelnika ali starešino rodu, da ti ga določi.</p>);
        }
    }
}

export default RegisterMember;
