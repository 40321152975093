const ALL_ROLES = {
    ADMIN: {
        VALUE: "vodstvo",
        LABEL: "Vodstvo"
    },
    GG: {
        VALUE: "gg-druzina",
        LABEL: "Načelnik GG družine"
    },
    MC: {
        VALUE: "mc-druzina",
        LABEL: "Načelnik MČ družine"
    },
    TROOP_LEADER: {
        VALUE: "vodnik",
        LABEL: "Vodnik"
    }
}

const NO_ROLE = "no role";

export { NO_ROLE };

export default ALL_ROLES;
