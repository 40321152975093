import React from 'react';
import TablesShowPastTroops from "./TablesShowPastTroops";

class PastTroops extends React.Component {
    render() {
        if(this.props.archiveTroops && Object.keys(this.props.archiveTroops).length > 0) {
            const tableHeader = ['Št', 'Ime', 'Družina', 'Vodnik', 'Zbriši', 'Obnovitev'];
            const classesToSend = {
                wrapperTitle: "wrapper-past-troops",
                tableTile: "past-troops-table"
            }
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    <h1 className="yellow-font">Arhivirani vodi</h1>
                    <TablesShowPastTroops reverseArchiveTroop={this.props.reverseArchiveTroop} deleteTroop={this.props.deleteTroop} archiveTroops={this.props.archiveTroops} users={this.props.users} classes={classesToSend} tableHeader={tableHeader} />
                </div>
            )
        } else {
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    <p className="notice-empty white-font">V bazi ni arhiviranih vodov!</p>
                </div>
            )
        }
    }
}

export default PastTroops;