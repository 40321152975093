import React, {Fragment} from "react";
import TablesShowMembers from "../members/TablesShowMembers";

class ShowAllMembers extends React.Component {
    render() {
        let checkMembers;
        if(this.props.activeTroop) {
            if('members' in this.props.troopsMembers[this.props.activeTroop]) {
                checkMembers = Object.keys(this.props.troopsMembers[this.props.activeTroop].members);
            } else {
                checkMembers = [];
            }
        } else {
            checkMembers = Object.keys(this.props.troopsMembers).filter(f => this.props.troopsMembers[f].members !== undefined);
        }
        if(checkMembers.length) {
            const tableHeader = ['Št', 'Ime in Priimek', 'Telefonska številka člana', 'Telefonska številka starša', 'Udeležba na sestankih', 'Več'];
            const classesToSend = {
                wrapperTitle: "single-multi-troop-members",
                tableTile: "members-table"
            }
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    {(this.props.activeTroop) ?
                        <Fragment>
                            <h2 className="white-font">Člani voda</h2>
                            <TablesShowMembers adminAccess={true} troopID={this.props.activeTroop} troopsData={this.props.troopsData} troopsMembers={this.props.troopsMembers} troopsMeetings={this.props.troopsMeetings} schoolYears={this.props.schoolYears} classes={classesToSend} tableHeader={tableHeader} />
                        </Fragment> :
                        <Fragment>
                        <h2 className="white-font">{(Object.keys(this.props.troopsMembers).length > 1) ? "Člani vodov" : "Člani voda"}</h2>
                        {Object.keys(this.props.troopsMembers).map(key =>
                        <TablesShowMembers key={key} troopID={key} troopsData={this.props.troopsData} troopsMembers={this.props.troopsMembers} troopsMeetings={this.props.troopsMeetings} schoolYears={this.props.schoolYears} classes={classesToSend} tableHeader={tableHeader} />
                        )}
                        </Fragment>
                    }
                </div>
            )
        } else {
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    <p className="notice-empty white-font">V bazi še ni članov.</p>
                </div>
            )
        }
    }
}
export default ShowAllMembers;
