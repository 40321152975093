import React, {Fragment} from 'react';
import MemberSkillsSingleMember from "../../../members/progress/MemberSkillsSingleMember";
import loaderImage from "../../../../images/fidget-spinner-loader.svg";
import FilterByTroopsAllData from "../FilterByTroopsAllData";
import {combineMembersInSingleArray, combineMembersInSingleArrayRaw, foundMultiMembers} from "../../../../helpers";

class GroupMembersSkills extends React.Component {
    state = {
        selectedYear: `${new Date(this.props.schoolYears.currentStartSchoolDate).getFullYear()}-${new Date(this.props.schoolYears.currentEndSchoolDate).getFullYear()}`,
        selectedTroop: 'all-troops',
        searchParam: '',
        clickedMultiMember: null,
        findMultiMembers: null,
        progressField: false
    }

    componentDidMount() {
        this.fillEmptyProgress(this.state.selectedYear, true);
    }

    changeDate = (event) => {
        this.fillEmptyProgress(event.target.value, false);
        this.setState({selectedYear: event.target.value});
    }

    filterChangeTroop = (selectedTroop) => {
        this.setState({selectedTroop: selectedTroop})
    }

    filterSearchMember = (term) => {
        this.setState({searchParam: term});
    }

    multiMemberDuplicates = (parentMember) => {
        this.setState({
            clickedMultiMember: parentMember.memberID,
            findMultiMembers: parentMember.memberDist
        });
    }

    fillEmptyProgress = (year, progress) => {
        const thisClass = this;
        const checkMembers = Object.keys(this.props.troopsMembers).filter(f => this.props.troopsMembers[f].members !== undefined);
        checkMembers.map(function (key) {
            Object.keys(thisClass.props.troopsMembers[key]['members']).map(function (key2) {
                const updateCurrentMemberProgressData = {
                    'selectedMember': thisClass.props.troopsMembers[key]['members'][key2],
                    'selectedYear': year
                };
                const emptyFieldsStart = {
                    'membershipCard': false,
                    'membershipPayment': false,
                    'memberActivityWinter': false,
                    'memberActivitySummer': false,
                    'memberActivityFall': false,
                    'memberActivityOther': 'Prazno',
                    'memberSkillsLevel': 'Prazno',
                    'memberSkillsMisc': ['Prazno']
                }
                if(progress) {
                    if(!('progress' in thisClass.props.troopsMembers[key]['members'][key2])) {
                        thisClass.props.troopsMembers[key]['members'][key2]['progress'] = {};
                    }
                }
                if(!(year in thisClass.props.troopsMembers[key]['members'][key2]['progress'])) {
                    thisClass.props.updateCurrentMemberProgress(updateCurrentMemberProgressData, emptyFieldsStart, thisClass.props.troopsMembers[key].troopRole);
                }
                return null;
            });
            thisClass.setState({progressField: true});
            return null;
        });
    }

    getYears = () => {
        const firstTroopId = Object.keys(this.props.troopsMembers).filter(f => ('members' in this.props.troopsMembers[f]))[0];
        const firstMemberId = Object.keys(this.props.troopsMembers[firstTroopId]['members'])[0];
        if(!('progress' in this.props.troopsMembers[firstTroopId]['members'][firstMemberId])) {
            this.props.troopsMembers[firstTroopId]['members'][firstMemberId]['progress'] = {};
        }
        const sortByYear = Object.keys(this.props.troopsMembers[firstTroopId]['members'][firstMemberId]['progress']).sort((a, b) => b.split("-")[0] - a.split("-")[0]);
        return (
            <Fragment>
                <option value={`${new Date(this.props.schoolYears.currentStartSchoolDate).getFullYear()}-${new Date(this.props.schoolYears.currentEndSchoolDate).getFullYear()}`}>{`${new Date(this.props.schoolYears.currentStartSchoolDate).getFullYear()}-${new Date(this.props.schoolYears.currentEndSchoolDate).getFullYear()}`}</option>
                {sortByYear.map(key=>
                    (key !== `${new Date(this.props.schoolYears.currentStartSchoolDate).getFullYear()}-${new Date(this.props.schoolYears.currentEndSchoolDate).getFullYear()}`) ? <option key={key} value={key}>{key}</option> : null
                )}
            </Fragment>
        )
    }

    render() {
        const allMembersRawArray = combineMembersInSingleArrayRaw(this.props.troopsMembers);
        const allMembersRaw = allMembersRawArray.allMembers;
        const duplicateMembersRaw = allMembersRawArray.allMembersDuplicateIndex;
        const allMembers = combineMembersInSingleArray(this.props.troopsMembers);
        const allMembersClean = allMembers.allMembersUnique;
        const duplicatedMembers = allMembers.allMembersDuplicateIndex;
        let orderMemberByName;
        if(this.state.selectedTroop === "all-troops") {
            orderMemberByName = allMembersClean.filter(f => this.props.troopsMembers[f.troop].members[f.member].name.toLowerCase().includes(this.state.searchParam.toLowerCase()) ||
                                                            this.props.troopsMembers[f.troop].members[f.member].lastName.toLowerCase().includes(this.state.searchParam.toLowerCase()) ||
                                                            this.props.troopsMembers[f.troop].members[f.member].address.toLowerCase().includes(this.state.searchParam.toLowerCase()));
        } else {
            orderMemberByName = allMembersRaw.filter(f => (f.troop === this.state.selectedTroop && this.props.troopsMembers[f.troop].members[f.member].name.toLowerCase().includes(this.state.searchParam.toLowerCase())) ||
                                                            (f.troop === this.state.selectedTroop && this.props.troopsMembers[f.troop].members[f.member].lastName.toLowerCase().includes(this.state.searchParam.toLowerCase())) ||
                                                            (f.troop === this.state.selectedTroop && this.props.troopsMembers[f.troop].members[f.member].address.toLowerCase().includes(this.state.searchParam.toLowerCase())));
        }
        return (
            <div className="wrapper-group-members-skills">
                {(this.state.progressField) ?
                    <div className="wrapper-multi-troop wrapper-multi-troop-members">
                        <div className="flex-columns control-panel-header">
                            <h1 className="yellow-font">Veščine</h1>
                            <div className="right-side-filters flex-columns">
                                <FilterByTroopsAllData
                                    troopsData={this.props.troopsData}
                                    searchParam={this.state.searchParam}
                                    filterChangeTroop={this.filterChangeTroop}
                                    filterSearchMember={this.filterSearchMember}
                                />
                                <div className="year-menu">
                                    <select name="member-progress-filter-year" className="negative-color box-shadow select-box" onChange={this.changeDate} value={this.state.selectedYear}>
                                        <this.getYears />
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="member-skills flex-columns flex-multi-lines">
                            {orderMemberByName.map((key, index) =>
                                <Fragment key={key.member}>
                                <MemberSkillsSingleMember
                                    order={index +1}
                                    trueOrder={(this.state.selectedTroop === "all-troops") ? allMembersClean.findIndex((element) => (element.member === key.member) && true) : allMembersRaw.findIndex((element) => (element.member === key.member) && true)}
                                    updateCurrentMemberProgress={this.props.updateCurrentMemberProgress}
                                    duplicates={true}
                                    duplicatedMembers={(this.state.selectedTroop === "all-troops") ? duplicatedMembers : duplicateMembersRaw}
                                    clickedMultiMember={this.state.clickedMultiMember}
                                    troopsData={this.props.troopsData}
                                    troopsMembers={this.props.troopsMembers}
                                    selectedYear={this.state.selectedYear}
                                    multiMemberDuplicates={this.multiMemberDuplicates}
                                    memberID={key.member}
                                    details={this.props.troopsMembers[key.troop].members[key.member]}
                                    troopRole={this.props.troopsMembers[key.troop].troopRole}
                                    inElement={false}
                                    noTroopWrapper={true} />
                                {(this.state.clickedMultiMember !== null && this.state.clickedMultiMember === key.member) ?
                                    foundMultiMembers(this.props.troopsMembers, this.state.findMultiMembers, key.member).map((key2) =>
                                        <MemberSkillsSingleMember
                                            key={key2.member}
                                            order={null}
                                            updateCurrentMemberProgress={this.props.updateCurrentMemberProgress}
                                            extraClass="duplicated-box"
                                            duplicates={true}
                                            duplicatedMembers={[-9999]}
                                            troopsData={this.props.troopsData}
                                            troopsMembers={this.props.troopsMembers}
                                            selectedYear={this.state.selectedYear}
                                            memberID={key2.member}
                                            details={this.props.troopsMembers[key2.troop].members[key2.member]}
                                            troopRole={this.props.troopsMembers[key2.troop].troopRole}
                                            inElement={false}
                                            noTroopWrapper={true} />
                                    ) :
                                    null
                                }
                                </Fragment>
                            )}
                        </div>
                    </div> :
                    <div className="loading-screen">
                        <div className="loader-ico"><img src={loaderImage} alt="loader"/></div>
                    </div>
                }
            </div>
        )
    }
}

export default GroupMembersSkills;