import React from 'react';
import {formatDateForComputer} from "../../helpers";

class SetSchoolYear extends React.Component {
    state = {
        errorStartYear: false,
        success: false
    }

    // Form refs
    startYear = React.createRef();

    setSchoolYear = (event) => {
        event.preventDefault();

        const startYear = this.startYear.current.value;
        const endYear = +startYear + +1;
        const startDate = new Date(startYear, 8, 1);
        const endDate = new Date(endYear, 7, 31);
        const date = {
            id: `${startYear}-${endYear}`,
            startDate: formatDateForComputer(startDate),
            endDate: formatDateForComputer(endDate),
            endOfSchoolYear: false
        }
        if(!('all' in this.props.years)) {
            this.props.years.all = {};
        }
        if(!(`${startYear}-${endYear}` in this.props.years.all)) {
            this.props.addSchoolYear(date);
            this.setState({
                success: true,
                errorStartYear: false,
            })
        } else {
            this.setState({
                errorStartYear: true,
                success: false
            })
        }
    }

    handleChange = (event) => {
        const startDate = this.props.years.all[event.currentTarget.value].startSchoolDate;
        const endDate = this.props.years.all[event.currentTarget.value].endSchoolDate;
        const updatedCurrentSchoolYear = {
            currentStartSchoolDate: startDate,
            currentEndSchoolDate: endDate
        }
        this.props.updateCurrentSchoolYear(updatedCurrentSchoolYear);
    }

    handleChangeEndOfSchoolYear = (event) => {
        const endSchoolYear = {
            year: event.currentTarget.value,
            checked: event.currentTarget.checked
        }
        this.props.endSchoolYear(endSchoolYear);
    }

    closePopUpClicked = () => {
        this.props.popUpOpen(false);
    }

    render() {
        const currentSchoolYear = `${new Date(this.props.years.currentStartSchoolDate).getFullYear()}-${new Date(this.props.years.currentEndSchoolDate).getFullYear()}`;
        let reverseSchoolYears;
        if('all' in this.props.years) {
            reverseSchoolYears = Object.keys(this.props.years.all).sort((a, b) => b.split("-")[0] - a.split("-")[0]);
        } else {
            reverseSchoolYears = null;
        }
        return (
            <div className="wrapper-popup">
                <div className="inner-popup width-popup-larger box-shadow">
                    <div className="flex-columns school-years">
                        <div id="popup-form" className="content-popup wrapper_forms one-half-flex-with-space">
                            <form id="set-school-year" className="form-settings" onSubmit={this.setSchoolYear}>
                                <h4>Dodaj novo šolsko leto</h4>
                                <div className="body-form">
                                    <div className="form-section">
                                        <p className="single-input startYear">
                                            <input name="startYear" className="popup-input" ref={this.startYear} type="number" min="2019" max="3000" required placeholder="2020" />
                                            {(this.state.errorStartYear) ? <label className="notice-error">To šolsko leto že obstaja.</label> : <label>Začetek šolskega leta. Vnesi samo leto.</label>}
                                        </p>
                                    </div>
                                </div>
                                <div className="footer-form">
                                    <button type="submit">Dodaj novo šolsko leto</button>
                                </div>
                                {(this.state.success) ? <p className="form-success-message">Uspešno si dodal novo šolsko leto!</p> : null}
                            </form>
                        </div>
                        {!(reverseSchoolYears) ? null :
                            <div className="current-school-years one-half-flex-with-space">
                                <h4>Vsa šolska leta</h4>
                                <p className="notice-edit">Ko zamenjaš aktivno leto, se bodo strani "Podatki članov" v prvo podatki nalagali dalj časa, ker se morajo v bazo dodati novi podatki za članarine, akcije, ... Ocenjen čas je nekje 3h, med tem vam bo zamrznil ekran, ampak to samo pomeni, da v ozadju deluje!</p>
                                <table className="general-table no-shadow-table">
                                    <thead>
                                    <tr>
                                        <th>Šolsko leto</th>
                                        <th>Aktivno leto</th>
                                        <th>Zaključeno leto</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {reverseSchoolYears.map((key, index) =>
                                        <tr key={index}>
                                            <td data-label="Šolsko leto">{key}</td>
                                            <td data-label="Aktivno leto"><input type="checkbox" name="activeSchoolYear" value={key} className="negative-color" onChange={this.handleChange} checked={(currentSchoolYear === key)} /></td>
                                            <td data-label="Zaključeno leto"><input type="checkbox" name="endOfSchoolYear" value={key} className="negative-color" onChange={this.handleChangeEndOfSchoolYear} checked={this.props.years.all[key].endOfSchoolYear} /></td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                    <p className="floating-button"><button type="button" className="button-as-link" onClick={this.closePopUpClicked}>Zapri</button></p>
                </div>
            </div>
        )
    }
}

export default SetSchoolYear;