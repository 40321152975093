import React from "react";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import ListData from "../templates/ListData";

class MyProfile extends React.Component {
    state = {
        editable: false,
        popUpEmailActive: false,
        popUpPasswordActive: false,
    }

    editData = () => {
        this.setState({editable: true});
    };
    stopEditData = () => {
        this.setState({editable: false});
    };

    handleChange = (event) => {
        const updatedCurrentUser = {
            ...this.props.user,
            [event.currentTarget.name]: event.currentTarget.value
        };
        this.props.updateCurrentUser(updatedCurrentUser);
    }

    openPopUpEmailChange = () => {
        this.setState({popUpEmailActive: true})
    }

    openPopUpPasswordChange = () => {
        this.setState({popUpPasswordActive: true})
    }

    closePopUp = (click) => {
        this.setState({
            popUpEmailActive: click,
            popUpPasswordActive: click
        })
    }

    render() {
        const { email, firstName, lastName, telNumber, userName, userRole} = this.props.user;
        const profile = [
            {
                'editable': this.state.editable,
                'label': 'Uporabniško ime',
                'labelEnglish': 'userName',
                'data': userName,
                'inputType': 'text',
                'handleChange': this.handleChange,
            },
            {
                'editable': this.state.editable,
                'label': 'E-mail',
                'labelEnglish': 'email',
                'data': email,
                'inputType': 'notEdit',
                'handleChange': this.handleChange,
            },
            {
                'editable': this.state.editable,
                'label': 'Tel. številka',
                'labelEnglish': 'telNumber',
                'data': telNumber,
                'inputType': 'tel',
                'handleChange': this.handleChange,
            },
            {
                'editable': this.state.editable,
                'label': 'Ime',
                'labelEnglish': 'firstName',
                'data': firstName,
                'inputType': 'text',
                'handleChange': this.handleChange,
            },
            {
                'editable': this.state.editable,
                'label': 'Priimek',
                'labelEnglish': 'lastName',
                'data': lastName,
                'inputType': 'text',
                'handleChange': this.handleChange,
            },
            {
                'editable': this.state.editable,
                'label': 'Vloga',
                'labelEnglish': 'userRole',
                'data': userRole,
                'inputType': 'notEdit',
                'handleChange': this.handleChange,
            },
        ]
        return (
            <div className="cards-presentation one-column-card my-profile-card">
                <h1 className="yellow-font">Moj profil</h1>
                <div className="flex-columns">
                    <div className="box-shadow two-third-flex has-floating-buttons">
                        <h2 className="blue-font">{firstName} {lastName}</h2>
                        <div className="flex-columns my-profile">
                            <div className="personal-data full-column-flex">
                                <ListData data={profile} />
                            </div>
                        </div>
                        {(!this.state.editable) ?
                            <p className="floating-button"><button type="button" className="button-as-link" onClick={this.editData}>Uredi podatke</button></p> :
                            <p className="floating-button"><button type="button" className="button-as-link" onClick={this.stopEditData}>Zaključi urejanje</button></p>
                        }
                    </div>
                    <div className="my-profile-buttons one-third-flex">
                        <button name="change-email" type="button" className="negative-color" onClick={this.openPopUpEmailChange}>Zamenjaj e-mail naslov</button>
                        <button name="change-password" type="button" className="negative-color" onClick={this.openPopUpPasswordChange}>Zamenjaj geslo</button>
                    </div>
                    {(this.state.popUpEmailActive) ? <ChangeEmail  updateCurrentUserEmail={this.props.updateCurrentUserEmail} popUpOpen={this.closePopUp} /> : null}
                    {(this.state.popUpPasswordActive) ? <ChangePassword popUpOpen={this.closePopUp} /> : null}
                </div>
            </div>
        )
    }
}

export default MyProfile;
