export const PHOTOS = {
    DA: {
        VALUE: 'da',
        LABEL: 'Da'
    },
    NE: {
        VALUE: 'ne',
        LABEL: 'Ne'
    }
}

export const FOOD = ['Vsejed', 'Vegeterijanska prehrana', 'Veganska prehrana', 'Brez svinjine'];