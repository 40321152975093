import React from "react";
import ALL_ROLES from "../../../constants/roles";
import {generatePushID, slugify} from "../../../helpers";
import NOTICES from "../../../constants/notices";
import FAMILIES, {SPECIAL_TROOPS} from "../../../constants/familesClubs";
import PLACES from "../../../constants/meetingsPlaces";
import DAYS_OF_THE_WEEK from "../../../constants/daysOfTheWeek";
import {firebaseApp} from "../../../base";

class RegisterTroop extends React.Component {
    // troop refs
    troopNameRef = React.createRef();
    troopRoleRef = React.createRef();
    familyTroopRef = React.createRef();
    troopPlaceRef = React.createRef();
    troopMeetingsTimeStartRef = React.createRef();
    troopMeetingsTimeEndRef = React.createRef();
    troopDayOfTheWeekRef = React.createRef();
    troopAssistantRef = React.createRef();
    // troop leader refs
    userNameRef = React.createRef();

    state = {
        error: false,
        errorMessage: null,
        succeed: false
    }

    registerTroop = (event) => {
        // Stop the form from submitting
        event.preventDefault();
        const currentForm = event.currentTarget;
        // Load data in object
        const troop = {
            troopID: `vod${generatePushID()}`,
            troopName: this.troopNameRef.current.value,
            troopRole: this.troopRoleRef.current.value,
            familyTroop: this.familyTroopRef.current.value,
            troopMeetingsPlace: this.troopPlaceRef.current.value,
            troopMeetingsTime: this.troopMeetingsTimeStartRef.current.value + " - " + this.troopMeetingsTimeEndRef.current.value,
            troopDayOfTheWeek: this.troopDayOfTheWeekRef.current.value,
            troopAssistant: this.troopAssistantRef.current.value,
            troopLeader: this.userNameRef.current.value,
        };

        firebaseApp.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
            if(idTokenResult.claims.role === ALL_ROLES.ADMIN.VALUE) {
                let checkTroops = {};
                if(Object.keys(this.props.troopsData).length) {
                    checkTroops = Object.keys(this.props.troopsData).filter(f => f === slugify(troop.troopName));
                }
                if(checkTroops.length) {
                    this.setState({
                        error: true,
                        errorMessage: NOTICES.TROOP_NAME_ALREADY_IN_USE
                    });
                } else {
                    this.props.addTroop(troop);
                    this.setState({
                        error: false,
                        errorMessage: null,
                        succeed: true
                    });
                    // Refresh the form
                    currentForm.reset();
                }
            } else {
                alert('Nimaš ustreznih pravic za dodajanje novih vodov!');
            }
        });
    };

    getUsersDropDown = (key) => {
        const user = this.props.users[key];
        if(user.userRole === ALL_ROLES.TROOP_LEADER.VALUE) {
            return <option key={key} value={key}>{user.userName}</option>
        } else {
            return '';
        }
    }

    closePopUpClicked = () => { this.setState({succeed: false}); }

    render() {
        return (
            <div className="wrapper_forms">
                <form id="register-troop" className="form-settings" onSubmit={this.registerTroop}>
                    <h1 className="yellow-font">Registracija novega voda</h1>
                    <div className="body-form">
                        <div className="form-section troop-data">
                            <h2 className="white-font">Podatki voda</h2>
                            {(this.state.error) ?
                                <p className="single-input medium-left troop-name error-input">
                                    <input name="troop-name" className="negative-color" ref={this.troopNameRef} type="text" required placeholder="Ime voda" />
                                    <label className="white-font">{this.state.errorMessage}</label>
                                </p> :
                                <p className="single-input medium-left troop-name">
                                    <input name="troop-name" className="negative-color" ref={this.troopNameRef} type="text" required placeholder="Ime voda" />
                                    <label className="white-font">Uporabi unikatno ime</label>
                                </p>
                            }
                            <p className="single-input medium-right troop-place">
                                <select name="troop-place" className="negative-color" ref={this.troopPlaceRef} required defaultValue="lokacija-srecanj">
                                    <option value="lokacija-srecanj" disabled>Lokacija srečanj</option>
                                    {Object.keys(PLACES).map(key => <option key={PLACES[key].VALUE} value={PLACES[key].VALUE}>{PLACES[key].LABEL}</option>)}
                                </select>
                            </p>
                            <p className="single-input medium-left troop-role">
                                <select name="troop-role" className="negative-color" ref={this.troopRoleRef} required defaultValue="starostna-veja">
                                    <option value="starostna-veja" disabled>Starostna veja</option>
                                    {Object.keys(FAMILIES).map(key => <option key={FAMILIES[key].VALUE} value={FAMILIES[key].VALUE}>{FAMILIES[key].LABEL}</option>)}
                                </select>
                                <label className="white-font">Starostna veja</label>
                            </p>
                            <p className="single-input medium-right troop-role">
                                <select name="troop-role" className="negative-color" ref={this.familyTroopRef} required defaultValue="posebnost-voda">
                                    <option value="posebnost-voda" disabled>Posebnost voda</option>
                                    {Object.keys(SPECIAL_TROOPS).map(key => <option key={SPECIAL_TROOPS[key].VALUE} value={SPECIAL_TROOPS[key].VALUE}>{SPECIAL_TROOPS[key].LABEL}</option>)}
                                </select>
                                <label className="white-font">Če bo vod družinski, klubski ali neuvrščen v shemo, izberi eno od opcij, drugače izberi Klasičen vod!</label>
                            </p>
                            <p className="single-input medium-left troop-day-of-the-week">
                                <select name="troop-day-of-the-week" className="negative-color" ref={this.troopDayOfTheWeekRef} required defaultValue="Dan srečanj">
                                    <option value="Dan srečanj" disabled>Dan srečanj</option>
                                    {DAYS_OF_THE_WEEK.map(key => <option key={key} value={key}>{key}</option>)}
                                </select>
                            </p>
                            <p className="single-input medium-right input-from-to troop-meetings-time">
                                <span className="from">
                                    <label className="white-font">Od</label>
                                    <input name="troop-meetings-time-start" className="negative-color" ref={this.troopMeetingsTimeStartRef} type="time" min="06:00" max="23:59" required />
                                </span>
                                <span className="to">
                                    <label className="white-font">Do</label>
                                    <input name="troop-meetings-time-end" className="negative-color" ref={this.troopMeetingsTimeEndRef} type="time" min="06:00" max="23:59" required />
                                </span>
                                <label className="white-font">Termin vodovih srečanj</label>
                            </p>
                        </div>
                        <div className="form-section troop-leader-info">
                            <h2 className="white-font">Podatki vodnika</h2>
                            <p className="single-input medium-left user-name">
                                <select name="user-name" className="negative-color" ref={this.userNameRef} defaultValue="">
                                    <option value="" disabled>Vodnik</option>
                                    {Object.keys(this.props.users).map(this.getUsersDropDown)}
                                </select>
                                <label className="white-font">Izberi registriranega vodnika.</label>
                            </p>
                            <p className="single-input medium-right troop-assistants">
                                <input name="troop-assistants" className="negative-color" ref={this.troopAssistantRef} type="text" placeholder="Pomočnik vodnika" />
                                <label className="white-font">Če je več pomočnikov jih loči z vejico.</label>
                            </p>
                        </div>
                    </div>
                    <div className="footer-form">
                        <button className="negative-color" type="submit">Registracija</button>
                    </div>
                </form>
                {(!this.state.succeed) ? null :
                    <div className="wrapper-popup">
                        <div className="inner-popup on-succeed box-shadow">
                            <div className="content-popup">
                                <p className="floating-button"><button type="button" className="button-as-link" onClick={this.closePopUpClicked}>X</button></p>
                                <p className="form-success-message">Uspešno si dodal nov vod in ga povezal z vodnikom.</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default RegisterTroop;
