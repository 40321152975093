import React, {Fragment} from 'react';

import Membership from "./progress/Membership";
import MemberActivity from "./progress/MemberActivity";
import MemberSkills from "./progress/MemberSkills";

class Members extends React.Component {
    checkMembers = Object.keys(this.props.troopsMembers).filter(f => this.props.troopsMembers[f].members !== undefined);
    state = {
        activePageMembership: false,
        activePageMembersActivity: false,
        activePageMembersSkills: false,
        selectedYear: `${new Date(this.props.schoolYears.currentStartSchoolDate).getFullYear()}-${new Date(this.props.schoolYears.currentEndSchoolDate).getFullYear()}`
    }

    componentDidMount() {
        if(this.checkMembers.length === Object.keys(this.props.troopsMembers).length) {
            this.fillEmptyProgress(this.state.selectedYear, true);
            this.setState({activePageMembership: true});
        }
    }

    changeDate = (event) => {
        this.fillEmptyProgress(event.target.value, false);
        this.setState({selectedYear: event.target.value});
    }

    fillEmptyProgress = (year, progress) => {
        const thisClass = this;
        Object.keys(thisClass.props.troopsMembers).map(function (key) {
            Object.keys(thisClass.props.troopsMembers[key]['members']).map(function (key2) {
                const updateCurrentMemberProgressData = {
                    'selectedMember': thisClass.props.troopsMembers[key]['members'][key2],
                    'selectedYear': year
                };
                const emptyFieldsStart = {
                    'membershipCard': false,
                    'membershipPayment': false,
                    'memberActivityWinter': false,
                    'memberActivitySummer': false,
                    'memberActivityFall': false,
                    'memberActivityOther': 'Prazno',
                    'memberSkillsLevel': 'Prazno',
                    'memberSkillsMisc': ['Prazno']
                }
                if(progress) {
                    if(!('progress' in thisClass.props.troopsMembers[key]['members'][key2])) {
                        thisClass.props.troopsMembers[key]['members'][key2]['progress'] = {};
                    }
                }
                if(!(year in thisClass.props.troopsMembers[key]['members'][key2]['progress'])) {
                    thisClass.props.updateCurrentMemberProgress(updateCurrentMemberProgressData, emptyFieldsStart);
                }
                return null;
            });
            return null;
        });
    }

    activePage = (event) => {
        const state = { ...this.state };
        Object.keys(state).map(function (key) {
            if(key !== "selectedYear" && key !== event.target.name) {
                state[key] = false;
            }
            return null;
        });
        state[event.target.name] = true;
        this.setState(state);
    }

    getYears = () => {
        const firstTroopId = Object.keys(this.props.troopsMembers).filter(f => ('members' in this.props.troopsMembers[f]))[0];
        const firstMemberId = Object.keys(this.props.troopsMembers[firstTroopId]['members'])[0];
        if(!('progress' in this.props.troopsMembers[firstTroopId]['members'][firstMemberId])) {
            this.props.troopsMembers[firstTroopId]['members'][firstMemberId]['progress'] = {};
        }
        const sortByYear = Object.keys(this.props.troopsMembers[firstTroopId]['members'][firstMemberId]['progress']).sort((a, b) => b.split("-")[0] - a.split("-")[0]);
        return (
            <Fragment>
                <option value={`${new Date(this.props.schoolYears.currentStartSchoolDate).getFullYear()}-${new Date(this.props.schoolYears.currentEndSchoolDate).getFullYear()}`}>{`${new Date(this.props.schoolYears.currentStartSchoolDate).getFullYear()}-${new Date(this.props.schoolYears.currentEndSchoolDate).getFullYear()}`}</option>
                {sortByYear.map(key=>
                    (key !== `${new Date(this.props.schoolYears.currentStartSchoolDate).getFullYear()}-${new Date(this.props.schoolYears.currentEndSchoolDate).getFullYear()}`) ? <option key={key} value={key}>{key}</option> : null
                )}
            </Fragment>
        )
    }

    render() {
        if(Object.keys(this.props.troopsMembers).length > 0 && this.checkMembers.length === Object.keys(this.props.troopsMembers).length) {
            return (
                <div className="member-progress">
                    <h1 className="yellow-font">Podatki članov</h1>
                    <div className="page-sub-menus">
                        <div className="box-shadow buttons-menu"><ul>
                            {(this.state.activePageMembership) ?
                                <li className="button-in-menu active"><button type="button" disabled="disabled" className="button-as-link">Članstvo</button></li> :
                                <li className="button-in-menu"><button type="button" name="activePageMembership" className="button-as-link" onClick={this.activePage}>Članstvo</button></li>
                            }
                            {(this.state.activePageMembersActivity) ?
                                <li className="button-in-menu active"><button type="button" disabled="disabled" className="button-as-link">Udeležba na akcijah</button></li> :
                                <li className="button-in-menu"><button type="button" name="activePageMembersActivity" className="button-as-link" onClick={this.activePage}>Udeležba na akcijah</button></li>
                            }
                            {(this.state.activePageMembersSkills) ?
                                <li className="button-in-menu active"><button type="button" disabled="disabled" className="button-as-link">Veščine</button></li> :
                                <li className="button-in-menu"><button type="button" name="activePageMembersSkills" className="button-as-link" onClick={this.activePage}>Veščine</button></li>
                            }
                        </ul></div>
                        <div className="year-menu">
                            <select name="member-progress-filter-year" className="negative-color box-shadow select-box" onChange={this.changeDate} value={this.state.selectedYear}>
                                <this.getYears />
                            </select>
                        </div>
                    </div>
                    {(this.state.activePageMembership) ? <Membership updateCurrentMemberProgress={this.props.updateCurrentMemberProgress} troopsData={this.props.troopsData} troopsMembers={this.props.troopsMembers} selectedYear={this.state.selectedYear} /> : null }
                    {(this.state.activePageMembersActivity) ? <MemberActivity updateCurrentMemberProgress={this.props.updateCurrentMemberProgress} troopsData={this.props.troopsData} troopsMembers={this.props.troopsMembers} selectedYear={this.state.selectedYear} /> : null }
                    {(this.state.activePageMembersSkills) ? <MemberSkills updateCurrentMemberProgress={this.props.updateCurrentMemberProgress} troopsData={this.props.troopsData} troopsMembers={this.props.troopsMembers} selectedYear={this.state.selectedYear} /> : null }
                </div>
            )
        } else {
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    <p className="notice-empty white-font">V bazi ni še članov za vsak vod, ki ga vodiš.</p>
                </div>
            )
        }
    }
}

export default Members;