import React, {Fragment} from 'react';
import {formatDate, reTimeInInput, slugify} from "../../helpers";

const ListData = (props) => (
    <div className="data-inner-wrapper">
        <ul className="no-bullets">
            {props.data.map(key =>
                (!key || (!key.data && !key.editable)) ? null :
                <li key={key.labelEnglish} className="flex-columns">
                    <span className="labels one-third-flex">{key.label}</span>
                    {(!key.editable || key.inputType === "notEdit") ?
                        <span className={`data two-third-flex ${(key.color) ? key.color : ""}`}>{(key.inputType === "date") ? formatDate(key.data) : (key.prettyData) ? key.prettyData : key.data}</span> :
                        <span id="wrapper-edit-data" className="data two-third-flex">
                            {(key.inputType === "number") ?
                                <input name={key.labelEnglish} className="edit-data" type={key.inputType} min="1" max="9" onBlur={key.handleChange} defaultValue={key.data} /> :
                                (key.inputType === "select") ?
                                    <Fragment>
                                        <select name={key.labelEnglish} className="edit-data" onChange={key.handleChange} {...((key.emptyOption && !key.data) ? {defaultValue: slugify(key.emptyOption)} : {value:key.data})}>
                                            {key.emptyOption ? <option key={slugify(key.emptyOption)} value={slugify(key.emptyOption)} disabled>{key.emptyOption}</option> : null}
                                            {key.selectOptions.map(key2 =>
                                                <option key={key2.value} value={key2.value}>{key2.label}</option>
                                            )}
                                        </select>
                                        {(!key.extraInput) ? null :
                                            <input id="extra-input-select" name={key.labelEnglish} className="edit-data edit-data-extra-input-select" type="number" min="1" max="60" onBlur={key.handleChange} placeholder="Zapiši minute s številko" />
                                        }
                                        {(!key.extraInputSecond) ? null :
                                            <input id="extra-input-select-second" name={key.labelEnglish} className="edit-data edit-data-extra-input-select" type="number" min="1" max="60" onBlur={key.handleChange} placeholder="Zapiši minute s številko" />
                                        }
                                    </Fragment> :
                                    (key.inputType === "textarea") ?
                                        <textarea name={key.labelEnglish} className="edit-data" rows="4" onBlur={key.handleChange} defaultValue={key.data} /> :
                                        (key.inputType === "tel") ?
                                            <input name={key.labelEnglish} className="edit-data" type={key.inputType} pattern="[0-9]{3}/[0-9]{3}-[0-9]{3}" onBlur={key.handleChange} defaultValue={key.data} /> :
                                            (key.inputType === "time") ?
                                                <Fragment>
                                                    <input name={`${key.labelEnglish}-from`} className="edit-data edit-data-time edit-data-time-start" type={key.inputType} min="06:00" max="23:59" onBlur={key.handleChange} defaultValue={reTimeInInput(key.data)} />
                                                    <input name={`${key.labelEnglish}-to`} className="edit-data edit-data-time edit-data-time-stop" type={key.inputType} min="06:00" max="23:59" onBlur={key.handleChange} defaultValue={reTimeInInput(key.data, true)} />
                                                </Fragment> :
                                                <input name={key.labelEnglish} className="edit-data" type={key.inputType} onBlur={key.handleChange} defaultValue={key.data} />
                            }
                        </span>
                    }
                </li>
            )}
        </ul>
    </div>
);

export default ListData;