import React, {Fragment} from 'react';
import loaderImage from "../../images/fidget-spinner-loader.svg";
import {firebaseApp, fireBaseFunctions} from "../../base";
import ALL_ROLES from "../../constants/roles";
import NOTICES from "../../constants/notices";

class ChangeEmailForUser extends React.Component {
    state = {
        error: false,
        errorCode: null,
        errorMessage: null,
        waitOnPromise: false,
        succeed: false
    }

    // Form refs
    userEmailChangeRef = React.createRef();

    closePopUpClicked = () => {
        this.props.popUpOpen(false);
        this.setState({succeed: false})
    }

    changeEmailForm = (event) => {
        event.preventDefault();

        const currentForm = event.currentTarget;
        const email = this.userEmailChangeRef.current.value;

        const userData = {
            uid: this.props.userID,
            props: {
                email: email
            }
        }

        this.setState({waitOnPromise: true});

        // Update user e-mail
        let errorMessage;
        firebaseApp.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
            if(idTokenResult.claims.role === ALL_ROLES.ADMIN.VALUE) {
                const changeUserEmail = fireBaseFunctions.httpsCallable('changeTroopLeaderData');
                changeUserEmail(userData).then(result => {
                    if('errorInfo' in result.data) {
                        console.log(result);
                        if(result.data.errorInfo.code === "auth/email-already-exists") {
                            errorMessage = NOTICES.EMAIL_ALREADY_IN_USE;
                        }
                        this.setState({
                            error: true,
                            errorCode: result.data.errorInfo.code,
                            errorMessage: errorMessage,
                            waitOnPromise: false
                        });
                    } else {
                        const updatedUser = {
                            ...this.props.userAllData,
                            email: email
                        };
                        this.props.updateUser(updatedUser);
                        this.setState({
                            error: false,
                            errorCode: null,
                            errorMessage: null,
                            succeed: true,
                            waitOnPromise: false,
                        });
                        currentForm.reset();
                    }
                });
            } else {
                alert('Nimaš ustreznih pravic za urejanje vodnikov!');
                this.setState({waitOnPromise: false});
            }
        }).catch((error) => {
            console.log(error);
            this.setState({waitOnPromise: false});
        });
    }

    render() {
        return (
            <div className="wrapper-popup">
                <div className="inner-popup box-shadow">
                    <div id="popup-form" className="content-popup wrapper_forms">
                        <form id="change-email-address" className="form-settings" onSubmit={this.changeEmailForm}>
                            <h2 className="no-extra-notice">Menjava e-mail naslova</h2>
                            {(this.state.succeed) ?
                                <p className="form-success-message">Uspešno si zamenjal/a e-mail naslov uporabnika.</p> :
                                <Fragment>
                                    <div className="body-form">
                                        <div className="form-section new-data">
                                            {(this.state.error && this.state.errorCode === "auth/email-already-exists") ?
                                            <p className="single-input user-email-change error-input">
                                                <input name="user-email-change" className="popup-input" ref={this.userEmailChangeRef} type="email" required placeholder="Vnesite e-mail naslov" />
                                                <label className="white-font">{this.state.errorMessage}</label>
                                            </p> :
                                            <p className="single-input user-email-change">
                                                <input name="user-email-change" className="popup-input" ref={this.userEmailChangeRef} type="email" required placeholder="Vnesite e-mail naslov" />
                                            </p> }
                                        </div>
                                    </div>
                                    <div className="footer-form wait-on-promise">
                                        <button type="submit">Zamenjaj e-mail naslov</button>
                                        {(!this.state.waitOnPromise) ? null :
                                            <div className="loader-on-submit">
                                                <div className="loader-ico"><img src={loaderImage} alt="loader" /></div>
                                            </div>
                                        }
                                    </div>
                                </Fragment>
                            }
                        </form>
                    </div>
                    <p className="floating-button"><button type="button" className="button-as-link" onClick={this.closePopUpClicked}>Zapri</button></p>
                </div>
            </div>
        )
    }
}

export default ChangeEmailForUser;