// skill levels
export const SKILL_LEVELS = [
    'Skok',
    'Prvi plamen',
    'Drugi plamen',
    'Tretji plamen',
    'Ogenj',
    'Prvi list',
    'Drugi list',
    'Tretji list',
    'Izziv',
    'Prvi vozel',
    'Drugi vozel',
    'Tretji vozel',
    'Prvi korak',
    'Drugi korak'
]
// skill misc
export const SKILL_MISC = [
    'Ljubitelj živali',
    'Poznavalec ognjev',
    'Poznavalec morja',
    'Izletnik',
    'Gibalček',
    'Sankač',
    'Rešilko 1',
    'Pevec',
    'Poznavalec dinozavrov',
    'Kmetovalec 1',
    'Mladi naravoslovec',
    'Plavalec 1',
    'Smučar',
    'Redoljub',
    'Ribič',
    'Opazovalec neba',
    'Robinzon',
    'Kolesar',
    'Rolar 1',
    'Drsalec',
    'Rešilko 2',
    'Igralec',
    'Zbiralec',
    'Prijatelj gozda',
    'Zeliščar 1',
    'Kuhar začetnik',
    'Vremenoslovec',
    'Eko policaj',
    'Vodič po kraju',
    'Partizanski kurir',
    'Uporabnik noža',
    'Lokostrelec 1',
    'Civilna zaščita',
    'Uporabnik računalnika',
    'Moj grb',
    'Poznavalec gozda 1',
    'Poznavalec živali 1',
    'Zeliščar 2',
    'Čuvaj ognja',
    'Varuh planeta',
    'Vodič po terenu',
    'Stezosledec',
    'Signalist',
    'Vrvar',
    'Čolnar',
    'Plavalec',
    'Rolar 2',
    'Alpski smučar',
    'Bolničar 1',
    'Ljubitelj glasbe',
    'Poznavalec taborniških šeg',
    'Vodov kronist',
    'Poznavalec živali 2',
    'Kmetovalec 2',
    'Kuharski vajenec',
    'Meterolog',
    'Morjevarstvenik,',
    'Vodič po slovenski naravi',
    'Risar skic 1',
    'Opazovalec',
    'Oskrbnik šotorov',
    'Športnik z žogo',
    'Nordijski smučar',
    'Deskar',
    'Zabavljač',
    'Poročevalec',
    'Poznavalec fotografije',
    'Potapljač na vdih',
    'Poznavalec gozda 2',
    'Astronom',
    'Poznavalec gora',
    'Risar skic 2',
    'Svetilničar',
    'Nastanjevalec 1',
    'Taborni izumitelj',
    'Lokostrelec 2',
    'Športni plezalec',
    'Bolničar 2',
    'Kitarist',
    'Pripovedovalec zgodb',
    'Jezikač',
    'Urednik glasila',
    'Računalničar',
    'BiPi (Svetko)'
]