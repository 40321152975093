export function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâčèéëêìíïîòóöôšùúüûžñç·/_,:;";
    var to   = "aaaaceeeeiiiioooosuuuuznc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export function formatDateForComputer(date) {
    let month;
    let day;
    if((date.getMonth() + 1) <= 9) {
        month = "0" + (date.getMonth() + 1);
    } else {
        month = (date.getMonth() + 1);
    }
    if(date.getDate() <= 9) {
        day = "0" + date.getDate();
    } else {
        day = date.getDate();
    }
    const year = date.getFullYear();

    return [year, month, day].join('-');
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    return [day, month, year].join('.');
}

export function formatPhoneNumber(number) {
    var clean = number.replace(/[^A-Z0-9]+/ig, "");
    return clean.replace(/[0-9]/, '+386');
}

export function reCapitalizeData(value, data) {
    const selected = Object.keys(data).filter(f => data[f].VALUE === value);
    if(selected in data) {
        return data[selected].LABEL;
    } else {
        return null;
    }
}

export function reTimeInInput(time, end=false) {
    time = time.replace(/\s/g, '');
    time = time.split("-");
    if(!end) {
        return time[0];
    } else {
        return time[1];
    }
}

// Function from Michael Lehenbauer on GitHub
export function generatePushID() {
    // Modeled after base64 web-safe chars, but ordered by ASCII.
    var PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

    // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
    var lastPushTime = 0;

    // We generate 72-bits of randomness which get turned into 12 characters and appended to the
    // timestamp to prevent collisions with other clients.  We store the last characters we
    // generated because in the event of a collision, we'll use those same characters except
    // "incremented" by one.
    var lastRandChars = [];

    var now = new Date().getTime();
    var duplicateTime = (now === lastPushTime);
    lastPushTime = now;

    var timeStampChars = new Array(8);
    for (var i = 7; i >= 0; i--) {
        timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
        // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
        now = Math.floor(now / 64);
    }
    if (now !== 0) throw new Error('We should have converted the entire timestamp.');

    var id = timeStampChars.join('');

    if (!duplicateTime) {
        for (i = 0; i < 12; i++) {
            lastRandChars[i] = Math.floor(Math.random() * 64);
        }
    } else {
        // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
        for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
            lastRandChars[i] = 0;
        }
        lastRandChars[i]++;
    }
    for (i = 0; i < 12; i++) {
        id += PUSH_CHARS.charAt(lastRandChars[i]);
    }
    if(id.length !== 20) throw new Error('Length should be 20.');

    return id;
}

export function generateYears() {
    var max = new Date().getFullYear()
    var min = max - 9
    var years = []

    for (var i = max; i >= min; i--) {
        years.push(i)
    }
    return years;
}

function allMembersRaw(troops) {
    const allMembersRaw = [];
    Object.keys(troops).map(function (key) {
        if('members' in troops[key]) {
            Object.keys(troops[key].members).map(function (key2) {
                allMembersRaw.push({
                    'troop': key,
                    'member': key2
                });
                return null;
            })
        }
        return null;
    })
    return allMembersRaw.sort((a, b) => troops[a.troop].members[a.member].name.localeCompare(troops[b.troop].members[b.member].name, "sl"));
}

export function combineMembersInSingleArrayRaw(troops) {
    const allMembersRawOrder = allMembersRaw(troops);
    const allMembersDuplicateIndex = [];
    allMembersRawOrder.map(function (key, index) {
        const combineData = troops[key.troop].members[key.member].name+troops[key.troop].members[key.member].lastName+troops[key.troop].members[key.member].birth.replace(/\s+/g, '');
        const checkMembers = allMembersRawOrder.filter(f => troops[f.troop].members[f.member].name+troops[f.troop].members[f.member].lastName+troops[f.troop].members[f.member].birth.replace(/\s+/g, '') === combineData);
        if(checkMembers.length > 1) {
            allMembersDuplicateIndex.push(index);
        }
        return null;
    });
    return {
        allMembers: allMembersRawOrder,
        allMembersDuplicateIndex: allMembersDuplicateIndex
    };
}

export function combineMembersInSingleArray(troops) {
    const allMembersRawOrder = allMembersRaw(troops);
    const allMembersUnique = [];
    const allMembersTest = [];
    const allMembersDuplicateIndex = [];
    allMembersRawOrder.map(function (key) {
        const combineData = troops[key.troop].members[key.member].name+troops[key.troop].members[key.member].lastName+troops[key.troop].members[key.member].birth.replace(/\s+/g, '');
        if(allMembersTest.indexOf(combineData) === -1) {
            allMembersUnique.push(key);
            allMembersTest.push(combineData);
        } else {
            allMembersDuplicateIndex.push(allMembersTest.indexOf(combineData));
        }
        return null;
    });
    return {
        allMembersUnique: allMembersUnique,
        allMembersDuplicateIndex: allMembersDuplicateIndex
    };
}

export function foundMultiMembers(troops, memberDist, parentMemberID) {
    const allMembersRawOrder = allMembersRaw(troops);
    const duplicatedMembers = [];
    allMembersRawOrder.map(function (key, index) {
        const combineData = troops[key.troop].members[key.member].name+troops[key.troop].members[key.member].lastName+troops[key.troop].members[key.member].birth.replace(/\s+/g, '');
        if(combineData === memberDist && parentMemberID !== key.member) {
            duplicatedMembers.push(key);
        }
        return null;
    })
    return duplicatedMembers;
}