import React from "react";
import SetSchoolYear from "../templates/SetSchoolYear";
import ALL_ROLES from "../../constants/roles";
import StatisticMainCanvas from "../statistics/adminAccess/StatisticMainCanvas";

class OverviewInfoAdmin extends React.Component {
    state = {
        popUpSchoolYearActive: false,
    }

    openPopUpSetSchoolYear = () => {
        this.setState({popUpSchoolYearActive: true})
    }

    closePopUp = (click) => {
        this.setState({
            popUpSchoolYearActive: click
        })
    }

    render() {
        return (
            <div className="overview-info">
                <div className="flex-columns control-panel-header">
                    <h1 className="yellow-font">Nadzorna plošča</h1>
                    {(this.props.currentUser.userRole === ALL_ROLES.GG.VALUE || this.props.currentUser.userRole === ALL_ROLES.MC.VALUE) ? null : <button name="open-popup-school-year" type="button" className="negative-color" onClick={this.openPopUpSetSchoolYear}>Nastavi šolsko leto</button> }
                </div>
                <StatisticMainCanvas
                    currentUser={this.props.currentUser}
                    troopsData={this.props.troopsData}
                    troopsMembers={this.props.troopsMembers}
                    years={this.props.schoolYears}
                    troopsNumbers={this.props.troopsNumbers}
                    addTroopsNumbers={this.props.addTroopsNumbers}
                    windowWidth={this.props.windowWidth}
                    windowHeight={this.props.windowHeight}
                />
                {((this.state.popUpSchoolYearActive && this.props.currentUser.userRole !== ALL_ROLES.GG.VALUE) || (this.state.popUpSchoolYearActive && this.props.currentUser.userRole !== ALL_ROLES.MC.VALUE)) ? <SetSchoolYear years={this.props.schoolYears}  addSchoolYear={this.props.addSchoolYear} updateCurrentSchoolYear={this.props.updateCurrentSchoolYear} endSchoolYear={this.props.endSchoolYear} popUpOpen={this.closePopUp} /> : null}
            </div>
        )
    }
}

export default OverviewInfoAdmin;