import React from 'react';
import {reCapitalizeData} from "../../../helpers";
import FAMILIES from "../../../constants/familesClubs";

class TablesShowPastTroops extends React.Component {
    state = {
        areYouSureDelete: false,
        selectedTroop: null
    };

    restoreTroop = (key, event) => {
        this.props.reverseArchiveTroop(this.props.archiveTroops[key]);
    }

    deleteTroopSure = (key, event) => {
        this.setState({
            selectedTroop: key,
            areYouSureDelete: true
        });
    }
    deleteTroopSureNo = () => {
        this.setState({areYouSureDelete: false});
    }
    deleteTroopSureYes = () => {
        this.props.deleteTroop(this.state.selectedTroop);
    }

    render() {
        return (
            <div className={this.props.classes.wrapperTitle}>
                <table className={`general-table ${this.props.classes.tableTile}`}>
                    <thead>
                        <tr>
                            {this.props.tableHeader.map((key, index) => <th key={index}>{key}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(this.props.archiveTroops).map((key, index) =>
                            <tr key={key}>
                                <td data-label="Št." className="order">{index+1}</td>
                                <td data-label="Ime" className="name">{this.props.archiveTroops[key].troopName}</td>
                                <td data-label="Družina" className="family">{reCapitalizeData(this.props.archiveTroops[key].troopRole, FAMILIES)}</td>
                                <td data-label="Vodnik" className="troop-leader">/</td>
                                <td data-label="Zbriši" className="delete-troop"><button type="button" className="button-as-link red-font in-table-link" onClick={this.deleteTroopSure.bind(this, key)}>Zbriši</button></td>
                                <td data-label="Obnovitev" className="restore-troop"><button type="button" className="button-as-link red-font in-table-link" onClick={this.restoreTroop.bind(this, key)}>Obnovi</button></td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {(!this.state.areYouSureDelete) ? null :
                    <div className="wrapper-popup">
                        <div className="inner-popup are-you-sure-popup box-shadow">
                            <div className="content-popup">
                                <h4>Ali res želiš zbrisati vod?</h4>
                                <div className="buttons-wrapper">
                                    <button type="button" className="yes" onClick={this.deleteTroopSureYes}>Da</button>
                                    <button type="button" className="no" onClick={this.deleteTroopSureNo}>Ne</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default TablesShowPastTroops;