import React from "react";
import { Link } from "react-router-dom";
import {MEMBER_CARD} from "../../../constants/routes";

class MembershipSingleTable extends React.Component {
    handleChange = (event) => {
        const updateCurrentMemberProgressData = {
            selectedMember: this.props.details,
            selectedYear: this.props.selectedYear
        }
        let updateCurrentMemberProgressFields;
        if(event.currentTarget.type === "checkbox") {
            updateCurrentMemberProgressFields = {
                ...this.props.troopsMembers[this.props.details.troop]['members'][this.props.details.memberID]['progress'][this.props.selectedYear],
                [event.currentTarget.name]: event.currentTarget.checked
            };
        } else {
            updateCurrentMemberProgressFields = {
                ...this.props.troopsMembers[this.props.details.troop]['members'][this.props.details.memberID]['progress'][this.props.selectedYear],
                [event.currentTarget.name]: event.currentTarget.value
            };
        }
        this.props.updateCurrentMemberProgress(updateCurrentMemberProgressData, updateCurrentMemberProgressFields);
    }

    render() {
        return (
            <tr>
                <td data-label="Št." className="order">{this.props.order}</td>
                <td data-label="Član" className="member">{this.props.details.name} {this.props.details.lastName}</td>
                <td data-label="Vpisni list" className="membership-card"><input type="checkbox" name="membershipCard" className="negative-color" onChange={this.handleChange} checked={this.props.details['progress'][this.props.selectedYear]['membershipCard']} /></td>
                <td data-label="Plačana članarina" className="membership-payment"><input type="checkbox" disabled="disabled" name="membershipPayment" className="negative-color" /*onChange={this.handleChange}*/ checked={this.props.details['progress'][this.props.selectedYear]['membershipPayment']} /></td>
                <td data-label="Več" className="more-info"><Link to={`${MEMBER_CARD}/${this.props.details.troop}/${this.props.details.memberID}`}>Info</Link></td>
            </tr>
        );
    }
}

export default MembershipSingleTable;