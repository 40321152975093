import React from 'react';
import TablesShowMembers from "../TablesShowMembers";

class MemberActivity extends React.Component {
    state = {
        otherActivityEdit: false
    }

    otherActivityEditStart = () => {
        this.setState({otherActivityEdit: true})
    }

    otherActivityEditEnd = () => {
        this.setState({otherActivityEdit: false})
    }

    render() {
        const tableHeader = ['Št', 'Član', 'Jesenovanje', 'Zimovanje', 'Tabor', 'Druge akcije', 'Več'];
        const classesToSend = {
            wrapperTitle: "wrapper-member-activity-table",
            tableTile: "member-activity-table"
        }
        return (
            <div className="wrapper-multi-troop wrapper-multi-troop-members">
                <h2 className="white-font">
                    Udeležba na akcijah
                    {(this.state.otherActivityEdit) ?
                        <button type="button" className="button-as-link red-font" onClick={this.otherActivityEditEnd}>Zaključi urejanje drugih akcij</button> :
                        <button type="button" className="button-as-link red-font" onClick={this.otherActivityEditStart}>Uredi druge akcije</button>
                    }
                </h2>
                {Object.keys(this.props.troopsMembers).map(key =>
                    <TablesShowMembers
                        key={key}
                        troopID={key}
                        tableSort="memberActivity"
                        updateCurrentMemberProgress={this.props.updateCurrentMemberProgress}
                        troopsData={this.props.troopsData}
                        troopsMembers={this.props.troopsMembers}
                        selectedYear={this.props.selectedYear}
                        classes={classesToSend}
                        tableHeader={tableHeader}
                        otherActivityEdit={this.state.otherActivityEdit}
                    />
                )}
            </div>
        )
    }
}

export default MemberActivity;