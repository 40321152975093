import React from "react";
import base from "../../base";
import { Route, Switch } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import loaderImage from "../../images/fidget-spinner-loader.svg";

import MainMenuUser from "../user/MainMenuUser";
import OverviewInfoUser from "../user/OverviewInfoUser";
import MyProfile from "../myProfile/MyProfile";
import Footer from "../Footer";
import NotFound from "../NotFound";

import Members from "../members/Members";
import PastMembers from "../members/PastMembers";
import MemberCard from "../members/MemberCard";
import RegisterMember from "../members/RegisterMember";

import MeetingCard from "../meetings/MeetingCard";
import RegisterWeeklyMeeting from "../meetings/RegisterWeeklyMeeting";
import TroopsCards from "../troops/userAccess/TroopsCards";

class CanvasUser extends React.Component {
    // States
    state = {
        loadingGeneral: true,
        loadingData: true,
        troopsData: {},
        loadingMembers: true,
        troopsMembers: {},
        loadingMeetings: true,
        troopsMeetings: {}
    };

    // When component mount check database
    componentDidMount() {
        this.ref1 = base.syncState(`/troopsData/`, {
            context: this,
            state: 'troopsData',
            queries: {
                orderByChild: 'troopLeader',
                equalTo: this.props.currentUser.userID
            },
            then(){
                this.setState({ loadingData: false });
                this.checkLoading();
            }
        });
        this.ref2 = base.syncState(`/troopsMembers/`, {
            context: this,
            state: 'troopsMembers',
            queries: {
                orderByChild: 'troopLeader',
                equalTo: this.props.currentUser.userID
            },
            then(){
                this.setState({ loadingMembers: false });
                this.checkLoading();
            }
        });
        this.ref3 = base.syncState(`/troopsMeetings/`, {
            context: this,
            state: 'troopsMeetings',
            queries: {
                orderByChild: 'troopLeader',
                equalTo: this.props.currentUser.userID
            },
            then(){
                this.setState({ loadingMeetings: false });
                this.checkLoading();
            }
        });
    };

    // Remove all data from states. Safety for memory lik
    componentWillUnmount() {
        if(this.ref1) {
            base.removeBinding(this.ref1);
        }
        if(this.ref2) {
            base.removeBinding(this.ref2);
        }
        if(this.ref3) {
            base.removeBinding(this.ref3);
        }
    }

    checkLoading = () => {
        if(this.state.loadingData === false && this.state.loadingMembers === false && this.state.loadingMeetings === false ) {
            this.setState({loadingGeneral: false})
        }
    }

    // Update selected troop
    updateCurrentTroop = (troop) => {
        const troopsData = { ...this.state.troopsData };
        troopsData[troop.troopID] = troop;
        this.setState({troopsData: troopsData});
    }

    // Add new member
    addMember = (member) => {
        // Take a copy of existing state
        const troopsMembers = { ...this.state.troopsMembers };
        // Check if object members exists in selected troop
        if(!('members' in troopsMembers)) {
            troopsMembers[member.troop]['members'] = {};
        }
        // Add a new member to members in troop
        troopsMembers[member.troop]['members'][member.memberID] = member;
        delete troopsMembers[member.troop]['members'][member.memberID].troopRole;

        // Set the new object to state
        this.setState({
            troopsMembers: troopsMembers
        });
    };

    // Update current member - based on member page
    updateCurrentMember = (updateCurrentMember) => {
        const troopsMembers = { ...this.state.troopsMembers };
        troopsMembers[updateCurrentMember.troop]['members'][updateCurrentMember.memberID] = updateCurrentMember;
        this.setState({troopsMembers: troopsMembers});
    }

    // Delete current member - based on member page
    deleteCurrentMember = (currentMember) => {
        const troopsMembers = { ...this.state.troopsMembers };
        // Check if object past members exists in selected troop
        if(!('pastMembers' in troopsMembers)) {
            troopsMembers[currentMember.troop]['pastMembers'] = {};
        }
        troopsMembers[currentMember.troop]['pastMembers'][currentMember.memberID] = currentMember;
        troopsMembers[currentMember.troop]['members'][currentMember.memberID] = null;
        this.setState({troopsMembers: troopsMembers});
    }

    // Restore member from past members
    restoreCurrentMember = (currentMember) => {
        const troopsMembers = { ...this.state.troopsMembers };
        troopsMembers[currentMember.troop]['pastMembers'][currentMember.memberID] = null;
        // Check if object members exists in selected troop
        if(!('members' in troopsMembers)) {
            troopsMembers[currentMember.troop]['members'] = {};
        }
        troopsMembers[currentMember.troop]['members'][currentMember.memberID] = currentMember;
        this.setState({troopsMembers: troopsMembers});
    }

    // Update current member - progress data
    updateCurrentMemberProgress = (updateCurrentMemberProgressData, updatedCurrentMemberProgressFields) => {
        // Take a copy of existing state
        const troopsMembers = { ...this.state.troopsMembers };
        // Check if object meetings exists in selected troop
        if(!('progress' in troopsMembers[updateCurrentMemberProgressData.selectedMember.troop]['members'][updateCurrentMemberProgressData.selectedMember.memberID])) {
            troopsMembers[updateCurrentMemberProgressData.selectedMember.troop]['members'][updateCurrentMemberProgressData.selectedMember.memberID]['progress'] = {};
        }
        // Add new progress to member.
        troopsMembers[updateCurrentMemberProgressData.selectedMember.troop]['members'][updateCurrentMemberProgressData.selectedMember.memberID]['progress'][updateCurrentMemberProgressData.selectedYear] = updatedCurrentMemberProgressFields;
        this.setState({troopsMembers: troopsMembers});
    }

    // Functions for manipulating weekly meetings of troop
    addWeeklyMeeting = (meeting) => {
        // Take a copy of existing state
        const troopsMeetings = { ...this.state.troopsMeetings };
        // Check if object meetings exists in selected troop
        if(!('meetings' in troopsMeetings)) {
            troopsMeetings[meeting.troop]['meetings'] = {};
        }
        // Add new meeting to meetings.
        troopsMeetings[meeting.troop]['meetings'][meeting.meetingID] = meeting;
        // Set the new object to state
        this.setState({
            troopsMeetings: troopsMeetings
        });
    }

    // Update current meeting - based on meeting page
    updateCurrentMeeting = (updateCurrentMeeting) => {
        const troopsMeetings = { ...this.state.troopsMeetings };
        troopsMeetings[updateCurrentMeeting.troop]['meetings'][updateCurrentMeeting.meetingID] = updateCurrentMeeting;
        this.setState({troopsMeetings: troopsMeetings});
    }

    // Delete current meeting - based on meeting page
    deleteCurrentMeeting = (currentMeeting) => {
        const troopsMeetings = { ...this.state.troopsMeetings };
        troopsMeetings[currentMeeting.troop]['meetings'][currentMeeting.meetingID] = null;
        this.setState({troopsMeetings: troopsMeetings});
    }

    render() {
        const proRegisterMember = (props) => {
            return (
                <RegisterMember
                    addMember={this.addMember.bind(this)}
                    troopsData={this.state.troopsData}
                    currentYear={{currentStartSchoolDate: this.props.schoolYears.currentStartSchoolDate, currentEndSchoolDate: this.props.schoolYears.currentEndSchoolDate}}
                    {...props}
                />
            );
        };
        const proRegisterWeeklyMeeting = (props) => {
            return (
                <RegisterWeeklyMeeting
                    addWeeklyMeeting={this.addWeeklyMeeting.bind(this)}
                    troopsData={this.state.troopsData}
                    troopsMembers={this.state.troopsMembers}
                    {...props}
                />
            );
        };
        const proOverviewInfoUser = (props) => {
            return (
                <OverviewInfoUser
                    troopsData={this.state.troopsData}
                    troopsMembers={this.state.troopsMembers}
                    troopsMeetings={this.state.troopsMeetings}
                    schoolYears={this.props.schoolYears}
                    {...props}
                />
            );
        };
        const proMyProfile = (props) => {
            return (
                <MyProfile
                    updateCurrentUser={this.props.updateCurrentUser}
                    updateCurrentUserEmail={this.props.updateCurrentUserEmail}
                    user={this.props.currentUser}
                    {...props}
                />
            );
        };
        const proTroops = (props) => {
            return (
                <TroopsCards
                    updateCurrentTroop={this.updateCurrentTroop}
                    troopsData={this.state.troopsData}
                    {...props}
                />
            );
        };
        const proMembers = (props) => {
            return (
                <Members
                    updateCurrentMemberProgress={this.updateCurrentMemberProgress}
                    troopsData={this.state.troopsData}
                    troopsMembers={this.state.troopsMembers}
                    schoolYears={this.props.schoolYears}
                    {...props}
                />
            );
        };
        const proPastMembers = (props) => {
            return (
                <PastMembers
                    restoreCurrentMember={this.restoreCurrentMember}
                    troopsData={this.state.troopsData}
                    troopsMembers={this.state.troopsMembers}
                    {...props}
                />
            );
        };
        const proMemberCard = (props) => {
            return (
                <MemberCard
                    currentUserRole={this.props.currentUser.userRole}
                    addMember={this.addMember.bind(this)}
                    updateCurrentMember={this.updateCurrentMember}
                    deleteCurrentMember={this.deleteCurrentMember}
                    updateCurrentMemberProgress={this.updateCurrentMemberProgress}
                    troopsData={this.state.troopsData}
                    troopsMembers={this.state.troopsMembers}
                    schoolYears={this.props.schoolYears}
                    {...props}
                />
            );
        };
        const proMeetingCard = (props) => {
            return (
                <MeetingCard
                    updateCurrentMeeting={this.updateCurrentMeeting}
                    deleteCurrentMeeting={this.deleteCurrentMeeting}
                    troopsData={this.state.troopsData}
                    troopsMeetings={this.state.troopsMeetings}
                    troopsMembers={this.state.troopsMembers}
                    {...props}
                />
            );
        };
        if(this.state.loadingGeneral) {
            return (
                <div className="loading-screen">
                    <div className="loader-ico"><img src={loaderImage} alt="loader" /></div>
                </div>
            )
        } else {
            return (
                <div className="wrapper-main-app">
                    <MainMenuUser
                        logout={this.props.logout}
                        userName={this.props.currentUser.userName}
                        windowWidth={this.props.windowWidth}
                        windowHeight={this.props.windowHeight}
                    />
                    <main className="main-canvas">
                        <Switch>
                            <Route path={ROUTES.HOME} exact render={proOverviewInfoUser}/>
                            <Route path={ROUTES.MY_PROFILE} render={proMyProfile}/>
                            <Route path={ROUTES.TROOPS_CARDS} render={proTroops}/>
                            <Route path={ROUTES.MEMBERS} render={proMembers}/>
                            <Route path={ROUTES.PAST_MEMBERS} render={proPastMembers}/>
                            <Route path={ROUTES.REGISTER_MEMBER} render={proRegisterMember}/>
                            <Route path={ROUTES.REGISTER_WEEKLY_MEETING} render={proRegisterWeeklyMeeting}/>
                            <Route path={`${ROUTES.MEMBER_CARD}/:troopId/:memberId`} render={proMemberCard}/>
                            <Route path={`${ROUTES.MEETING_CARD}/:troopId/:meetingId`} render={proMeetingCard}/>
                            <Route render={NotFound}/>
                        </Switch>
                    </main>
                    <Footer />
                </div>
            )
        }
    }
}

export default CanvasUser;
