import React from "react";
import NotFound from "../NotFound";

import ListData from "../templates/ListData";
import MemberSingleProgress from "./progress/MemberSingleProgress";
import MemberCopyToDifferentTroop from "./MemberCopyToDifferentTroop";

import {FOOD, PHOTOS} from "../../constants/registerMembersOptions";
import {reCapitalizeData} from "../../helpers";

class MemberCard extends React.Component {
    state = {
        troopMemberExist: false,
        editable: false,
        areYouSureDelete: false,
        copyMemberPopup: false,
        selectedYear: `${new Date(this.props.schoolYears.currentStartSchoolDate).getFullYear()}-${new Date(this.props.schoolYears.currentEndSchoolDate).getFullYear()}`
    };
    troopId = this.props.match.params.troopId;
    memberId = this.props.match.params.memberId;

    componentDidMount() {
        const troopExist = Object.keys(this.props.troopsData).filter(f => f === this.troopId);
        if(troopExist.length) {
            const memberExist = Object.keys(this.props.troopsMembers[this.troopId]['members']).filter(f => f === this.memberId);
            if(memberExist.length) {
                this.setState({  troopMemberExist: true })
            }
        }
    }

    changeDate = (year) => {
        this.setState({selectedYear: year});
    }

    editData = () => {
        this.setState({editable: true});
    };
    stopEditData = () => {
        this.setState({editable: false});
    };

    deleteUserSure = () => {
        this.setState({areYouSureDelete: true})
    }
    deleteUserSureNo = () => {
        this.setState({areYouSureDelete: false})
    }
    deleteUserSureYes = () => {
        this.props.deleteCurrentMember(this.props.troopsMembers[this.troopId]['members'][this.memberId], this.props.troopsMembers[this.troopId].troopRole);
        this.setState({  troopMemberExist: false })
        this.props.history.goBack();
    }

    copyMemberToAnotherTroopPopup = () => {
        this.setState({copyMemberPopup: true});
    }
    copyMemberToAnotherTroopPopupClose = () => {
        this.setState({copyMemberPopup: false});
    }

    handleChange = (event) => {
        const updatedCurrentMember = {
            ...this.props.troopsMembers[this.troopId]['members'][this.memberId],
            [event.currentTarget.name]: event.currentTarget.value
        };
        this.props.updateCurrentMember(updatedCurrentMember, this.props.troopsData[this.troopId].troopRole);
    }

    render() {
        if(this.state.troopMemberExist && this.props.troopsMembers[this.troopId]['members'][this.memberId]) {
            const troopsCount = Object.keys(this.props.troopsData).length;
            const memberAllData = this.props.troopsMembers[this.troopId]['members'][this.memberId];
            const troopName = this.props.troopsData[this.troopId]['troopName'];
            const { address, birth, birthCity, citizenship, emailMember, emailParent, emailParentFather, health, food, lastName, memberIdNumber, name, pictureAlow, post, schoolClass, scoutIdNumber, scoutName, telMember, telParent, telParentFather} = memberAllData;
            const memberProgressData = this.props.troopsMembers[this.troopId].members[this.memberId].progress;
            const personalData = [
                {
                    'editable': this.state.editable,
                    'label': 'Ime',
                    'labelEnglish': 'name',
                    'data': name,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Priimek',
                    'labelEnglish': 'lastName',
                    'data': lastName,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Naslov',
                    'labelEnglish': 'address',
                    'data': address,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Pošta',
                    'labelEnglish': 'post',
                    'data': post,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Datum rojstva',
                    'labelEnglish': 'birth',
                    'data': birth,
                    'inputType': 'date',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Kraj rojstva',
                    'labelEnglish': 'birthCity',
                    'data': birthCity,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Državljanstvo',
                    'labelEnglish': 'citizenship',
                    'data': citizenship,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Številka osebnega dokumenta',
                    'labelEnglish': 'memberIdNumber',
                    'data': memberIdNumber,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                }
            ]
            const scoutData = [
                {
                    'editable': this.state.editable,
                    'label': 'Taborniška številka',
                    'labelEnglish': 'scoutIdNumber',
                    'data': scoutIdNumber,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Taborniško ime',
                    'labelEnglish': 'scoutName',
                    'data': scoutName,
                    'inputType': 'text',
                    'handleChange': this.handleChange,
                }
            ]
            let troopEnter;
            if(troopsCount>1) {
                troopEnter = {
                    'editable': this.state.editable,
                    'label': 'Vod',
                    'labelEnglish': 'troopName',
                    'data': troopName,
                    'inputType': 'notEdit',
                    'handleChange': this.handleChange,
                }
            } else {
                troopEnter = null;
            }
            const addTroopToScoutData = [troopEnter, ...scoutData];
            const otherData = [
                {
                    'editable': this.state.editable,
                    'label': 'Razred',
                    'labelEnglish': 'schoolClass',
                    'data': schoolClass,
                    'inputType': 'number',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Objavljanje slik',
                    'labelEnglish': 'pictureAlow',
                    'data': pictureAlow,
                    'prettyData': reCapitalizeData(pictureAlow, PHOTOS),
                    'inputType': 'select',
                    'selectOptions' : Object.keys(PHOTOS).map(key => ({value: PHOTOS[key].VALUE, label: PHOTOS[key].LABEL})),
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Prehrana',
                    'labelEnglish': 'food',
                    'data': food,
                    'inputType': 'select',
                    'selectOptions' : FOOD.map(key => ({value: key, label: key})),
                    'handleChange': this.handleChange,
                }
            ]
            let healthEnterNonEdit;
            if(health && !this.state.editable) {
                healthEnterNonEdit = {
                    'editable': this.state.editable,
                    'label': 'Zdravstvene posebnosti',
                    'labelEnglish': 'health',
                    'data': health,
                    'inputType': 'notEdit',
                    'handleChange': this.handleChange,
                }
            } else if(this.state.editable) {
                healthEnterNonEdit = {
                    'editable': this.state.editable,
                    'label': 'Zdravstvene posebnosti',
                    'labelEnglish': 'health',
                    'data': health,
                    'inputType': 'textarea',
                    'handleChange': this.handleChange,
                }
            } else {
                healthEnterNonEdit = null;
            }
            const otherDataAll = [...otherData, healthEnterNonEdit];
            const contactMember = [
                {
                    'editable': this.state.editable,
                    'label': 'E-mail',
                    'labelEnglish': 'emailMember',
                    'data': emailMember,
                    'inputType': 'email',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Tel. številka',
                    'labelEnglish': 'telMember',
                    'data': telMember,
                    'inputType': 'tel',
                    'handleChange': this.handleChange,
                },
            ]
            const contactParent = [
                {
                    'editable': this.state.editable,
                    'label': 'E-mail matere',
                    'labelEnglish': 'emailParent',
                    'data': emailParent,
                    'inputType': 'email',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Tel. številka matere',
                    'labelEnglish': 'telParent',
                    'data': telParent,
                    'inputType': 'tel',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'E-mail očeta',
                    'labelEnglish': 'emailParentFather',
                    'data': emailParentFather,
                    'inputType': 'email',
                    'handleChange': this.handleChange,
                },
                {
                    'editable': this.state.editable,
                    'label': 'Tel. številka očeta',
                    'labelEnglish': 'telParentFather',
                    'data': telParentFather,
                    'inputType': 'tel',
                    'handleChange': this.handleChange,
                }
            ]
            return (
                <div className="cards-presentation member-card">
                    <h1 className="yellow-font">Članska izkaznica</h1>
                    <div className="box-shadow buttons-menu"><ul>
                        {(!this.state.editable) ?
                            <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.editData}>Uredi podatke</button></li> :
                            <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.stopEditData}>Zaključi urejanje</button></li>
                        }
                        {(troopsCount>1) ? <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.copyMemberToAnotherTroopPopup}>Kopiraj člana</button></li> : null}
                        <li className="button-in-menu"><button type="button" className="button-as-link" onClick={this.deleteUserSure}>Zbriši člana</button></li>
                    </ul></div>
                    <div className="box-shadow box-margin">
                        <h2 className="blue-font">
                            {name} {lastName}
                            <p className="extra-title red-font">{scoutName} - {scoutIdNumber}</p>
                        </h2>
                        <div className="flex-columns multi-columns-not-last member-info">
                            <div className="personal-data left-side-data one-half-flex">
                                <h4 className="blue-font">Osebni podatki</h4>
                                <ListData data={personalData} />
                            </div>
                            <div className="extra-data right-side-data one-half-flex">
                                {(scoutIdNumber || scoutName || this.state.editable) ?
                                    <div className="double-data-one-side-top">
                                        <h4 className="blue-font">Taborniški podatki</h4>
                                        <ListData data={addTroopToScoutData} />
                                    </div> :
                                    null
                                }
                                <div className="double-data-one-side-bottom">
                                    <h4 className="blue-font">Ostali podatki</h4>
                                    <ListData data={otherDataAll} />
                                </div>
                            </div>
                        </div>
                        <div className="flex-columns contact-info">
                            <div className="member-contact left-side-data one-half-flex">
                                <h4 className="blue-font">Kontaktni podatki člana</h4>
                                <ListData data={contactMember} />
                            </div>
                            <div className="parent-contact right-side-data one-half-flex">
                                <h4 className="blue-font">Kontaktni podatki staršev</h4>
                                <ListData data={contactParent} />
                            </div>
                        </div>
                    </div>
                    <MemberSingleProgress
                        currentUserRole={this.props.currentUserRole}
                        troopsData={this.props.troopsData}
                        troopsMembers={this.props.troopsMembers}
                        troopRole={this.props.troopsMembers[this.troopId].troopRole}
                        memberData={this.props.troopsMembers[this.troopId]['members'][this.memberId]}
                        memberProgressData={memberProgressData}
                        updateCurrentMemberProgress={this.props.updateCurrentMemberProgress}
                        schoolYears={this.props.schoolYears}
                        selectedYear={this.state.selectedYear}
                        changeDate={this.changeDate}
                        editable={this.state.editable}/>
                    {(!this.state.areYouSureDelete) ? null :
                        <div className="wrapper-popup">
                            <div className="inner-popup are-you-sure-popup box-shadow">
                                <div className="content-popup">
                                    <h4>Ali res želiš zbrisati člana?</h4>
                                    <div className="buttons-wrapper">
                                        <button type="button" className="yes" onClick={this.deleteUserSureYes}>Da</button>
                                        <button type="button" className="no" onClick={this.deleteUserSureNo}>Ne</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {((troopsCount>1 && !this.state.copyMemberPopup) || (troopsCount<2)) ? null : <MemberCopyToDifferentTroop
                        copyMemberToAnotherTroopPopupClose={this.copyMemberToAnotherTroopPopupClose}
                        troopsData={this.props.troopsData}
                        troopsMembers={this.props.troopsMembers}
                        troopId ={this.troopId}
                        memberId ={this.memberId}
                        addMember={this.props.addMember}
                    />}
                </div>
            )
        } else {
            return (
                <NotFound />
            )
        }
    }
}

export default MemberCard;
