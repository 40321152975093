import React from "react";

import MemberSingleTable from "../members/MemberSingleTable";
import MembershipSingleTable from "./progress/MembershipSingleTable";
import MemberActivitySingleTable from "./progress/MemberActivitySingleTable";
import PastMemberSingleTable from "./PastMemberSingleTable";

class TablesShowMembers extends React.Component {
    render() {
        const checkMembers = Object.keys(this.props.troopsMembers[this.props.troopID]).filter(f => f === "members");
        const checkPastMembers = Object.keys(this.props.troopsMembers[this.props.troopID]).filter(f => f === "pastMembers");
        if(checkMembers.length && this.props.tableSort !== "pastMembers") {
            const sortMembers = Object.keys(this.props.troopsMembers[this.props.troopID].members).sort((a, b) => this.props.troopsMembers[this.props.troopID].members[a].name.localeCompare(this.props.troopsMembers[this.props.troopID].members[b].name, "sl"));
            return(
                <div className={`single-multi-troop ${this.props.classes.wrapperTitle}`}>
                    {(Object.keys(this.props.troopsMembers).length > 1 && !this.props.adminAccess) ? <h4 className="yellow-font">{this.props.troopsData[this.props.troopID].troopName}</h4> : null}
                    <table className={`general-table ${this.props.classes.tableTile}`}>
                        <thead>
                        <tr>
                            {this.props.tableHeader.map((key, index) => <th key={index}>{key}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {sortMembers.map((key2, index) => (
                            (this.props.tableSort === "membership") ?
                                <MembershipSingleTable
                                    key={key2}
                                    order={index+1}
                                    updateCurrentMemberProgress={this.props.updateCurrentMemberProgress}
                                    troopsMembers={this.props.troopsMembers}
                                    selectedYear={this.props.selectedYear}
                                    details={this.props.troopsMembers[this.props.troopID].members[key2]}
                                /> :
                                (this.props.tableSort === "memberActivity") ?
                                    <MemberActivitySingleTable
                                        key={key2}
                                        order={index+1}
                                        updateCurrentMemberProgress={this.props.updateCurrentMemberProgress}
                                        troopsMembers={this.props.troopsMembers}
                                        selectedYear={this.props.selectedYear}
                                        details={this.props.troopsMembers[this.props.troopID].members[key2]}
                                        otherActivityEdit={this.props.otherActivityEdit}
                                    /> :
                                    <MemberSingleTable
                                        key={key2}
                                        order={index+1}
                                        slug={key2}
                                        details={this.props.troopsMembers[this.props.troopID].members[key2]}
                                        meetings={this.props.troopsMeetings[this.props.troopID].meetings}
                                        schoolYears={this.props.schoolYears}
                                    />
                        ))}
                        </tbody>
                    </table>
                </div>
            )
        } else if (checkPastMembers.length && this.props.tableSort === "pastMembers") {
            const sortPastMembers = Object.keys(this.props.troopsMembers[this.props.troopID].pastMembers).sort((a, b) => this.props.troopsMembers[this.props.troopID].pastMembers[a].name.localeCompare(this.props.troopsMembers[this.props.troopID].pastMembers[b].name, "sl"));
            return(
                <div className={`single-multi-troop ${this.props.classes.wrapperTitle}`}>
                    {(Object.keys(this.props.troopsMembers).length > 1 && !this.props.adminAccess) ? <h4 className="yellow-font">{this.props.troopsData[this.props.troopID].troopName}</h4> : null}
                    <table className={`general-table ${this.props.classes.tableTile}`}>
                        <thead>
                        <tr>
                            {this.props.tableHeader.map((key, index) => <th key={index}>{key}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {sortPastMembers.map((key2, index) => (
                            <PastMemberSingleTable
                                key={key2}
                                order={index+1}
                                restoreCurrentMember={this.props.restoreCurrentMember}
                                troopsMembers={this.props.troopsMembers}
                                troopRole={this.props.troopsMembers[this.props.troopID].troopRole}
                                details={this.props.troopsMembers[this.props.troopID].pastMembers[key2]}
                            />
                        ))}
                        </tbody>
                    </table>
                </div>
            )
        } else {
            return(
                (this.props.tableSort !== "pastMembers") ?
                    <p className="notice-empty white-font">V bazi ni še nobenega člana voda {this.props.troopsData[this.props.troopID].troopName}.</p> :
                    <p className="notice-empty white-font">V bazi ni bivših članov voda {this.props.troopsData[this.props.troopID].troopName}.</p>

            )
        }
    }
}

export default TablesShowMembers;
