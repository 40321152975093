import React, {Fragment} from 'react';
import TablesShowMembers from "./TablesShowMembers";

class PastMembers extends React.Component {
    render() {
        let checkMembers;
        if(this.props.activeTroop) {
            if('pastMembers' in this.props.troopsMembers[this.props.activeTroop]) {
                checkMembers = Object.keys(this.props.troopsMembers[this.props.activeTroop].pastMembers);
            } else {
                checkMembers = [];
            }
        } else {
            checkMembers = Object.keys(this.props.troopsMembers).filter(f => this.props.troopsMembers[f].pastMembers !== undefined);
        }
        if(checkMembers.length) {
            const tableHeader = ['Št', 'Ime in Priimek', 'Član do leta', 'Kontakt', 'Obnovitev'];
            const classesToSend = {
                wrapperTitle: "single-multi-troop-members",
                tableTile: "members-table"
            }
            return (
                <div className="wrapper-multi-troop wrapper-multi-troop-members">
                    {(this.props.activeTroop) ?
                        <Fragment>
                            <h2 className="white-font">Bivši člani voda</h2>
                            <TablesShowMembers adminAccess={true} troopID={this.props.activeTroop} tableSort="pastMembers" restoreCurrentMember={this.props.restoreCurrentMember} troopsData={this.props.troopsData} troopsMembers={this.props.troopsMembers} classes={classesToSend} tableHeader={tableHeader} />
                        </Fragment> :
                        <Fragment>
                            <h1 className="yellow-font">{(Object.keys(this.props.troopsMembers).length > 1) ? "Bivši člani vodov" : "Bivši člani voda"}</h1>
                            {Object.keys(this.props.troopsMembers).map(key =>
                                <TablesShowMembers
                                    key={key}
                                    troopID={key}
                                    tableSort="pastMembers"
                                    restoreCurrentMember={this.props.restoreCurrentMember}
                                    troopsData={this.props.troopsData}
                                    troopsMembers={this.props.troopsMembers}
                                    classes={classesToSend}
                                    tableHeader={tableHeader}
                                />
                            )}
                        </Fragment>
                    }
                </div>
            )
        } else {
            if(!this.props.activeTroop) {
                return (
                    <div className="wrapper-multi-troop wrapper-multi-troop-members">
                        <p className="notice-empty white-font">V bazi ni bivših članov voda.</p>
                    </div>
                )
            } else {
                return null;
            }
        }
    }
}

export default PastMembers;