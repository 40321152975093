import React, {Fragment} from 'react';
import NOTICES from "../../constants/notices";
import {generatePushID} from "../../helpers";

class MemberCopyToDifferentTroop extends React.Component {
    memberCopyTroopRef = React.createRef();

    state = {
        error: false,
        errorMessage: null,
        succeed: false
    }

    copyMemberToAnotherTroop = (event) => {
        event.preventDefault();

        const newTroop = this.memberCopyTroopRef.current.value;

        if(newTroop === "vod") {
            this.setState({
                error: true,
                errorMessage: NOTICES.NO_TROOP_SELECT
            })
            return;
        } else {
            this.setState({
                error: false,
                errorMessage: null
            })
        }

        const member = {...this.props.troopsMembers[this.props.troopId].members[this.props.memberId]};
        member.memberID = `clan${generatePushID()}`;
        member.troop = newTroop;
        member.troopRole = this.props.troopsMembers[newTroop].troopRole;

        this.props.addMember(member);
        this.setState({succeed: true})
    }

    render() {
        return (
            <div className="wrapper-popup">
                <div className="inner-popup box-shadow">
                    <div className="content-popup">
                        <div id="popup-form" className="content-popup wrapper_forms">
                            <form id="copy-member" className="form-settings" onSubmit={this.copyMemberToAnotherTroop}>
                                <h2 className="no-extra-notice">Kopiraj člana</h2>
                                {(this.state.succeed) ?
                                    <p className="form-success-message">Uspešno si kopiral člana!</p> :
                                    <Fragment>
                                        <div className="body-form">
                                            <div className="form-section new-data">
                                                {(this.state.error) ?
                                                    <p className="single-input copy-member-troop error-input">
                                                        <select name="copy-member-troop" defaultValue="vod" ref={this.memberCopyTroopRef}>
                                                            <option value="vod" disabled>Vod</option>
                                                            {Object.keys(this.props.troopsData).map(key => <option key={key} value={key}>{this.props.troopsData[key].troopName}</option>)}
                                                        </select>
                                                        <label>{this.state.errorMessage}</label>
                                                    </p> :
                                                    <p className="single-input copy-member-troop">
                                                        <select name="copy-member-troop" defaultValue="vod" ref={this.memberCopyTroopRef}>
                                                            <option value="vod" disabled>Vod</option>
                                                            {Object.keys(this.props.troopsData).map(key => <option key={key} value={key}>{this.props.troopsData[key].troopName}</option>)}
                                                        </select>
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                        <div className="footer-form wait-on-promise">
                                            <button type="submit">Kopiraj člana</button>
                                        </div>
                                    </Fragment>
                                }
                            </form>
                        </div>
                    </div>
                    <p className="floating-button"><button type="button" className="button-as-link" onClick={this.props.copyMemberToAnotherTroopPopupClose}>Zapri</button></p>
                </div>
            </div>
        )
    }
}

export default MemberCopyToDifferentTroop;