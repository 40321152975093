import React from 'react';
import {Link} from "react-router-dom";
import {MEMBER_CARD} from "../../../constants/routes";
import PercentageBar from "../../templates/PercentageBar";

class GroupAllMembersTable extends React.Component {
    multiMemberClicked = (memberID, memberDist, event) => {
        const memberData = {
            memberID: memberID,
            memberDist: memberDist
        }
        this.props.multiMemberDuplicates(memberData);
    }

    multiMemberClosed = (event) => {
        const memberData = {
            memberID: null,
            memberDist: null
        }
        this.props.multiMemberDuplicates(memberData);
    }

    render() {
        return (
            <tr className={this.props.extraClass}>
                <td data-label="Št." className="order">{(this.props.order !== null) ? this.props.order +1 : ''}</td>
                <td data-label="Ime in priimek" className="name-surname">
                    {this.props.troopsMembers[this.props.troopID].members[this.props.memberID].name} {this.props.troopsMembers[this.props.troopID].members[this.props.memberID].lastName} {}
                    {((this.props.duplicatedMembers.includes(this.props.trueOrder)) && (this.props.clickedMultiMember === null || this.props.clickedMultiMember !== this.props.memberID)) ?
                        <button id={this.props.order} type="button" className="button-as-link red-font in-table-link no-underline" onClick={
                            this.multiMemberClicked.bind(this,
                                this.props.memberID,
                                this.props.troopsMembers[this.props.troopID].members[this.props.memberID].name+this.props.troopsMembers[this.props.troopID].members[this.props.memberID].lastName+this.props.troopsMembers[this.props.troopID].members[this.props.memberID].birth.replace(/\s+/g, ''))}>(Multi)
                        </button> :
                        (this.props.duplicatedMembers.includes(this.props.trueOrder) && this.props.clickedMultiMember === this.props.memberID) &&
                        <button type="button" className="button-as-link red-font in-table-link no-underline" onClick={this.multiMemberClosed}>(Zapri)</button>
                    }
                </td>
                <td data-label="Telefonska številka člana" className="tel-number">{this.props.troopsMembers[this.props.troopID].members[this.props.memberID].telMember}</td>
                <td data-label="Telefonska številka starša" className="tel-number">{this.props.troopsMembers[this.props.troopID].members[this.props.memberID].telParent}</td>
                <td data-label="Udeležba na sestankih" className="attendance"><PercentageBar memberID={this.props.memberID} meetings={this.props.troopsMeetings[this.props.troopID].meetings} schoolYears={this.props.schoolYears} /></td>
                <td data-label="Vod" className="troop">{this.props.troopsData[this.props.troopID].troopName}</td>
                <td data-label="Več" className="more-info"><Link to={`${MEMBER_CARD}/${this.props.troopsMembers[this.props.troopID].members[this.props.memberID].troop}/${this.props.memberID}`}>Info</Link></td>
            </tr>
        )
    }
}



export default GroupAllMembersTable;