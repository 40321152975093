import React, {Fragment} from 'react';
import firebase from "firebase/app";
import 'firebase/auth';

class ChangeEmail extends React.Component {
    state = {
        errorEmail: false,
        errorPass: false,
        success: false
    }

    // Form refs
    oldEmailChangeRef = React.createRef();
    passwordRef = React.createRef();

    newEmailChangeRef = React.createRef();

    changeEmailForm = (event) => {
        event.preventDefault();
        const thisClass = this;

        const currentUser = firebase.auth().currentUser;
        const currentUserEmail = currentUser.email;
        const credential = firebase.auth.EmailAuthProvider.credential(
            this.oldEmailChangeRef.current.value,
            this.passwordRef.current.value
        );

        if(currentUserEmail === this.oldEmailChangeRef.current.value) {
            currentUser.reauthenticateWithCredential(credential).then(function () {
                thisClass.setState({errorPass: false})
                currentUser.updateEmail(thisClass.newEmailChangeRef.current.value).then(function () {
                    thisClass.props.updateCurrentUserEmail(thisClass.newEmailChangeRef.current.value);
                    thisClass.setState({success: true})
                }).catch(function (error) {
                    console.log(error);
                })
            }).catch(function (error) {
                thisClass.setState({errorPass: true})
                console.log(error);
            })
            this.setState({errorEmail: false})
        } else {
            this.setState({errorEmail: true})
        }
    }

    closePopUpClicked = () => {
        this.props.popUpOpen(false);
        this.setState({success: false})
    }

    render() {
        return (
            <div className="wrapper-popup">
                <div className="inner-popup box-shadow">
                    <div id="popup-form" className="content-popup wrapper_forms">
                        <form id="change-email-address" className="form-settings" onSubmit={this.changeEmailForm}>
                            <h2>Menjava e-mail naslova</h2>
                            {(this.state.success) ?
                                <p className="form-success-message">Uspešno si zamenjal/a svoj e-mail naslov, ko se boš ponovno vpisoval/a v bazo uporabi novi e-mail naslov.</p> :
                                <Fragment>
                                    <p className="form-description">Za menjavo e-mail naslova morate ponovno vpisati svoj stari e-mail naslov ter geslo.</p>
                                    <div className="body-form">
                                        <div className="form-section old-data">
                                            <h4>Trenutni podatki</h4>
                                            <p className="single-input old-email-change">
                                                <input name="old-email-change" className="popup-input" ref={this.oldEmailChangeRef} type="email" required placeholder="Vnesite svoj stari e-mail naslov" />
                                                {(this.state.errorEmail) ? <label className="notice-error">Vnešeni e-mail se ne ujema z vašim e-mailom!</label> : null}
                                            </p>
                                            <p className="single-input old-pass-email-change">
                                                <input name="password" ref={this.passwordRef} type="password" required placeholder="Geslo" />
                                                {(this.state.errorPass) ? <label className="notice-error">Vnešeno geslo je nepravilno!</label> : null}
                                            </p>
                                        </div>
                                        <div className="form-section new-data">
                                            <h4>Novi podatki</h4>
                                            <p className="single-input new-email-change">
                                                <input name="new-email-change" className="popup-input" ref={this.newEmailChangeRef} type="email" required placeholder="Vnesite svoj novi e-mail naslov" />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="footer-form">
                                    <button type="submit">Zamenjaj e-mail naslov</button>
                                    </div>
                                </Fragment>
                            }
                        </form>
                    </div>
                    <p className="floating-button"><button type="button" className="button-as-link" onClick={this.closePopUpClicked}>Zapri</button></p>
                </div>
            </div>
        )
    }
}

export default ChangeEmail;